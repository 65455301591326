import { call, put } from "redux-saga/effects";
import {
  checkAuthentication,
  checkAuthenticationFailed,
  checkAuthenticationSuccess,
} from "./CheckAuthenticationSlice";
import {
  AuthenticationResponse,
  queryAuthenticationStatus,
  queryAuthenticationStatusWithState,
  retrieveAuthenticationToken,
} from "../../api/ServerApi";
import {
  getQueryParameter,
  removeQueryParameter,
  redirect,
  rewriteTo,
} from "../../common/utils/NavigationHelpers";
import { renderErrorPageAction } from "../customer-profile/CustomerProfileActions";
import { getFeaturesAction } from "../feature-flags/FeaturesActions";

import { PayloadAction } from "@reduxjs/toolkit";

/* Authorisation code parameter returned by Auth0 after the user successfully authenticate */
const authorisationParameter = "code";
const errorParameter = "error";
const returnToParameter = "returnTo";
const redirectableRoutes = [
  "/delete-account",
  "/change-security-level",
  "/change-password",
  "/change-password/success",
  "/change-password/failed",
  "/add-edit-mobile",
  "/integration-flybuys",
  "/marketing-preferences/supermarket",
  "/marketing-preferences/cexp",
  "/receipt-preferences/supermarket",
];

function* exchangeAuthCode(codeQueryParameter: string) {
  yield call(rewriteTo, removeQueryParameter(authorisationParameter));
  yield call(retrieveAuthenticationToken, codeQueryParameter);
  yield put(checkAuthenticationSuccess());
  yield put(getFeaturesAction());
}

function* authenticationCheckAndRedirect(action: PayloadAction<string>) {
  const isOnRedirectableRoute = redirectableRoutes.includes(action.payload);
  const returnTo = getQueryParameter(returnToParameter);

  const statePayload =
    action.payload.includes("marketing-preferences") ||
    action.payload.includes("receipt-preferences")
      ? btoa(
          `${action.payload.split("/")[1]}/${action.payload.split("/")[2]}${
            returnTo ? `|${returnTo}` : ""
          }`
        )
      : btoa(
          `${action.payload.split("/")[1]}${returnTo ? `|${returnTo}` : ""}`
        );

  const status: AuthenticationResponse = isOnRedirectableRoute
    ? yield call(queryAuthenticationStatusWithState, statePayload)
    : yield call(queryAuthenticationStatus);

  if (status.authenticated) {
    yield put(checkAuthenticationSuccess());
    yield put(getFeaturesAction());
  } else {
    const returnTo = getQueryParameter(returnToParameter);
    const url = new URL(status.loginUri!);
    if (returnTo) {
      const redirectUri = url.searchParams.get("redirect_uri");
      const updatedRedirectUri = new URL(redirectUri!);
      updatedRedirectUri.searchParams.set("returnTo", returnTo);
      url.searchParams.set("redirect_uri", updatedRedirectUri.toString());
    }
    yield call(redirect, url.toString());
  }
}

function* handleExchangeOrRedirect(action: PayloadAction<string>) {
  const codeQueryParameter = getQueryParameter(authorisationParameter);
  if (codeQueryParameter) {
    yield call(exchangeAuthCode, codeQueryParameter);
  } else {
    yield call(authenticationCheckAndRedirect, action);
  }
}

export function* checkAuthenticationAsync(action: PayloadAction<string>) {
  try {
    const errorQueryParameter = getQueryParameter(errorParameter);
    if (errorQueryParameter) {
      yield put(checkAuthenticationFailed());
      yield put(renderErrorPageAction());
    } else {
      yield put(checkAuthentication());
      yield call(handleExchangeOrRedirect, action);
    }
  } catch (error) {
    yield put(checkAuthenticationFailed());
    yield put(renderErrorPageAction());
  }
}
