import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import editIcon from "../../assets/icons/edit.svg";

export const SettingButton = styled.button<{ withIcon?: boolean }>`
  margin: 0;
  border: none;
  flex: 0 0 auto;
  order: 3;
  color: #e01a22;
  background: ${(props) =>
    props.withIcon ? `left center no-repeat url(${editIcon})` : "none"};
  font-family: "Source Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding: ${(props) => (props.withIcon ? "0 0 0 20px" : 0)};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
  a:link,
  a:visited,
  a:active {
    color: #e01a22;
  }

  @media ${screenSize.mobile} {
    order: 2;
  }
`;
