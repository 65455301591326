import styled from "styled-components";

import { Link, ThemedButton } from "ccp-common-ui-components";
import { screenSize } from "../../../common/styles/ScreenSizes";

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;

export const DeleteLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
  color: #e01a22;
`;

export const ThemedAddAddressButton = styled(ThemedButton)`
  width: 300px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;
