import styled from "styled-components";
import loader from "../../assets/icons/loader.svg";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  height: 22px;
  width: 78px;
  color: #858583;
  font-family: "Source Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  padding-left: 12px;
`;

const Spinner = styled.img`
  background-color: #20211f07;
  border-radius: 39px;
`;

export function UpdatePendingSpinner() {
  return (
    <Container data-testid="update-pending-spinner">
      <Spinner
        height={39}
        width={39}
        src={loader}
        alt="spinner"
        role="presentation"
        aria-hidden
      />
      <Text role="status">Updating...</Text>
    </Container>
  );
}
