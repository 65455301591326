import appConfig from "../../config/Configuration";
import { loginFetch } from "../LoginFetch";
import { defaultRequestConfig } from "../ServerApi";

const loyaltyAccountsBaseRoute = `${appConfig.serverUrl}/api/v1/loyalty-accounts`;

export interface LoyaltyAccountsResponse {
  teamMember: {
    memberId: string;
    loyaltyAccountId: string;
    hasError: boolean;
  };
  flybuys: {
    memberId: string;
    loyaltyAccountId: string;
    hasError: boolean;
    linkFlybuysUrl: string;
  };
}

export interface LoyaltyAccountsUnlinkResponse extends Response {
  error: LoyaltyAccountsUnlinkErrorResponseData;
}

export interface LoyaltyAccountsUnlinkErrorResponseData {
  status: number;
}

export interface FlybuysVerifyResponse {
  returnTo: string | null;
}

export class LoyaltyAccountsUnlinkError
  extends Error
  implements ILoyaltyAccountsUnlinkError
{
  data?: LoyaltyAccountsUnlinkErrorResponseData;
  constructor({ data }: ILoyaltyAccountsUnlinkError) {
    super();
    this.data = data;
  }
}

interface ILoyaltyAccountsUnlinkError {
  data?: LoyaltyAccountsUnlinkErrorResponseData;
}

export interface FlybuysVerifyErrorResponseData {
  returnTo: string;
}

interface IFlybuysVerifyError {
  returnTo?: string;
}

export class FlybuysVerifyError extends Error implements IFlybuysVerifyError {
  returnTo?: string;
  constructor(returnTo?: string) {
    super();
    this.returnTo = returnTo;
  }
}

export async function unlinkLoyaltyAccountNumber(
  loyaltyAccountId: string
): Promise<null> {
  const route = `${loyaltyAccountsBaseRoute}/${loyaltyAccountId}`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "DELETE",
  });

  if (!response.ok) {
    const responseBody = await response.json();
    const errorResponseBody = responseBody as LoyaltyAccountsUnlinkResponse;
    const errorObject = new LoyaltyAccountsUnlinkError({
      data: errorResponseBody.error,
    });
    console.log("Error:" + errorObject);
    throw errorObject;
  }

  return null;
}

export async function verifyFlybuysAccountNumber(
  code: string | null,
  state: string | null
): Promise<void> {
  const route = `${loyaltyAccountsBaseRoute}/flybuys/verify`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify({ code, state }),
  });

  const responseBody = await response.json();
  if (!response.ok) {
    const errorResponseBody = responseBody as FlybuysVerifyErrorResponseData;
    const errorObject = new FlybuysVerifyError(errorResponseBody.returnTo);
    throw errorObject;
  }
  return { ...responseBody };
}

export async function queryLoyaltyAccounts(
  returnTo: string = "",
  action: string = ""
): Promise<LoyaltyAccountsResponse> {
  const loyaltyAccountsUrlWithParams = `${loyaltyAccountsBaseRoute}?flybuysReturnTo=${returnTo}&flybuysAction=${action}`;

  const response = await loginFetch(loyaltyAccountsUrlWithParams, {
    ...defaultRequestConfig,
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}
