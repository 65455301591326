import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "../../common/hooks/useQuery";
import { redirect } from "../../common/utils/NavigationHelpers";
import { verifyFlybuysAccount } from "../../store/flybuys/FlybuysSlice";
import { RootState } from "../../store/Store";
import { PageLoader } from "../common/PageLoader";

const VerifyFlybuysAccount = () => {
  const queryParams = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();

  const { returnToRoute, isFlybuysLinkSuccess } = useSelector(
    (state: RootState) => state.flybuys
  );

  // TODO - resolve why queryParams dependency puts this useEffect in infinite loop
  useEffect(() => {
    const state = queryParams.get("state");
    const code = queryParams.get("code");

    if (!code && !state) {
      history.push("/callback/flybuys/error");
      return;
    }

    dispatch(verifyFlybuysAccount({ code, state }));
    return;
  }, [dispatch]); // eslint-disable-line

  // TODO - resolve why queryParams dependency puts this useEffect in infinite loop
  useEffect(() => {
    if (isFlybuysLinkSuccess === undefined) {
      return;
    }

    const userCancelled = queryParams.get("linkingStatus") === "userCancelled";

    const redirectionHandler = () => {
      if (returnToRoute) {
        redirect(returnToRoute);
        return;
      }
      history.push("/");
    };

    if (isFlybuysLinkSuccess || userCancelled) {
      redirectionHandler();
      return;
    }
    history.push(`/callback/flybuys/error?returnTo=${returnToRoute}`);
  }, [history, returnToRoute, isFlybuysLinkSuccess]); // eslint-disable-line

  return <PageLoader isShown={true} />;
};

export default VerifyFlybuysAccount;
