import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";

import { MessageContainer } from "../../styled/MessageContainer";
import { SettingValue } from "../../styled/SettingValue";
import { MobileVerifiedIndicator } from "./MobileVerifiedIndicator";
import styled from "styled-components";

const Mobile = styled.span`
  padding-right: 6px;
`;

export function MobileNumberDisplay() {
  const { mobile } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  return (
    <SettingValue data-testid="mobile-number-display">
      <MessageContainer>
        <Mobile data-testid="mobile-number-value">{mobile}</Mobile>
        {mobile && <MobileVerifiedIndicator />}
      </MessageContainer>
    </SettingValue>
  );
}
