import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ActionModal } from "./ActionModal";
import {
  setDeleteAddressModalVisibility,
  deleteAddress,
} from "../../store/add-address/AddAddressSlice";

export const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

export function AddressActionModalContainer() {
  const dispatch = useDispatch();

  const { showDeleteAddressModal, addressToBeDeleted } = useSelector(
    (state: RootState) => state.addAddress
  );

  const closeModal = () => {
    dispatch(setDeleteAddressModalVisibility(false));
  };

  const deleteAccountRequest = () => {
    if (addressToBeDeleted) {
      dispatch(deleteAddress(addressToBeDeleted));
    }
  };

  const deleteHeader = `Delete address?`;
  const deleteBlurb = `This will remove the address from all Coles channels.`;
  const deleteYesButton = `Delete address`;
  const deleteNoButton = `Cancel`;

  return (
    <>
      {showDeleteAddressModal && <BackgroundDiv />}
      <ActionModal
        onSuccess={() => deleteAccountRequest()}
        onRequestClose={() => closeModal()}
        isOpen={showDeleteAddressModal}
        shouldCloseOnEsc={true}
        header={deleteHeader}
        blurb={deleteBlurb}
        yesbutton={deleteYesButton}
        nobutton={deleteNoButton}
      />
    </>
  );
}
