import React, { useEffect, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";

import { ErrorText } from "ccp-common-ui-components";

import AppFooter from "../../common/AppFooter";

import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";

import { SubPageHeader } from "../styled/SubPageHeader";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { Layout } from "../styled/Layout";
import { MessageContainer } from "../styled/MessageContainer";

import { getCustomerProfileAction } from "../../store/customer-profile/CustomerProfileActions";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";
import useDebounce from "../../common/hooks/useDebounce";

import {
  cancelPostcodeUpdate,
  PostcodeFields,
  updatePostcode,
} from "../../store/customer-profile/CustomerProfileSlice";

import { AutoSuggestOption } from "../../store/add-edit-postcode/AddEditPostcodeSlice";
import {
  getMatchingPostcodeSuggestions,
  setMatchingPostcodes,
} from "../../store/add-edit-postcode/AddEditPostcodeSlice";

import { CircleCloseAdornment } from "../common/CircleCloseAdornment";
import { WarningIcon } from "../common/WarningIcon";

import {
  AutocompleteDropDownMessage,
  AutocompleteSettingName,
  StyledInput,
  StyledPopper,
} from "../../common/styles/Autocomplete";

import {
  CancelLink,
  CircleCloseContainer,
  VerifyButton,
  VerifyButtonContainer,
} from "./AddEditPostcodePageStyles";

type FormData = {
  postcode: string;
  suburb: string;
  state: string;
};

interface BackButtonProps {
  backButtonUrl: string | undefined;
}

const theme = createTheme({});

const generateInitialAutocompleteValue = (
  originalPostcode?: PostcodeFields
) => {
  if (
    !originalPostcode ||
    (originalPostcode?.suburb === "" &&
      originalPostcode.state === "" &&
      originalPostcode.postcode === "")
  ) {
    return null;
  } else {
    return {
      label: `${originalPostcode.suburb}, ${originalPostcode.state}, ${originalPostcode.postcode}`,
      value: originalPostcode,
    };
  }
};
const AUTOCOMPLETE_INPUT_LIMIT = 3;

export function AddEditPostcodePage({ backButtonUrl }: BackButtonProps) {
  const [userChangedValue, setUserChangedValue] = useState<boolean>(false);
  const [userTriedToSubmit, setUserTriedToSubmit] = useState<boolean>(false);
  const [userInputRequired, setUserInputRequired] = useState<boolean>(false);

  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");
  const originalPostcode = useSelector(
    (state: RootState) => state.customerProfile.profileFields.locality
  );

  const [autocompleteValue, setAutocompleteValue] =
    useState<AutoSuggestOption | null>(
      generateInitialAutocompleteValue(originalPostcode)
    );

  useEffect(() => {
    setAutocompleteValue(generateInitialAutocompleteValue(originalPostcode));
  }, [originalPostcode]);

  const { matchingPostcodes, searchPostcodeFailed } = useSelector(
    (state: RootState) => state.addEditPostcode
  );

  const isAddMode = !originalPostcode;
  const subtitleMessageMode = isAddMode ? "Add" : "Edit";
  const subtitleMessage = `${subtitleMessageMode} my postcode/suburb`;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm<FormData>();

  useEffect(() => {
    register("postcode", { required: true });
    register("suburb", { required: true });
    register("state", { required: true });
  }, [register]);

  const isOptionEqualToValueCheck = (
    option: AutoSuggestOption,
    value: any
  ): boolean => {
    if (option && value && option.value && value.value) {
      return (
        option.value.postcode === value.value.postcode &&
        option.value.state === value.value.state &&
        option.value.suburb === value.value.suburb
      );
    }

    return false;
  };

  const mapAutocompleteValueToLocalityPayload = (
    option: AutoSuggestOption
  ): {
    postcode: string;
    state: string;
    suburb: string;
  } => {
    return {
      postcode:
        option && option.value && option.value.postcode
          ? option.value.postcode
          : "",
      state:
        option && option.value && option.value.state ? option.value.state : "",
      suburb:
        option && option.value && option.value.suburb
          ? option.value.suburb
          : "",
    };
  };

  useEffect(() => {
    if (autocompleteValue) {
      const localityPayload =
        mapAutocompleteValueToLocalityPayload(autocompleteValue);

      setValue("postcode", localityPayload.postcode);
      setValue("suburb", localityPayload.suburb);
      setValue("state", localityPayload.state);
      clearErrors("postcode");
      setUserInputRequired(false);
    } else if (userTriedToSubmit && !searchPostcodeFailed) {
      setUserInputRequired(true);

      setError("postcode", {
        type: "custom",
        message: "Enter a valid suburb or a postcode",
      });
      setValue("postcode", "");
      setValue("suburb", "");
      setValue("state", "");
    } else {
      setValue("postcode", "");
      setValue("suburb", "");
      setValue("state", "");
    }
  }, [
    autocompleteValue,
    searchPostcodeFailed,
    userTriedToSubmit,
    setValue,
    setError,
    clearErrors,
  ]);

  useEffect(() => {
    dispatch(getCustomerProfileAction());
  }, [dispatch]);

  const debouncedAutocompleteInput = useDebounce(autocompleteInput, 300);

  const clearPostcodeSuggestions = () => {
    setAutocompleteInput("");
    setAutocompleteValue(null);

    dispatch(setMatchingPostcodes([]));
  };

  const getPostcodeSuggestions = useCallback(
    (searchTerm: string) => {
      dispatch(getMatchingPostcodeSuggestions({ postcodeText: searchTerm }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      debouncedAutocompleteInput &&
      debouncedAutocompleteInput.length &&
      debouncedAutocompleteInput.length > AUTOCOMPLETE_INPUT_LIMIT
    ) {
      getPostcodeSuggestions(debouncedAutocompleteInput);
    }
  }, [debouncedAutocompleteInput, getPostcodeSuggestions]);

  const { handleNavigation } = useReturnUrl(backButtonUrl);

  const onCancel = () => {
    dispatch(cancelPostcodeUpdate());
    handleNavigation();
  };

  const onSubmit = (data: FormData) => {
    if (!userChangedValue) {
      dispatch(cancelPostcodeUpdate());
      handleNavigation();
      return;
    }

    dispatch(
      updatePostcode({
        suburb: data.suburb,
        state: data.state,
        postcode: data.postcode,
      })
    );

    reset();

    handleNavigation();
  };

  const onAutocompleteInput = (newValue: string, reason: string) => {
    setAutocompleteInput(newValue);
  };

  const autocompleteOnOpen = (event: React.SyntheticEvent) => {
    setAutocompleteOpen(true);
  };

  const autocompleteOnClose = (event: React.SyntheticEvent) => {
    setAutocompleteOpen(false);
  };

  const autocompleteNoResults = (
    <AutocompleteDropDownMessage>
      {autocompleteInput &&
      autocompleteInput.length >= AUTOCOMPLETE_INPUT_LIMIT ? (
        <React.Fragment>
          <WarningIcon />
          {searchPostcodeFailed
            ? "Something went wrong, please retry."
            : "No postcode matches found"}
        </React.Fragment>
      ) : (
        <React.Fragment>Enter postcode/suburb here</React.Fragment>
      )}
    </AutocompleteDropDownMessage>
  );

  return (
    <Layout>
      <SubPageHeader>{subtitleMessage}</SubPageHeader>
      <Container>
        <form
          onSubmit={handleSubmit(onSubmit)}
          data-testid="add-edit-postcode-form"
          noValidate
        >
          <InputDiv>
            <AutocompleteSettingName htmlFor="postcode-autocomplete">
              Postcode/suburb
            </AutocompleteSettingName>
            <ThemeProvider theme={theme}>
              <Autocomplete
                filterOptions={(x) => x}
                id="postcode-autocomplete"
                data-testid="postcode-autocomplete-test"
                inputValue={autocompleteInput}
                value={autocompleteValue}
                isOptionEqualToValue={isOptionEqualToValueCheck}
                onChange={(event, value, reason) => {
                  if (value && typeof value !== "string") {
                    setAutocompleteValue(value && value.value ? value : null);
                    setUserChangedValue(true);
                  }
                }}
                onClose={autocompleteOnClose}
                options={matchingPostcodes}
                onInputChange={(event, newValue, reason) => {
                  onAutocompleteInput(newValue, reason);
                }}
                onOpen={autocompleteOnOpen}
                open={autocompleteOpen}
                noOptionsText={autocompleteNoResults}
                PopperComponent={StyledPopper}
                renderInput={(params) => (
                  <StyledInput
                    endAdornment={
                      <InputAdornment position="end">
                        {autocompleteInput && autocompleteInput.length > 0 ? (
                          <CircleCloseContainer
                            aria-label="Clear"
                            title="Clear"
                            onClick={() => {
                              clearPostcodeSuggestions();
                            }}
                          >
                            <CircleCloseAdornment aria-hidden="true" />
                          </CircleCloseContainer>
                        ) : null}
                      </InputAdornment>
                    }
                    ref={params.InputProps.ref}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "chrome-off",
                    }}
                    error={!!errors.postcode}
                  />
                )}
              />
              {userInputRequired && (
                <MessageContainer>
                  <ErrorText id="postcode-error" data-testid="postcode-text">
                    Enter a valid suburb or a postcode
                  </ErrorText>
                </MessageContainer>
              )}
            </ThemeProvider>
          </InputDiv>
          <VerifyButtonContainer>
            <VerifyButton
              data-testid="save-button"
              type="submit"
              onClick={() => {
                setUserTriedToSubmit(true);
                return false;
              }}
            >
              Save postcode/suburb
            </VerifyButton>
            <CancelLink tabIndex={0} onClick={onCancel}>
              Cancel
            </CancelLink>
          </VerifyButtonContainer>
        </form>
      </Container>
      <AppFooter />
    </Layout>
  );
}
