import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { customerProfilePageClosing } from "../customer-profile/CustomerProfileSlice";

export interface AsyncOperationState {
  readonly [key: string]: {
    status: AsyncOperationStatus;
  };
}

export enum AsyncOperationStatus {
  NotStarted,
  Pending,
  Success,
  Failure,
}

export type AsyncOperationKey =
  | "updateFirstName"
  | "updateLastName"
  | "updateMobileNumber";

const initialState: AsyncOperationState = {
  updateFirstName: {
    status: AsyncOperationStatus.NotStarted,
  },
  updateLastName: {
    status: AsyncOperationStatus.NotStarted,
  },
  updateMobileNumber: {
    status: AsyncOperationStatus.NotStarted,
  },
};

const asyncOperationSlice = createSlice({
  name: "asyncOperation",
  initialState,
  reducers: {
    asyncOperationStarting: (
      state,
      action: PayloadAction<AsyncOperationKey>
    ) => {
      state[action.payload].status = AsyncOperationStatus.Pending;
    },
    asyncOperationFinished: (
      state,
      action: PayloadAction<AsyncOperationKey>
    ) => {
      state[action.payload].status = AsyncOperationStatus.Success;
    },
    asyncOperationFailed: (state, action: PayloadAction<AsyncOperationKey>) => {
      state[action.payload].status = AsyncOperationStatus.Failure;
    },
    asyncOperationReset: (state, action: PayloadAction<AsyncOperationKey>) => {
      state[action.payload].status = AsyncOperationStatus.NotStarted;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(customerProfilePageClosing, (state) => {
      state["updateFirstName"].status = AsyncOperationStatus.NotStarted;
      state["updateLastName"].status = AsyncOperationStatus.NotStarted;
      state["updateMobileNumber"].status = AsyncOperationStatus.NotStarted;
    });
  },
});

export const {
  asyncOperationStarting,
  asyncOperationFinished,
  asyncOperationFailed,
  asyncOperationReset,
} = asyncOperationSlice.actions;
export const asyncOperationReducer = asyncOperationSlice.reducer;
