import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";

import {
  MobileVerificationSendCodeRequest,
  MobileVerificationVerifyCodeRequest,
  MobileVerificationVerifyCodeResponse,
  sendCode,
  verifyCode,
} from "../../api/verifyMobileNumber/MobileVerificationApiClient";
import { oneTimeCodeVerificationFailedMessage } from "../../common/constants/ErrorMessages";
import {
  sendCodeFailed,
  sendCodeSuccess,
  SendCodeArgs,
  verifyCodeFailed,
  VerifyCodeArgs,
} from "./VerifyMobileNumberSlice";
import { history } from "../../common/utils/history";
import { getVerifyMobileErrorMessage } from "./getVerifyMobileErrorMessage";
import { RootState } from "../Store";

export function* sendCodeSaga(action: PayloadAction<SendCodeArgs>) {
  try {
    const request: MobileVerificationSendCodeRequest = {
      mobile: action.payload.mobile,
    };
    yield call(sendCode, request);
    yield put(sendCodeSuccess());
    yield call(navigateToVerifyMobilePageSaga);
  } catch (error) {
    const errorMessage = getVerifyMobileErrorMessage(error);
    yield put(sendCodeFailed(errorMessage));
    if (history.location.pathname === "/add-edit-mobile") {
      yield call(navigateToVerifyMobileErrorPageSaga);
    }
  }
}

export function* verifyCodeSaga(action: PayloadAction<VerifyCodeArgs>) {
  try {
    const newMfaPreference: string = yield select(
      (state: RootState) => state.verifyMobileNumber.newMfaPreference
    );

    const request: MobileVerificationVerifyCodeRequest = {
      verificationCode: action.payload.verificationCode,
      channel: action.payload.channel,
      mfaPreference: newMfaPreference,
    };

    const response: MobileVerificationVerifyCodeResponse = yield call(
      verifyCode,
      request
    );

    if (response.data.verified) {
      yield call(navigateToAcceptedCodeSaga);
    } else {
      yield put(
        verifyCodeFailed({
          errorMessage: oneTimeCodeVerificationFailedMessage,
          canRetry: true,
        })
      );
    }
  } catch (error) {
    const errorMessage = getVerifyMobileErrorMessage(error);
    yield put(
      verifyCodeFailed({
        errorMessage,
        canRetry: false,
      })
    );
    yield call(navigateToVerifyMobileErrorPageSaga);
  }
}

export function* navigateToAcceptedCodeSaga() {
  yield history.push("/verification-code-accepted");
}

export function* navigateToVerifyMobilePageSaga() {
  yield history.push("/verify-mobile-number");
}

export function* navigateToVerifyMobileErrorPageSaga() {
  yield history.push("/verify-mobile-error");
}
