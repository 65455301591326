import { ThemedButton } from "ccp-common-ui-components";
import { useEffect } from "react";

import SuccessIcon from "../../assets/icons/icon_check-success.svg";
import {
  DeleteAccountStandardIcon,
  DeleteAccountSingularButton,
  DeleteAccountText,
} from "./DeleteAccountStyles";
import { dataLayerDeleteSuccessImpression } from "../../common/utils/tracking/deleteAccountPageTracking";

export function DeleteAccountSuccess({ returnTo }: { returnTo: string }) {
  useEffect(() => {
    dataLayerDeleteSuccessImpression(returnTo);
  }, [returnTo]);

  const cancelAccountDelete = () => {
    window.location.assign(returnTo);
  };

  return (
    <>
      <DeleteAccountStandardIcon src={SuccessIcon} alt="success" />
      <h2>Request sent</h2>
      <DeleteAccountText>
        We&#39;ll delete your account within 10 business days. You can keep
        using your account until it’s deleted.
      </DeleteAccountText>
      <DeleteAccountSingularButton>
        <ThemedButton onClick={cancelAccountDelete}>Done</ThemedButton>
      </DeleteAccountSingularButton>
    </>
  );
}
