import styled from "styled-components";

export interface IconPlusProps {
  size?: string;
  margin?: string;
}

const PlusIcon = styled.span`
  &::before {
    content: "\\002B";
    margin: ${(props: IconPlusProps) => props.margin || "0"};
    vertical-align: bottom;
    font-weight: 900;
    font-size: ${(props: IconPlusProps) => props.size || "inherit"};
  }
`;

export function IconPlus(props: IconPlusProps) {
  return <PlusIcon margin={props.margin} size={props.size} />;
}
