import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoyaltyAccountsResponse } from "../../api/loyaltyAccounts/LoyaltyAccountsApiClient";

export interface LoyaltyAccounts extends LoyaltyAccountsResponse {
  isLoadingLoyaltyAccount: boolean;
}

export const initialState: LoyaltyAccounts = {
  teamMember: {
    memberId: "",
    loyaltyAccountId: "",
    hasError: false,
  },
  flybuys: {
    memberId: "",
    loyaltyAccountId: "",
    hasError: false,
    linkFlybuysUrl: "",
  },
  isLoadingLoyaltyAccount: true,
};

export interface LoyaltyAccountFields {
  teamMember: TeamMemberFields;
  flybuys: FlybuysFields;
}

export interface TeamMemberFields {
  memberId: string;
  loyaltyAccountId: string;
  hasError: boolean;
}

export interface GetLoyaltyAccountsPayload {
  returnTo?: string;
  action?: string;
}

export interface FlybuysFields {
  memberId: string;
  loyaltyAccountId: string;
  hasError: boolean;
  linkFlybuysUrl: string;
}

const loyaltyAccountsSlice = createSlice({
  name: "loyaltyAccounts",
  initialState,
  reducers: {
    storeLoyaltyAccounts: (
      state,
      { payload }: PayloadAction<LoyaltyAccountFields>
    ) => {
      state.teamMember = payload.teamMember;
      state.flybuys = payload.flybuys;
    },
    updateIsLoadingLoyaltyAccounts: (state, action: PayloadAction<boolean>) => {
      state.isLoadingLoyaltyAccount = action.payload;
    },
    updateLoyaltyAccountsError: (state, action: PayloadAction<boolean>) => {
      state.teamMember.hasError = action.payload;
      state.flybuys.hasError = action.payload;
    },
  },
});

export const {
  storeLoyaltyAccounts,
  updateIsLoadingLoyaltyAccounts,
  updateLoyaltyAccountsError,
} = loyaltyAccountsSlice.actions;

export const loyaltyAccountsReducer = loyaltyAccountsSlice.reducer;
