import styled from "styled-components";

import locationPinIcon from "../../../assets/icons/red.svg";

const LocationPinIconImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 2px;
`;

export function LocationPinAdornment() {
  return <LocationPinIconImage src={locationPinIcon} alt="location-pin" />;
}
