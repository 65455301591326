import appConfig from "../../config/Configuration";
import { loginFetch } from "./../LoginFetch";

const unsubscribeUrl =
  appConfig.serverUrl +
  "/api/v1/customer-profile/preferences/marketing/unsubscribe";

export interface UnsubscribeResponse {
  statusCode?: number;
  message?: string;
}

export interface UnsubscribePayload {
  profileData: string;
  targetPreference: string;
}

export interface TargetPreferenceDetails {
  brand: string;
  content: string;
  mode: string;
}

const unsubscribeRequestConfig: RequestInit = {
  mode: "cors",
  cache: "no-cache",
  headers: {
    "Content-Type": "application/json",
  },
};

export async function unsubscribePreferences(
  payload: UnsubscribePayload
): Promise<boolean> {
  const body = {
    profileData: payload.profileData,
    targetPreference: payload.targetPreference,
  };

  const response = await loginFetch(unsubscribeUrl, {
    ...unsubscribeRequestConfig,
    method: "POST",
    body: JSON.stringify(body),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return response.ok;
  }
}

export async function getTargetPreferenceDetails(
  targetPreference: string
): Promise<string> {
  const infoUrl = unsubscribeUrl + "/info";
  const response = await loginFetch(infoUrl, {
    ...unsubscribeRequestConfig,
    method: "POST",
    body: JSON.stringify({ targetPreference }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return response.json();
  }
}
