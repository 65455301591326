import { useSelector } from "react-redux";
import { InputDiv } from "../../styled/InputDiv";
import { Banner } from "ccp-common-ui-components";
import { RootState } from "../../../store/Store";
import styled from "styled-components";

interface AddAddressBannerProps {
  isShown: boolean;
}

const StyledBanner = styled.div`
  background-color: white;
`;

export function AddAddressBanner(props: AddAddressBannerProps) {
  const successMessage = useSelector(
    (state: RootState) => state.addAddress.saveSuccessMessage
  );
  const errorMessage = useSelector(
    (state: RootState) => state.addAddress.saveFailedMessage
  );
  const isAddressesLimitReached = useSelector(
    (state: RootState) => state.addAddress.isAddressesLimitReached
  );
  const profileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const message = successMessage
    ? successMessage
    : errorMessage
    ? errorMessage
    : "";

  const maximumAddressesAllowed =
    profileType?.toLowerCase() === "personal" ? 20 : 200;

  return (
    <>
      {props.isShown && (
        <InputDiv data-testid="add-address-banner">
          <StyledBanner>
            {isAddressesLimitReached ? (
              <Banner hasError={true}>
                Address not saved because it exceeds the limit of{" "}
                {maximumAddressesAllowed}.<br />
                Delete or edit an existing address to add new details.
              </Banner>
            ) : (
              <Banner hasError={!successMessage}>{message}</Banner>
            )}
          </StyledBanner>
        </InputDiv>
      )}
    </>
  );
}
