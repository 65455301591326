import appConfig from "../../config/Configuration";
import { defaultRequestConfig } from "./../ServerApi";
import { loginFetch } from "./../LoginFetch";

const customerProfilePreferencesUrl =
  appConfig.serverUrl + "/api/v1/customer-profile/preferences";

export interface CustomerProfilePreference {
  group: string;
  name: string;
  type: string;
  value: string;
}

export interface CustomerProfilePatchPreference {
  group: string;
  name: string;
  value: string;
}

export interface GetCustomerProfilePreferencesResponse {
  preferences: CustomerProfilePreference[];
}

export interface UpdateCustomerProfilePreferencesResponse {
  statusCode?: number;
  message?: string;
}

export async function getCustomerProfilePreferences(): Promise<GetCustomerProfilePreferencesResponse> {
  const response = await loginFetch(customerProfilePreferencesUrl, {
    ...defaultRequestConfig,
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function updateCustomerProfilePreferences(
  preferences: CustomerProfilePatchPreference[]
): Promise<boolean> {
  const response = await loginFetch(customerProfilePreferencesUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ preferences }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return response.ok;
  }
}
