import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import createSagaMiddleware from "redux-saga";
import { takeLatest, all } from "redux-saga/effects";
import { checkAuthenticationAsync } from "./check-authentication/CheckAuthenticationSaga";
import { authenticationReducer } from "./check-authentication/CheckAuthenticationSlice";
import { featuresReducer } from "./feature-flags/FeaturesSlice";
import {
  customerProfileReducer,
  updateDateOfBirth,
  updateHomePhone,
  updateMfaPreference,
  verifyLater,
  sendProfileDeleteRequest,
  updatePostcode,
} from "./customer-profile/CustomerProfileSlice";
import {
  getCustomerProfileAsync,
  routeToErrorPage,
} from "./customer-profile/CustomerProfileSaga";
import { updatePasswordAction } from "./update-password/UpdatePasswordActions";
import { updatePasswordSaga } from "./update-password/UpdatePasswordSaga";
import { updatePasswordReducer } from "./update-password/UpdatePasswordSlice";
import { updateFirstNameAction } from "./update-first-name/UpdateFirstNameActions";
import { updateFirstNameSaga } from "./update-first-name/UpdateFirstNameSaga";
import { updateLastNameAction } from "./update-last-name/UpdateLastNameActions";
import { updateLastNameSaga } from "./update-last-name/UpdateLastNameSaga";
import userAcceptancesReducer from "./user-acceptances/UserAcceptancesSlice";
import { asyncOperationReducer } from "./async-operation/AsyncOperationSlice";
import { checkAuthenticationAction } from "./check-authentication/CheckAuthenticationActions";
import {
  renderErrorPageAction,
  getCustomerProfileAction,
} from "./customer-profile/CustomerProfileActions";
import { preferredStoreReducer } from "./query-preferred-store/QueryPreferredStoreSlice";
import { updateDateOfBirthSaga } from "../components/profile/DateOfBirthProfileSetting/UpdateDateOfBirthSaga";
import { updateHomePhoneSaga } from "../components/profile/HomePhoneProfileSetting/UpdateHomePhoneSaga";
import { verifyLaterSaga } from "./verify-mobile-later/VerifyMobileLaterSaga";
import { SendProfileDeleteRequestSaga } from "./delete-customer-profile/SendProfileDeleteRequestSaga";
import {
  deleteMobileNumber,
  sendCodeAction,
  verifyCodeAction,
  verifyMobileNumberReducer,
} from "./verify-mobile-number/VerifyMobileNumberSlice";
import {
  sendCodeSaga,
  verifyCodeSaga,
} from "./verify-mobile-number/VerifyMobileNumberSaga";
import { deleteMobileNumberSaga } from "../components/addEditMobileNumber/deleteMobileNumber/DeleteMobileNumberSaga";
import { updateMfaPreferenceSaga } from "../components/profile/MfaSetting/UpdateMfaPreferenceSaga";
import { getFeaturesAction } from "./feature-flags/FeaturesActions";
import { getFeaturesAsync } from "./feature-flags/FeaturesSaga";
import { getCustomerProfileAddressesAction } from "./customer-profile-address/CustomerProfileAddressActions";
import { getCustomerProfileAddressesAsync } from "./customer-profile-address/CustomerProfileAddressSaga";
import { customerProfileAddressReducer } from "./customer-profile-address/CustomerProfileAddressSlice";
import {
  addAddressReducer,
  editAddress,
  getMatchingAddressSuggestions,
  saveAddress,
  deleteAddress,
} from "./add-address/AddAddressSlice";
import {
  deleteAddressSaga,
  editAddressSaga,
  getMatchingAddressSuggestionsAsync,
  saveAddressSaga,
} from "./add-address/AddAddressSaga";
import {
  deleteAccountReducer,
  deleteAccount,
} from "./delete-customer-profile/view/DeleteAccountSlice";
import { deleteAccountSaga } from "./delete-customer-profile/view/DeleteAccountSaga";
import {
  linkTeamMemberNumberAction,
  teamMemberNumberReducer,
  unlinkTeamMemberNumberAction,
} from "./team-member-number/TeamMemberNumberSlice";
import {
  sendTeamMemberNumberSaga,
  unlinkTeamMemberNumberSaga,
} from "./team-member-number/TeamMemberNumberSaga";
import {
  flybuysReducer,
  unlinkFlybuysAction,
  verifyFlybuysAccount,
} from "./flybuys/FlybuysSlice";
import {
  unlinkFlybuysSaga,
  verifyFlybuysAccountSaga,
} from "./flybuys/FlybuysSaga";
import {
  addEditPostcodeReducer,
  getMatchingPostcodeSuggestions,
} from "./add-edit-postcode/AddEditPostcodeSlice";
import {
  getMatchingPostcodeSuggestionsAsync,
  updatePostcodeSaga,
} from "./add-edit-postcode/AddEditPostcodeSaga";
import { getLoyaltyAccountsAsync } from "./loyalty-accounts/LoyaltyAccountsSaga";
import { getLoyaltyAccountsAction } from "./loyalty-accounts/LoyaltyAccountsActions";
import { loyaltyAccountsReducer } from "./loyalty-accounts/LoyaltyAccountsSlice";
import { getCustomerProfilePreferencesAsync } from "./customer-profile-preferences/CustomerProfilePreferencesSaga";
import { getCustomerProfilePreferencesAction } from "./customer-profile-preferences/CustomerProfilePreferencesActions";
import { customerProfilePreferencesReducer } from "./customer-profile-preferences/CustomerProfilePreferencesSlice";

function* watchGetFeaturesAsync() {
  yield takeLatest(getFeaturesAction, getFeaturesAsync);
}

function* watchGetCustomerProfileAsync() {
  yield takeLatest(getCustomerProfileAction, getCustomerProfileAsync);
}

function* watchGetLoyaltyAccountsAsync() {
  yield takeLatest(getLoyaltyAccountsAction, getLoyaltyAccountsAsync);
}

function* watchUpdatePassword() {
  yield takeLatest(updatePasswordAction, updatePasswordSaga);
}

function* watchUpdateFirstName() {
  yield takeLatest(updateFirstNameAction, updateFirstNameSaga);
}

function* watchUpdateLastName() {
  yield takeLatest(updateLastNameAction, updateLastNameSaga);
}

function* watchRenderErrorPage() {
  yield takeLatest(renderErrorPageAction, routeToErrorPage);
}

function* watchCheckAuthenticationAsync() {
  yield takeLatest(checkAuthenticationAction, checkAuthenticationAsync);
}

function* watchUpdateDateOfBirth() {
  yield takeLatest(updateDateOfBirth, updateDateOfBirthSaga);
}

function* watchUpdateHomePhone() {
  yield takeLatest(updateHomePhone, updateHomePhoneSaga);
}

function* watchUpdateMfaPreference() {
  yield takeLatest(updateMfaPreference, updateMfaPreferenceSaga);
}

function* watchSendCodeSaga() {
  yield takeLatest(sendCodeAction, sendCodeSaga);
}

function* watchVerifyCodeSaga() {
  yield takeLatest(verifyCodeAction, verifyCodeSaga);
}

function* watchVerifyLater() {
  yield takeLatest(verifyLater, verifyLaterSaga);
}

function* watchDeleteMobilePhoneNumber() {
  yield takeLatest(deleteMobileNumber, deleteMobileNumberSaga);
}

function* watchSendProfileDeleteRequest() {
  yield takeLatest(sendProfileDeleteRequest, SendProfileDeleteRequestSaga);
}

function* watchGetCustomerProfileAddressesAsync() {
  yield takeLatest(
    getCustomerProfileAddressesAction,
    getCustomerProfileAddressesAsync
  );
}

function* watchGetMatchingAddressSuggestionsAsync() {
  yield takeLatest(
    getMatchingAddressSuggestions,
    getMatchingAddressSuggestionsAsync
  );
}

function* watchSaveAddress() {
  yield takeLatest(saveAddress, saveAddressSaga);
}

function* watchEditAddress() {
  yield takeLatest(editAddress, editAddressSaga);
}

function* watchDeleteAddress() {
  yield takeLatest(deleteAddress, deleteAddressSaga);
}

function* watchDeleteAccount() {
  yield takeLatest(deleteAccount, deleteAccountSaga);
}

function* watchLinkTeamMemberNumberSaga() {
  yield takeLatest(linkTeamMemberNumberAction, sendTeamMemberNumberSaga);
}

function* watchUnlinkTeamMemberNumberSaga() {
  yield takeLatest(unlinkTeamMemberNumberAction, unlinkTeamMemberNumberSaga);
}

function* watchUnlinkFlybuysSaga() {
  yield takeLatest(unlinkFlybuysAction, unlinkFlybuysSaga);
}

function* watchVerifyFlybuysAccountSaga() {
  yield takeLatest(verifyFlybuysAccount, verifyFlybuysAccountSaga);
}

function* watchUpdatePostcode() {
  yield takeLatest(updatePostcode, updatePostcodeSaga);
}

function* watchGetMatchingPostcodeSuggestionsAsync() {
  yield takeLatest(
    getMatchingPostcodeSuggestions,
    getMatchingPostcodeSuggestionsAsync
  );
}

function* watchGetCustomerProfilePreferencesAsync() {
  yield takeLatest(
    getCustomerProfilePreferencesAction,
    getCustomerProfilePreferencesAsync
  );
}

function* rootSaga() {
  yield all([
    watchGetFeaturesAsync(),
    watchCheckAuthenticationAsync(),
    watchGetCustomerProfileAsync(),
    watchUpdatePassword(),
    watchUpdateFirstName(),
    watchUpdateLastName(),
    watchRenderErrorPage(),
    watchUpdateDateOfBirth(),
    watchUpdateHomePhone(),
    watchUpdateMfaPreference(),
    watchSendCodeSaga(),
    watchVerifyCodeSaga(),
    watchVerifyLater(),
    watchDeleteMobilePhoneNumber(),
    watchSendProfileDeleteRequest(),
    watchGetCustomerProfileAddressesAsync(),
    watchSaveAddress(),
    watchGetMatchingAddressSuggestionsAsync(),
    watchDeleteAccount(),
    watchEditAddress(),
    watchDeleteAddress(),
    watchLinkTeamMemberNumberSaga(),
    watchUnlinkTeamMemberNumberSaga(),
    watchUnlinkFlybuysSaga(),
    watchVerifyFlybuysAccountSaga(),
    watchUpdatePostcode(),
    watchGetMatchingPostcodeSuggestionsAsync(),
    watchGetLoyaltyAccountsAsync(),
    watchGetCustomerProfilePreferencesAsync(),
  ]);
}

// reducers
const reducers = {
  features: featuresReducer,
  authentication: authenticationReducer,
  customerProfile: customerProfileReducer,
  customerProfileAddress: customerProfileAddressReducer,
  preferredStore: preferredStoreReducer,
  updatePassword: updatePasswordReducer,
  userAcceptances: userAcceptancesReducer,
  asyncOperation: asyncOperationReducer,
  verifyMobileNumber: verifyMobileNumberReducer,
  addAddress: addAddressReducer,
  deleteAccount: deleteAccountReducer,
  teamMemberNumber: teamMemberNumberReducer,
  flybuys: flybuysReducer,
  addEditPostcode: addEditPostcodeReducer,
  loyaltyAccounts: loyaltyAccountsReducer,
  customerProfilePreferences: customerProfilePreferencesReducer,
};

export const rootReducer = combineReducers({ ...reducers });
export type RootState = ReturnType<typeof rootReducer>;

export function createStore() {
  // middlewares
  const sagaMiddleware = createSagaMiddleware();
  // store
  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
  });
  sagaMiddleware.run(rootSaga);
  return store;
}

export default createStore();
