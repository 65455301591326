import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";

import { MessageContainer } from "../../styled/MessageContainer";
import { SettingValue } from "../../styled/SettingValue";
import styled from "styled-components";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { UpdateFailureMessage } from "../UpdateFailureMessage";

const Postcode = styled.span`
  flex-basis: 100%;
`;

export function PostcodeDisplay() {
  const { locality } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const isPostcodeUpdated = useSelector(
    (state: RootState) => state.customerProfile.isPostcodeUpdated
  );
  const errorMessage = useSelector(
    (state: RootState) => state.customerProfile.postcodeErrorMessage
  );

  const formattedPostcode = locality
    ? `${locality.suburb}, ${locality.state}, ${locality.postcode}`
    : "";
  return (
    <SettingValue data-testid="postcode-display">
      <MessageContainer>
        <Postcode data-testid="postcode-value">{formattedPostcode}</Postcode>
        {isPostcodeUpdated && (
          <UpdateSuccessMessage
            data-testid="update-success-message"
            text="Your postcode/suburb has been updated."
          />
        )}

        {errorMessage && (
          <UpdateFailureMessage data-testid="update-failure-message">
            {errorMessage}
          </UpdateFailureMessage>
        )}
      </MessageContainer>
    </SettingValue>
  );
}
