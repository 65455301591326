import styled from "styled-components";

import deleteIcon from "../../../assets/icons/delete-icon.svg";

const DeleteIconImage = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 4px;
  margin-bottom: -3px;
`;

export function DeleteIcon() {
  return <DeleteIconImage src={deleteIcon} alt="delete" />;
}
