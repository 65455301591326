import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { FeaturesResponseItem } from "./../../store/feature-flags/FeaturesSlice";

const UseBcpIdentityProviderFeatureName = "UseBcpIdentityProvider";

export interface FeatureFlags {
  isBcpFeatureEnabled: boolean;
}

const defaultFeatureFlags: FeatureFlags = {
  isBcpFeatureEnabled: false,
};

export function useFeatureFlags(): FeatureFlags {
  const featuresResponse = useSelector((state: RootState) => state.features);

  if (!featuresResponse.items || featuresResponse.items.length === 0) {
    return defaultFeatureFlags;
  }

  const features: Record<string, boolean> = featuresResponse.items.reduce(
    (record: Record<string, boolean>, item: FeaturesResponseItem) => {
      record[item.feature] = item.isEnabled;
      return record;
    },
    {}
  );

  return {
    isBcpFeatureEnabled: features[UseBcpIdentityProviderFeatureName],
  };
}
