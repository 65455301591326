import { FlybuysVerifyError } from "../../api/loyaltyAccounts/LoyaltyAccountsApiClient";

export function getFlybuysVerifyReturnTo(error: unknown) {
  try {
    if (error instanceof FlybuysVerifyError) {
      return error.returnTo || "";
    }
    return "";
  } catch {
    return "";
  }
}
