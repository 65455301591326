import { SettingValue } from "../../styled/SettingValue";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { RootState } from "../../../store/Store";
import { useSelector } from "react-redux";
import { UpdateFailureMessage } from "../UpdateFailureMessage";
import { screenSize } from "../../../common/styles/ScreenSizes";
import styled from "styled-components";

const MessageContainer = styled.div`
  display: flex;
  max-width: fit-content;
  flex-wrap: wrap;

  @media ${screenSize.mobile} {
    max-width: none;
  }
`;

const DateOfBirthValue = styled.span`
  flex-basis: 100%;
`;

const FailedToUpdate = styled(UpdateFailureMessage)`
  margin-right: 5px;
  letter-spacing: 0;
  line-height: 19px;
`;

export function DateOfBirthDisplay() {
  const { dateOfBirth } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const isDateOfBirthUpdated = useSelector(
    (state: RootState) => state.customerProfile.isDateOfBirthUpdated
  );
  const errorMessage = useSelector(
    (state: RootState) => state.customerProfile.dateOfBirthErrorMessage
  );

  return (
    <SettingValue data-testid="date-of-birth-display">
      <MessageContainer>
        <DateOfBirthValue>{dateOfBirth}</DateOfBirthValue>
        {isDateOfBirthUpdated && (
          <UpdateSuccessMessage
            data-testid="update-success-message"
            text="Your date of birth has been updated."
          />
        )}
        {errorMessage && (
          <FailedToUpdate data-testid="update-failure-message">
            {errorMessage}
          </FailedToUpdate>
        )}
      </MessageContainer>
    </SettingValue>
  );
}
