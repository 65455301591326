import successIcon from "../../assets/icons/icon_check-success.svg";
import errorIcon from "../../assets/icons/error.svg";
import styled from "styled-components";

const StyledIcon = styled.img`
  width: 74px;
  height: 74px;
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledIconWrapper = styled.div`
  position: relative;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`;

export interface IconCircleProps {
  hasError?: boolean;
  decorative?: boolean;
}

export function IconCircle(props: IconCircleProps) {
  const iconSrc = props.hasError ? errorIcon : successIcon;
  const iconText = props.hasError ? "Error icon" : "Success icon";
  const ariaHidden = props.decorative ? "true" : "false";

  return (
    <StyledIconWrapper aria-hidden={ariaHidden}>
      <StyledIcon
        data-testid="icon-circle"
        src={iconSrc}
        alt={iconText}
      ></StyledIcon>
    </StyledIconWrapper>
  );
}
