import appConfig from "../../config/Configuration";
import { loginFetch } from "./../LoginFetch";
import { defaultRequestConfig } from "./../ServerApi";

const teamMemberNumberBaseRoute = `${appConfig.serverUrl}/api/v1/loyalty-accounts`;

export interface TeamMemberNumberLinkRequest {
  memberId: string;
}

export interface TeamMemberNumberLinkResponse extends Response {
  loyaltyAccountId: string;
  memberId: string;
}

export interface TeamMemberNumberLinkErrorResponse {
  error: TeamMemberNumberLinkErrorResponseData;
}

export interface TeamMemberNumberUnlinkResponse extends Response {
  error: TeamMemberNumberLinkErrorResponseData;
}

export interface TeamMemberNumberLinkErrorResponseData {
  status: number;
}
export class TeamMemberNumberLinkError
  extends Error
  implements ITeamMemberNumberLinkError
{
  data?: TeamMemberNumberLinkErrorResponseData;
  constructor({ data }: ITeamMemberNumberLinkError) {
    super();
    this.data = data;
  }
}

interface ITeamMemberNumberLinkError {
  data?: TeamMemberNumberLinkErrorResponseData;
}

export async function linkTeamMemberNumber(
  request: TeamMemberNumberLinkRequest
): Promise<TeamMemberNumberLinkResponse> {
  const route = teamMemberNumberBaseRoute;

  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(request),
  });

  const responseBody = await response.json();
  if (!response.ok) {
    const errorObject = new TeamMemberNumberLinkError({
      data: { status: response.status },
    });
    throw errorObject;
  }
  return { ...responseBody };
}
