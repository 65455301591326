import appConfig from "../config/Configuration";
import { loginFetch } from "./LoginFetch";
import { FeaturesResponseItem } from "../store/feature-flags/FeaturesSlice";

const authenticationUrl = appConfig.serverUrl + "/api/v1/auth";
const authenticationWithStateUrl = appConfig.serverUrl + "/api/v1/auth/state";

const updatePasswordUrl = appConfig.serverUrl + "/api/v1/password";
const featuresEndpointUrl = appConfig.serverUrl + "/api/v1/features";

export const defaultRequestConfig: RequestInit = {
  mode: "cors",
  cache: "no-cache",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
};

export interface AuthenticationResponse {
  authenticated: boolean;
  loginUri?: string;
}

export interface Password {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UpdatePasswordResponse {
  errors?: {
    CurrentPassword?: string[];
    NewPassword?: string[];
  };
}

export async function queryAuthenticationStatus(): Promise<AuthenticationResponse> {
  const response = await loginFetch(authenticationUrl, {
    ...defaultRequestConfig,
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function queryAuthenticationStatusWithState(
  state: string
): Promise<AuthenticationResponse> {
  const response = await loginFetch(authenticationWithStateUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify({ state }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}
export async function queryFeatureFlags(): Promise<
  Array<FeaturesResponseItem>
> {
  const response = await loginFetch(featuresEndpointUrl, {
    ...defaultRequestConfig,
    method: "GET",
  });
  if (!response.ok) {
    return new Array<FeaturesResponseItem>();
  } else {
    return await response.json();
  }
}

export async function retrieveAuthenticationToken(code: string): Promise<void> {
  const response = await loginFetch(authenticationUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify({ code }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updatePassword(
  passwords: Password
): Promise<UpdatePasswordResponse> {
  const response = await loginFetch(updatePasswordUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(passwords),
  });

  if (response.status >= 500 && response.status <= 599) {
    throw new Error(response.statusText);
  }

  return !response.ok ? { ...(await response.json()) } : {};
}
