import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CustomerProfileAddressContact {
  key: string;
  value: string;
}

interface CustomerProfileAddressLocation {
  latitude: number;
  longitude: number;
}

export interface CustomerProfileAddressFields {
  id: string;
  nickname: string;
  recipientFirstName: string;
  recipientLastName: string;
  addressType: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  location: CustomerProfileAddressLocation;
  verificationStatus: boolean | null;
  customerNotes: string;
  contacts: Array<CustomerProfileAddressContact>;
}

export interface CustomerProfileAddressState {
  addresses: Array<CustomerProfileAddressFields>;
  links: string | undefined;
  isLoadingAddresses: boolean;
}

export const initialState: CustomerProfileAddressState = {
  addresses: [],
  links: undefined,
  isLoadingAddresses: false,
};

const customerProfileAddressSlice = createSlice({
  name: "customerProfileAddress",
  initialState,
  reducers: {
    storeCustomerProfileAddresses: (
      state,
      { payload }: PayloadAction<Array<CustomerProfileAddressFields>>
    ) => {
      state.addresses = payload;
    },
    storeCustomerProfileAddressLinks: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.links = payload;
    },
    addAddress: (
      state,
      { payload }: PayloadAction<CustomerProfileAddressFields>
    ) => {
      state.addresses = [...state.addresses, payload];
    },
    updateIsLoadingAddress: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingAddresses = payload;
    },
  },
});

export const {
  storeCustomerProfileAddresses,
  updateIsLoadingAddress,
  storeCustomerProfileAddressLinks,
  addAddress,
} = customerProfileAddressSlice.actions;

export const customerProfileAddressReducer =
  customerProfileAddressSlice.reducer;
