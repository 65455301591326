import { ThemedHeader } from "./ThemedHeader";
import { Footer } from "./Footer";
import styled from "styled-components";
import { EmailVerifiedSuccess } from "./EmailVerifiedSuccess";
import { EmailVerifiedFailure } from "./EmailVerifiedFailure";
import { useQuery } from "../../common/hooks/useQuery";

const EmailVerified = styled.div`
  height: 100%;
  display: flex;
`;

const EmailVerifiedBody = styled.div`
  width: 514px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  @media (max-width: 460px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const EmailVerifiedContent = styled.div`
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid #e8e8e8;
  padding: 47px;
  text-align: center;

  @media (max-width: 460px) {
    border: none;
  }
`;

export function EmailVerifiedPage() {
  document.body.style.backgroundColor = "white";

  const query = useQuery();
  const success = query.get("success");

  function getContentBySuccess(success: string | null): JSX.Element {
    if (success === "true") {
      return <EmailVerifiedSuccess />;
    }
    return <EmailVerifiedFailure />;
  }

  return (
    <EmailVerified data-testid="email-verified-page">
      <EmailVerifiedBody>
        <ThemedHeader />
        <EmailVerifiedContent>
          {getContentBySuccess(success)}
        </EmailVerifiedContent>
        <Footer />
      </EmailVerifiedBody>
    </EmailVerified>
  );
}
