import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IconCircle } from "../../common/IconCircle";
import { StyledButtonWrapper } from "../../styled/StyledButtonWrapper";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoContainer,
  InfoPageThemedButton,
} from "../../styled/InfoPage";
import { continueMessage } from "../../../common/constants/InfoMessages";
import { Layout } from "../../styled/Layout";
import { redirect } from "../../../common/utils/NavigationHelpers";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";

export function MfaPreferenceSuccessPage() {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const history = useHistory();

  function handleClick() {
    const linkValue = getReturnToLink();

    if (linkValue !== "/") {
      return redirect(linkValue);
    }

    history.push("/");
  }

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  return (
    <Layout>
      <InfoContainer>
        <IconCircle decorative={true} />
        <CenteredHeader tabIndex={-1} ref={headingRef}>
          Security preference updated
        </CenteredHeader>
        <CenteredParagraph>
          Your security preference has been updated successfully.
        </CenteredParagraph>
        <StyledButtonWrapper>
          <InfoPageThemedButton onClick={() => handleClick()}>
            {continueMessage}
          </InfoPageThemedButton>
        </StyledButtonWrapper>
      </InfoContainer>
    </Layout>
  );
}
