import { call, put } from "redux-saga/effects";
import { deleteCustomerProfile } from "../../../api/profile/CustomerProfileApi";
import {
  deleteAccountFailed,
  deleteAccountSuccess,
} from "./DeleteAccountSlice";

export function* deleteAccountSaga() {
  try {
    yield call(deleteCustomerProfile);
    yield put(deleteAccountSuccess());
  } catch {
    yield put(deleteAccountFailed());
  }
}
