import { Link } from "react-router-dom";
import styled from "styled-components";

interface RouterLinkProps {
  color?: string;
}

export const RouterLink = styled(Link)<RouterLinkProps>`
  font-weight: 600;
  color: ${(props) => props.color ?? "inherit"};
`;
