import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomerProfilePreference } from "../../api/preferences/CustomerProfilePreferencesApi";

export interface MarketingPreference {
  group: string;
  name: string;
  type: string;
  brand: string;
  content: string;
  mode: string;
  value: boolean;
}

export interface ReceiptPreference {
  group: string;
  name: string;
  type: string;
  brand: string;
  content: string;
  mode: string;
  value: boolean;
}

export interface SecondaryEmailInfo {
  group: string;
  name: string;
  type: string;
  brand: string;
  content: string;
  mode: string;
  value: string;
}

export interface MarketingPreferencesBrand {
  brand: string;
  preferences: Array<MarketingPreference>;
}

export interface ReceiptPreferencesBrand {
  brand: string;
  preferences: Array<ReceiptPreference>;
}

export interface CustomerProfilePreferencesState {
  preferences: Array<CustomerProfilePreference>;
  marketingPreferences: Array<MarketingPreference>;
  receiptPreferences: Array<ReceiptPreference>;
  secondaryEmail: SecondaryEmail;
  isLoadingPreferences: boolean;
  isShowingSecondaryEmailSection: boolean;
}

export interface SecondaryEmail {
  id: string;
  emailValue: string;
}

export const initialState: CustomerProfilePreferencesState = {
  preferences: [],
  marketingPreferences: [],
  receiptPreferences: [],
  secondaryEmail: {} as SecondaryEmail,
  isLoadingPreferences: false,
  isShowingSecondaryEmailSection: false,
};

const customerProfilePreferencesSlice = createSlice({
  name: "customerProfilePreferences",
  initialState,
  reducers: {
    storeCustomerProfilePreferences: (
      state,
      { payload }: PayloadAction<Array<CustomerProfilePreference>>
    ) => {
      state.preferences = payload;
    },
    storeMarketingPreferences: (
      state,
      { payload }: PayloadAction<Array<MarketingPreference>>
    ) => {
      state.marketingPreferences = payload;
    },
    storeReceiptPreferences: (
      state,
      { payload }: PayloadAction<Array<ReceiptPreference>>
    ) => {
      state.receiptPreferences = payload;
    },
    storeSecondaryEmail: (
      state,
      { payload }: PayloadAction<SecondaryEmail>
    ) => {
      state.secondaryEmail = payload;
    },
    updateIsLoadingPreferences: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingPreferences = payload;
    },
    updateIsShowingSecondaryEmailSection: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isShowingSecondaryEmailSection = payload;
    },
  },
});

export const {
  storeCustomerProfilePreferences,
  storeMarketingPreferences,
  storeReceiptPreferences,
  storeSecondaryEmail,
  updateIsLoadingPreferences,
  updateIsShowingSecondaryEmailSection,
} = customerProfilePreferencesSlice.actions;

export const customerProfilePreferencesReducer =
  customerProfilePreferencesSlice.reducer;
