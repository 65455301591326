import { ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const DeleteAccountContainer = styled.div`
  background-color: white;
  width: 100%;
`;

export const DeleteAccountContent = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 40px;
`;

export const DeleteAccountButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2em;

  & button:first-child {
    margin-right: 1em;
  }

  @media ${screenSize.mobile} {
    flex-direction: column;

    & button:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const DeleteAccountYesButton = styled(ThemedButton)`
  background-color: white;
  border-color: black;
  color: black;
`;

export const DeleteAccountStandardIcon = styled.img`
  width: 42px;
  height: 42px;
`;

export const DeleteAccountSingularButton = styled.div`
  padding-top: 2em;
`;

export const DeleteAccountText = styled.p`
  text-align: center;
`;
