import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TeamMemberNumberState {
  isSendteamMemberNumberLinkRequest: boolean;
  isTeamMemberNumberLinkSuccess?: boolean;
  isUnlinkModelOpen: boolean;
  isTeamMemberNumberUnlinkSuccess?: boolean;
  isUnlinkRequestLoading: boolean;
}

export const initialState: TeamMemberNumberState = {
  isSendteamMemberNumberLinkRequest: false,
  isTeamMemberNumberLinkSuccess: undefined,
  isUnlinkModelOpen: false,
  isTeamMemberNumberUnlinkSuccess: undefined,
  isUnlinkRequestLoading: false,
};

export type TeamMemberNumberArgs = {
  memberId: string;
};

export type UnlinkActionPayload = {
  loyaltyAccountId: string;
};

const teamMemberNumberSlice = createSlice({
  name: "teamMemberNumber",
  initialState,
  reducers: {
    linkTeamMemberNumberAction: (
      state,
      action: PayloadAction<TeamMemberNumberArgs>
    ) => {
      state.isSendteamMemberNumberLinkRequest = true;
      state.isTeamMemberNumberLinkSuccess = undefined;
    },
    teamMemberNumberLinkSuccess: (state: any) => {
      state.isSendteamMemberNumberLinkRequest = false;
      state.isTeamMemberNumberLinkSuccess = true;
    },
    teamMemberNumberLinkFailed: (state) => {
      state.isTeamMemberNumberLinkSuccess = false;
      state.isSendteamMemberNumberLinkRequest = false;
    },
    setIsUnlinkModelOpen: (state, action: PayloadAction<boolean>) => {
      state.isUnlinkModelOpen = action.payload;
    },
    unlinkTeamMemberNumberAction: (state, action) => {
      state.isUnlinkRequestLoading = true;
      state.isUnlinkModelOpen = false;
      state.isTeamMemberNumberUnlinkSuccess = undefined;
    },
    updateIsUnlinkRequestLoading: (state, action: PayloadAction<boolean>) => {
      state.isUnlinkRequestLoading = action.payload;
      state.isTeamMemberNumberUnlinkSuccess = undefined;
    },
    setTeamMemberNumberUnlinkSuccess: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isTeamMemberNumberUnlinkSuccess = action.payload;
      state.isUnlinkRequestLoading = false;
    },
    resetTeamMemberNumberSuccessStatus: (state) => {
      state.isTeamMemberNumberLinkSuccess = undefined;
    },
  },
});

export const {
  teamMemberNumberLinkSuccess,
  teamMemberNumberLinkFailed,
  linkTeamMemberNumberAction,
  setIsUnlinkModelOpen,
  unlinkTeamMemberNumberAction,
  updateIsUnlinkRequestLoading,
  setTeamMemberNumberUnlinkSuccess,
  resetTeamMemberNumberSuccessStatus,
} = teamMemberNumberSlice.actions;

export const teamMemberNumberReducer = teamMemberNumberSlice.reducer;
