import React from "react";
import styled from "styled-components";

import { screenSize } from "./common/styles/ScreenSizes";
import AppHeader from "./common/AppHeader";
import { PageLoader } from "./components/common/PageLoader";

const MainColumn = styled.main`
  flex-grow: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin: 24px;
  @media ${screenSize.mobile} {
    align-self: stretch;
  }
`;

interface PageLayoutProps {
  backButtonUrl?: string;
  isLoading: boolean;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  backButtonUrl,
  isLoading,
  children,
}) => {
  return (
    <>
      {isLoading ? (
        <MainColumn>
          <AppHeader backButtonUrl={backButtonUrl} />
          <PageLoader isShown={isLoading} />
        </MainColumn>
      ) : (
        <MainColumn>
          <AppHeader backButtonUrl={backButtonUrl} />
          {children}
        </MainColumn>
      )}
    </>
  );
};

export default PageLayout;
