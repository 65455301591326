import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { Settings } from "../profile/ProfileSettings";

export const StyledIconWrapper = styled.div`
  position: relative;
  height: 60px;
`;

export const StyledStatusIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const CenteredHeader = styled.h2`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  text-align: center;
  color: #e01a22;
  font-family: "Avant Garde";
`;

export const CenteredParagraph = styled.p`
  color: ${headers.black900};
  opacity: 0.85;
  font-family: "Source Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;

export const ChangePasswordErrorContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 50px 25px;
`;

export const ButtonContainer = styled.div`
  text-align: center;
`;
