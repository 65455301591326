import React from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import warningCircle from "../../../assets/icons/warning-circle.svg";
import tickCircle from "../../../assets/icons/icon_check-success.svg";
import styled from "styled-components";
import { headers } from "../../../common/styles/RocketColours";
import { RouterLink } from "../../styled/RouterLink";
import { useFeatureFlags } from "../../../common/feature-flags/UseFeatureFlags";

interface MobileVerifiedProps {
  isVerified?: boolean;
}
const MobileVerified = styled.div<MobileVerifiedProps>`
  flex: 0 0 auto;
  color: ${(props) => (props.isVerified ? headers.success : headers.error)};
`;

const TickIcon = styled.img`
  vertical-align: text-bottom;
`;

const WarningIcon = styled.img`
  vertical-align: text-bottom;
  padding-bottom: 1px;
`;

const VerifiedText = styled.span`
  padding-left: 4px;
  font-weight: 600;
`;

export function MobileVerifiedIndicator() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const { isMobileVerified } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  return (
    <MobileVerified
      isVerified={isMobileVerified}
      data-testid="mobile-verified-indicator"
    >
      {isMobileVerified ? (
        <TickIcon src={tickCircle} alt="" aria-hidden />
      ) : (
        <WarningIcon src={warningCircle} alt="" aria-hidden />
      )}
      <VerifiedText>
        {isMobileVerified ? "Verified" : "Unverified. "}
      </VerifiedText>
      {!isBcpFeatureEnabled && !isMobileVerified && (
        <RouterLink
          color={headers.primary}
          to="/add-edit-mobile"
          data-testid="add-edit-mobile"
        >
          Verify my number
        </RouterLink>
      )}
    </MobileVerified>
  );
}
