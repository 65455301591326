import { call, put, select } from "redux-saga/effects";
import { RootState } from "../Store";
import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import { history } from "../../common/utils/history";
import {
  getCustomerProfilePreferences,
  GetCustomerProfilePreferencesResponse,
} from "../../api/preferences/CustomerProfilePreferencesApi";
import {
  storeCustomerProfilePreferences,
  storeMarketingPreferences,
  storeReceiptPreferences,
  storeSecondaryEmail,
  updateIsLoadingPreferences,
  updateIsShowingSecondaryEmailSection,
} from "./CustomerProfilePreferencesSlice";
import {
  generateMarketingPreferences,
  generateReceiptPreferences,
  getOnlineShoppingEmailValue,
  getSecondaryEmailInfo,
} from "./CustomerProfilePreferencesUtils";
import {
  CustomerProfileContact,
  getCustomerProfileContactById,
} from "../../api/contacts/CustomerProfileContactsApi";

export function* getCustomerProfilePreferencesAsync() {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      yield put(updateIsLoadingPreferences(true));
      const response: GetCustomerProfilePreferencesResponse = yield call(
        getCustomerProfilePreferences
      );
      const preferences = response.preferences;
      const marketingPreferences = generateMarketingPreferences(preferences);
      const receiptPreferences = generateReceiptPreferences(preferences);
      const secondaryEmailDetails = getSecondaryEmailInfo(preferences);
      const onlineEmail = getOnlineShoppingEmailValue(preferences);

      if (secondaryEmailDetails && secondaryEmailDetails.value) {
        const secondaryEmail: CustomerProfileContact = yield call(
          getCustomerProfileContactById,
          secondaryEmailDetails.value
        );
        yield put(
          storeSecondaryEmail({
            id: secondaryEmailDetails.value,
            emailValue: secondaryEmail.value,
          })
        );
      } else {
        yield put(
          storeSecondaryEmail({
            id: "",
            emailValue: "",
          })
        );
      }
      yield put(storeCustomerProfilePreferences(preferences));
      if (marketingPreferences) {
        yield put(storeMarketingPreferences(marketingPreferences));
      }
      if (receiptPreferences) {
        yield put(storeReceiptPreferences(receiptPreferences));
      }
      if (onlineEmail) {
        yield put(
          updateIsShowingSecondaryEmailSection(onlineEmail.value === "true")
        );
      }
      yield put(updateIsLoadingPreferences(false));
    } catch {
      yield call(routeToErrorPage);
    }
  }
}

export function* routeToErrorPage() {
  yield history.push("/error");
}
