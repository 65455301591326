import styled from "styled-components";

export const LineLabel = styled.label`
  flex: 0 0 auto;
  order: 1;
  display: block;
  color: #000000;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
`;
