import { useSelector } from "react-redux";
import { Redirect, useRouteMatch } from "react-router";
import { Route } from "react-router-dom";

import { RootState } from "../../store/Store";
import FlybuysErrorPage from "./FlybuysErrorPage";
import FlybuysIntegration from "./FlybuysIntegration";

const FlybuysIntegrationPage = () => {
  const { path } = useRouteMatch();

  const { isFlybuysRouteSuccess } = useSelector(
    (state: RootState) => state.flybuys
  );

  return (
    <>
      <Route exact path={`${path}/error`}>
        {typeof isFlybuysRouteSuccess !== "undefined" ? (
          <FlybuysErrorPage />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route exact path={path}>
        <FlybuysIntegration />
      </Route>
    </>
  );
};

export default FlybuysIntegrationPage;
