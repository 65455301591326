import { loginFetch } from "../LoginFetch";
import { defaultRequestConfig } from "../ServerApi";
import appConfig from "../../config/Configuration";
import { ErrorResponse } from "../../common/error/ErrorResponse";

const customerProfileAddressesUrl =
  appConfig.serverUrl + "/api/v1/customer-profile/addresses";

export interface QueryCustomerProfileAddressesResponse {
  data: Array<CustomerProfileAddress>;
  links: CustomerProfileAddressesResponseLinks | undefined;
}

export interface CustomerProfileAddressesResponseLinks {
  next: string;
}

export interface SaveCustomerProfileAddressResponse
  extends CustomerProfileAddress {}

export interface CustomerProfileAddress {
  id: string;
  nickname: string;
  recipientFirstName: string;
  recipientLastName: string;
  addressType: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  postcode: string;
  city: string;
  state: string;
  country: string;
  location: {
    latitude: number;
    longitude: number;
  };
  verificationStatus: boolean | null;
  customerNotes: string;
  contacts: [
    {
      key: string;
      value: string;
    },
    {
      key: string;
      value: string;
    }
  ];
}

export async function queryCustomerProfileAddressesWithOffsetAndLimit(
  optionalLink?: string
): Promise<QueryCustomerProfileAddressesResponse> {
  const actualUrl = optionalLink
    ? appConfig.serverUrl + optionalLink
    : customerProfileAddressesUrl + "?offset=0&limit=10";

  const response = await loginFetch(actualUrl, {
    ...defaultRequestConfig,
    method: "GET",
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export interface SaveAddressRequest {
  Nickname: string;
  GlobalAddressKey: string;
  RecipientFirstName: string;
  RecipientLastName: string;
  Email: string;
  Phone: string;
}

export interface EditAddressRequest {
  Id: string;
  CustomerNotes: string;
  Nickname: string;
  GlobalAddressKey: string;
  RecipientFirstName: string;
  RecipientLastName: string;
  Email: string;
  Phone: string;
}

export interface DeleteAddressRequest {
  Id: string;
  Nickname: string;
}

export async function saveAddress(
  request: SaveAddressRequest
): Promise<SaveCustomerProfileAddressResponse | ErrorResponse> {
  const response = await loginFetch(customerProfileAddressesUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(request),
  });

  if (!response.ok && response.status !== 422) {
    throw new Error(response.statusText);
  }
  if (response.status === 422) {
    const error = (await response.json()) as ErrorResponse;
    const addressLimitReachedError = error.errors.find(
      (e) => e.errorCode === "40405"
    );
    if (addressLimitReachedError) {
      return error;
    } else {
      throw new Error(response.statusText);
    }
  }
  return await response.json();
}

export async function editAddress(
  request: EditAddressRequest
): Promise<SaveCustomerProfileAddressResponse> {
  const response = await loginFetch(customerProfileAddressesUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function deleteAddress(
  request: DeleteAddressRequest
): Promise<void> {
  const deleteUrl = `${customerProfileAddressesUrl}/${request.Id}`;

  const response = await loginFetch(deleteUrl, {
    ...defaultRequestConfig,
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
