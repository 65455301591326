import styled from "styled-components";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { SettingValue } from "../../styled/SettingValue";
import { screenSize } from "../../../common/styles/ScreenSizes";
import editIcon from "../../../assets/icons/edit.svg";
import { CustomerProfileAddressFields } from "../../../store/customer-profile-address/CustomerProfileAddressSlice";
import AddressContacts from "../AddressContacts";
import { SettingButton } from "../../styled/SettingButton";
import {
  setFormVisibility,
  clearAddressNotifications,
} from "../../../store/add-address/AddAddressSlice";
import { useDispatch } from "react-redux";

export const AddressSettings = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  width: 100%;

  margin-bottom: 12px;
  align-self: flex-start;
  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
    width: 100%;
    margin-right: 1px;
    margin-left: 1px;
  }
  @media (min-width: 620px) and (max-width: 1260px) {
    width: 100%;
  }
`;

const AddressSettingLine = styled(SettingLine)`
  flex-direction: column;
  max-width: 100%;
`;

const AddressNicknameLabelContainer = styled.div`
  max-width: 70%;
  @media ${screenSize.mobile} {
    max-width: 60%;
  }
`;

const AddressNicknameLabel = styled(SettingName)`
  flex: 0 0 auto;
  font-size: 16px;
  margin: 0 0 15px 0;

  @media ${screenSize.mobile} {
    flex: 1 1 auto;
    line-height: 20px;
  }
`;

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

const EditAddressLinkContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 18px;
`;

const trimToLength = (value: string, length: number) => {
  return value.substring(0, length);
};

const formatAddressLines = (addressLines: string[]) => {
  return addressLines
    .filter((address) => address && address.length > 0)
    .map((address) => trimToLength(address, 64))
    .join(", ");
};

interface AddressCardProps {
  address: CustomerProfileAddressFields;
  editAddressId: string | null;
  setEditAddress: (arg0: CustomerProfileAddressFields) => void | null;
}

export function AddressCard(props: AddressCardProps) {
  const dispatch = useDispatch();
  const editButton = props.editAddressId === null && (
    <EditAddressLinkContainer>
      <SettingButton
        onClick={() => {
          props.setEditAddress(props.address);
          dispatch(setFormVisibility(true));
          dispatch(clearAddressNotifications());
        }}
      >
        <EditIcon data-testid="edit-icon" src={editIcon} alt="" aria-hidden />
        Edit Address
      </SettingButton>
    </EditAddressLinkContainer>
  );

  return props.editAddressId !== props.address.id ? (
    <AddressSettings>
      <AddressSettingLine>
        {props.address.nickname && (
          <AddressNicknameLabelContainer>
            <AddressNicknameLabel as="h3" data-testid="nickname">
              {trimToLength(props.address.nickname, 100)}
            </AddressNicknameLabel>
          </AddressNicknameLabelContainer>
        )}
        {editButton}
        <SettingValue>
          {trimToLength(props.address.recipientFirstName, 31)}{" "}
          {trimToLength(props.address.recipientLastName, 31)}
        </SettingValue>
        <SettingValue>
          {formatAddressLines([
            props.address.addressLine1,
            props.address.addressLine2,
            props.address.addressLine3,
          ])}
        </SettingValue>
        <SettingValue>{trimToLength(props.address.city, 64)}</SettingValue>
        <SettingValue>
          {trimToLength(props.address.state, 4)},{" "}
          {trimToLength(props.address.postcode, 4)}
        </SettingValue>
      </AddressSettingLine>
      <AddressContacts contacts={props.address.contacts} />
    </AddressSettings>
  ) : (
    <></>
  );
}
