import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { RootState } from "../../../store/Store";

import { SettingLink } from "../../styled/SettingLink";
import { MobileNumberDisplay } from "./MobileNumberDisplay";
import editIcon from "../../../assets/icons/edit.svg";
import styled from "styled-components";
import { useFeatureFlags } from "../../../common/feature-flags/UseFeatureFlags";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

export function MobileNumberSetting() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  const { mobile: originalMobileNumber } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const buttonText = originalMobileNumber
    ? "Edit mobile number"
    : "Add mobile number";

  return (
    <SettingLine>
      <SettingName>Mobile number:</SettingName>
      <MobileNumberDisplay />
      {!isBcpFeatureEnabled && (
        <SettingLink>
          {originalMobileNumber && (
            <EditIcon
              data-testid="edit-icon"
              src={editIcon}
              alt=""
              aria-hidden
            />
          )}
          <Link data-testid="add-edit-mobile-link" to="/add-edit-mobile">
            {buttonText}
          </Link>
        </SettingLink>
      )}
    </SettingLine>
  );
}
