import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { updateCustomerDateOfBirth } from "../../../api/profile/CustomerProfileApi";
import {
  updateDateOfBirthFailed,
  updateDateOfBirthSuccess,
} from "../../../store/customer-profile/CustomerProfileSlice";

export function* updateDateOfBirthSaga(action: PayloadAction<string>) {
  try {
    const dateOfBirth = action.payload;
    yield call(updateCustomerDateOfBirth, dateOfBirth);
    yield put(updateDateOfBirthSuccess(dateOfBirth));
  } catch {
    yield put(
      updateDateOfBirthFailed(
        "We weren’t able to update your date of birth. Please try again later."
      )
    );
  }
}
