import { call, put, select } from "redux-saga/effects";
import { RootState } from "../Store";
import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import { history } from "../../common/utils/history";
import {
  QueryCustomerProfileAddressesResponse,
  queryCustomerProfileAddressesWithOffsetAndLimit,
} from "../../api/address/AddressesApi";
import {
  storeCustomerProfileAddresses,
  storeCustomerProfileAddressLinks,
  updateIsLoadingAddress,
} from "./CustomerProfileAddressSlice";

export function* getCustomerProfileAddressesAsync() {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      const response: QueryCustomerProfileAddressesResponse = yield call(
        queryCustomerProfileAddressesWithOffsetAndLimit
      );
      yield put(storeCustomerProfileAddresses(response.data));
      yield put(storeCustomerProfileAddressLinks(response.links?.next));
      yield put(updateIsLoadingAddress(false));
    } catch {
      yield call(routeToErrorPage);
    }
  }
}

export function* routeToErrorPage() {
  yield history.push("/error");
}
