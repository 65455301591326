import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQuery } from "../../common/hooks/useQuery";
import { PageLoader } from "../common/PageLoader";
import { getLoyaltyAccountsAction } from "../../store/loyalty-accounts/LoyaltyAccountsActions";

const FlybuysIntegration = () => {
  const dispatch = useDispatch();
  const queryParams = useQuery();

  const returnTo = queryParams.get("returnTo") || "";
  const action = queryParams.get("action") || "";

  useEffect(() => {
    dispatch(
      getLoyaltyAccountsAction({
        returnTo: encodeURIComponent(returnTo),
        action: encodeURIComponent(action),
      })
    );
  }, [dispatch, returnTo, action]);

  return <PageLoader isShown={true} />;
};

export default FlybuysIntegration;
