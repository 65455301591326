import { call, put, select } from "redux-saga/effects";
import {
  queryCustomerProfile,
  QueryCustomerProfileResponse,
} from "../../api/profile/CustomerProfileApi";
import { RootState } from "../Store";
import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import {
  storeCustomerProfile,
  updateIsLoadingCustomerProfile,
} from "./CustomerProfileSlice";
import { storePreferredStore } from "../query-preferred-store/QueryPreferredStoreSlice";
import { history } from "../../common/utils/history";

export function* getCustomerProfileAsync() {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      const response: QueryCustomerProfileResponse = yield call(
        queryCustomerProfile
      );
      yield put(storeCustomerProfile(response));
      yield put(updateIsLoadingCustomerProfile(false));
      if (response.preferredStore) {
        yield put(storePreferredStore(response.preferredStore));
      }
    } catch {
      yield call(routeToErrorPage);
    }
  }
}

export function* routeToErrorPage() {
  yield history.push("/error");
}
