import { ErrorText, InputField } from "ccp-common-ui-components";
import React, { useEffect } from "react";
import { MessageContainer } from "../../styled/MessageContainer";

export interface IAddAddressInput {
  id: string;
  name: string;
  value: string;
  defaultValue: string;
  setValue: (name: any, value: any) => void;
  errorMessage?: string;
  errorTextId: string;
  revalidate: (name: any) => void;
}

export function AddAddressInput(props: IAddAddressInput) {
  // Explicitly exempted from exhaustive-deps rule
  // Fix this when we come through and purge the form validation
  useEffect(() => {
    props.setValue(props.name, props.value);
  }, [props.name, props.setValue]); // eslint-disable-line

  const hasError = !!props.errorMessage;

  return (
    <>
      <InputField
        id={props.id}
        data-testid={props.id}
        name={props.name}
        defaultValue={props.value}
        hasError={hasError}
        onChange={(e) => {
          props.setValue(props.name, e.target.value);
          props.revalidate(props.name);
        }}
        autoComplete="off"
      />
      {hasError && (
        <MessageContainer>
          <ErrorText id={props.errorTextId} data-testid={props.errorTextId}>
            {props.errorMessage}
          </ErrorText>
        </MessageContainer>
      )}
    </>
  );
}
