import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { Route, RouteProps } from "react-router-dom";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";
import { PageLoader } from "../common/PageLoader";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";
import { Redirect } from "react-router-dom";

const stateParameter = "state";

const ProtectedRoute = ({ children, ...props }: RouteProps) => {
  const currentAuthenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );

  if (currentAuthenticationStatus !== AuthenticationStatus.Authenticated) {
    return <PageLoader isShown={true} />;
  }

  const redirectState = getQueryParameter(stateParameter);
  if (redirectState) {
    const decodedState = atob(redirectState).split("|");
    const originRoute = decodedState[0];
    const returnTo = decodedState[1];
    const redirectStateUri = `/${originRoute}${
      returnTo ? `?returnTo=${returnTo}` : ""
    }`;
    return <Redirect to={redirectStateUri} />;
  }

  return <Route {...props}>{children}</Route>;
};

export default ProtectedRoute;
