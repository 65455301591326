import { ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../../common/styles/ScreenSizes";

export const UnsubscribeContainer = styled.div`
  background-color: white;
  width: 100%;
`;

export const UnsubscribeContent = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin: 0 40px;
`;

export const UnsubscribeTitleHeader = styled.h1`
  color: #e01a22;
`;

export const UnsubscribeButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2em;

  & button:first-child {
    margin-right: 1em;
  }

  @media ${screenSize.mobile} {
    flex-direction: column;

    & button:first-child {
      margin-right: 0;
      margin-bottom: 16px;
    }
  }
`;

export const UnsubscribeNoButton = styled(ThemedButton)`
  background-color: white;
  border-color: black;
  color: black;
`;

export const UnsubscribeStandardIcon = styled.img`
  width: 42px;
  height: 42px;
`;

export const UnsubscribeSingularButton = styled.div`
  padding-top: 2em;
`;

export const UnsubscribeText = styled.p`
  text-align: center;
`;

export const UnsubscribeHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 0px 48px 0px;

  @media ${screenSize.tablet} {
    margin: 1px 0px 54px 0px;
  }

  @media ${screenSize.mobile} {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: -24px;
    width: 100vw;
    background-color: white;
    box-shadow: 0px 2px 5px 0px rgb(182 182 182);
  }
`;

export const UnsubscribeHeaderLogo = styled.img`
  margin: 20px 0;
`;
