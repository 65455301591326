import colesLogo from "../assets/logos/coles-logo.svg";
import styled from "styled-components";
import { screenSize } from "./styles/ScreenSizes";
import { BackButtonContainer } from "./BackButtonContainer";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 24px 0px 48px 0px;

  @media ${screenSize.tablet} {
    margin: 1px 0px 54px 0px;
  }

  @media ${screenSize.mobile} {
    position: sticky;
    top: 0;
    z-index: 1;
    margin-top: -24px;
    width: 100vw;
    background-color: white;
    box-shadow: 0px 2px 5px 0px rgb(182 182 182);
  }
`;

const Logo = styled.img`
  margin: 20px 0;
`;

function AppHeader({ backButtonUrl }: AppHeaderProps) {
  return (
    <Header>
      <Logo src={colesLogo} alt="Coles logo" width="123" height="23" />
      <BackButtonContainer returnTo={backButtonUrl} />
    </Header>
  );
}

interface AppHeaderProps {
  backButtonUrl?: string | null;
}

export default AppHeader;
