import { ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";

export const NonThemedButton = styled(ThemedButton)`
  background-color: transparent;
  color: ${headers.black900};
  border: 1px solid ${headers.black900};

  &:hover,
  &:focus {
    &::after {
      border-color: black;
    }
  }
  &:active {
    background-color: ${headers.lightGrey};
    border-color: ${headers.black900};
  }
`;
