import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Link as VerifyLaterLink, Banner } from "ccp-common-ui-components";

import { verifyLater } from "../../store/customer-profile/CustomerProfileSlice";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";
import { RootState } from "../../store/Store";

const BannerContainerDiv = styled.div`
  padding-bottom: 18px;
`;

interface VerifyLaterBannerProps {
  backButtonUrl: string | undefined;
}

export function VerifyLaterBanner(prop: VerifyLaterBannerProps) {
  const dispatch = useDispatch();

  const isVerifyLaterDone = useSelector(
    (state: RootState) => state.customerProfile.isVerifyLaterDone
  );

  const { handleNavigation } = useReturnUrl(prop.backButtonUrl);

  const setMfaPreferenceLow = (e: React.MouseEvent) => {
    dispatch(
      verifyLater({
        mfaPreference: "low",
      })
    );
  };

  if (isVerifyLaterDone) {
    handleNavigation();
  }

  return (
    <BannerContainerDiv>
      <Banner hasError={true}>
        <div>
          If you don’t verify your mobile number the security features on your
          account will be disabled.&nbsp;
          <VerifyLaterLink
            primary={true}
            data-testid="verify-later-link"
            onClick={setMfaPreferenceLow}
          >
            Verify my number later.
          </VerifyLaterLink>
        </div>
      </Banner>
    </BannerContainerDiv>
  );
}
