export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,100})$/;
// eslint-disable-next-line no-useless-escape

export function validateEmail(email: string): boolean | string {
  if (!email) {
    return true;
  }
  email = email.trim();
  const isValid = email.match(emailRegex);

  return !!isValid || "Enter a valid email e.g. anna@email.com";
}
