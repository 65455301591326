import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import {
  setTeamMemberNumberUnlinkSuccess,
  TeamMemberNumberArgs,
  teamMemberNumberLinkFailed,
  teamMemberNumberLinkSuccess,
  UnlinkActionPayload,
  updateIsUnlinkRequestLoading,
} from "./TeamMemberNumberSlice";
import { history } from "../../common/utils/history";
import {
  linkTeamMemberNumber,
  TeamMemberNumberLinkError,
  TeamMemberNumberLinkRequest,
} from "../../api/teamMember/TeamMemberNumberApiClient";
import { unlinkLoyaltyAccountNumber } from "../../api/loyaltyAccounts/LoyaltyAccountsApiClient";
import { getLoyaltyAccountsAction } from "../loyalty-accounts/LoyaltyAccountsActions";

export function* sendTeamMemberNumberSaga(
  action: PayloadAction<TeamMemberNumberArgs>
) {
  try {
    const request: TeamMemberNumberLinkRequest = {
      memberId: action.payload.memberId,
    };
    yield call(linkTeamMemberNumber, request);
    yield put(teamMemberNumberLinkSuccess());
    yield call(navigateToProfilePageSaga);
  } catch (error) {
    yield put(teamMemberNumberLinkFailed());
    if (error && error instanceof TeamMemberNumberLinkError) {
      let status = error.data?.status;
      if (status && status !== 400) yield call(navigateToProfilePageSaga);
    }
  }
}

export function* unlinkTeamMemberNumberSaga(
  action: PayloadAction<UnlinkActionPayload>
) {
  try {
    yield call(unlinkLoyaltyAccountNumber, action.payload.loyaltyAccountId);
    yield put(updateIsUnlinkRequestLoading(false));
    yield put(setTeamMemberNumberUnlinkSuccess(true));
    yield put(getLoyaltyAccountsAction({}));
  } catch (error) {
    yield put(setTeamMemberNumberUnlinkSuccess(false));
  }
}

export function* navigateToProfilePageSaga() {
  yield history.push("/");
}
