import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import { ThemedButton } from "../styled/ThemedButton";
import { IconPlus } from "../common/IconPlus";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/Store";
import { setFormVisibility } from "../../store/add-address/AddAddressSlice";
const Description = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

const EmptyAddressMessage = styled(Description)`
  margin-bottom: 32px;
`;

const AddAddressButtonContainer = styled(StyledButtonWrapper)`
  padding: 10px;
  text-align: center;
`;

export const AddAddressButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

function EmptyAddresses() {
  const { addresses } = useSelector(
    (state: RootState) => state.customerProfileAddress
  );

  const { showAddAddressForm } = useSelector(
    (state: RootState) => state.addAddress
  );
  const dispatch = useDispatch();
  const doesNotHaveAddresses = addresses.length === 0;
  return (
    <>
      {doesNotHaveAddresses && !showAddAddressForm && (
        <>
          <EmptyAddressMessage>
            There aren't any addresses currently saved on your address book.
          </EmptyAddressMessage>
          <AddAddressButtonContainer>
            <AddAddressButton
              onClick={() => {
                dispatch(setFormVisibility(true));
              }}
            >
              <IconPlus size="24px" margin="0 8px" />
              Add a delivery address
            </AddAddressButton>
          </AddAddressButtonContainer>
        </>
      )}
    </>
  );
}
export default EmptyAddresses;
