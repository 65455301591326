import React from "react";
import styled from "styled-components";

const Message = styled.span`
  color: #e01a22;
  font-size: 14px;
  padding-top: 10px;
`;

export interface ValidationFailureMessageProps {
  text: string;
}

function ValidationFailureMessage({
  text,
  ...props
}: ValidationFailureMessageProps) {
  return <Message {...props}>{text}</Message>;
}

export default ValidationFailureMessage;
