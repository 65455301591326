import { Banner } from "ccp-common-ui-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { BannerContainer } from "../../styled/BannerContainer";

export function MfaPreferenceUpdateFailedBanner() {
  const mfaPreferenceErrorMessage = useSelector(
    (state: RootState) => state.customerProfile.mfaPreferenceErrorMessage
  );

  return (
    <>
      {mfaPreferenceErrorMessage && (
        <BannerContainer data-testid="mfaPreferenceUpdateErrorBanner">
          <Banner hasError={true}>{mfaPreferenceErrorMessage}</Banner>
        </BannerContainer>
      )}
    </>
  );
}
