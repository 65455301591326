import { InputBase, Popper } from "@mui/material";
import styled from "styled-components";
import { SettingName } from "../../components/styled/SettingName";

export const AutocompleteDropDownMessage = styled.div`
  display: flex;
  flex-direction: row;
  vertical-align: center;
  color: #000000;

  img {
    margin-right: 4px;
  }
`;

export const AutocompleteSettingName = styled(SettingName)`
  margin-bottom: 8px;
  display: block;
`;

export const StyledInput = styled(InputBase)`
  border: ${(props) =>
    props.error ? "1px solid transparent" : "1px solid black"};
  box-sizing: border-box;
  max-width: 100%;
  width: 100%;
  outline: none;
  height: 36px;
  line-height: 22px;
  font-size: 16px;
  padding-left: 6px;
  -webkit-appearance: none;
  border-color: ${(props) => (props.error ? "#e01a22" : "transparent")};
  box-shadow: ${(props) =>
    props.error ? "0 0 0 1px #e01a22" : "0 0 0 1px #898d8e"};
  transition: box-shadow 0.2s;
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0 0 0 3px ${(props) => (props.error ? "#e01a22" : "#20211f")};
    border: 1px solid transparent;
    outline: none;
  }
`;

export const StyledPopper = styled(Popper)`
  padding-left: 1px;
  padding-right: 1px;
  border: 1px solid #898d8e;
  background-color: #ffffff;

  & .MuiAutocomplete-paper {
    box-shadow: none;
  }
`;
