import styled from "styled-components";
import {
  DateInput,
  DateOfBirthInputProps,
} from "../../../common/form/DateInput";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { ErrorText } from "ccp-common-ui-components";
import { UseFormRegisterReturn } from "react-hook-form";
import { ScreenReaderText } from "../../styled/ScreenReaderText";
import { forwardRef, Ref } from "react";

type EditableDateOfBirthProps = DateOfBirthInputProps & {
  onAccept?: (value: string, mask: any) => void;
  errorMessage?: string;
} & UseFormRegisterReturn;

const EditableDateOfBirthContainer = styled.div`
  flex: 1 1 auto;
  order: 2;
  @media ${screenSize.mobile} {
    order: 3;
  }
`;

const EditableDateInput = styled(DateInput)`
  max-width: 80%;
  @media ${screenSize.mobile} {
    max-width: 100%;
  }
`;

export const EditableDateOfBirth = forwardRef(
  (
    { errorMessage, ...props }: EditableDateOfBirthProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorMessage;
    const errorTextId = "date-input-error";
    const helpTextId = "help-text";
    return (
      <EditableDateOfBirthContainer data-testid="editable-date-of-birth">
        <EditableDateInput
          id="date-input"
          data-testid="editable-date-input"
          hasError={hasError}
          aria-invalid={hasError}
          aria-describedby={`${errorTextId} ${helpTextId}`}
          {...props}
          ref={ref}
        />
        <ScreenReaderText id={helpTextId}>
          Forward slash is automatically added to the field as you type.
        </ScreenReaderText>
        {hasError && (
          <ErrorText id={errorTextId} data-testid="error-text">
            {errorMessage}
          </ErrorText>
        )}
      </EditableDateOfBirthContainer>
    );
  }
);
