import styled from "styled-components";

export const ThemedButton = styled.button`
  background-color: #e01a22;
  color: #ffffff;
  font-family: "Source Sans", sans-serif;
  font-size: 16px;
  display: inline-block;
  height: 48px;
  line-height: 46px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border: 1px solid #e01a22;
  padding: 0 23px;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  min-width: 48px;
  overflow: visible;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  &::after {
    position: absolute;
    content: "";
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    border: 2px solid transparent;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    transition: border-color 0.2s;
  }

  &:hover,
  &:focus {
    &::after {
      border-color: black;
    }
  }
  &:active {
    background-color: #a30006;
    border-color: #a30006;
  }
`;
