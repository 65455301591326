import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const SettingLink = styled.div`
  flex: 0 0 auto;
  order: 3;
  display: flex;
  align-self: center;

  color: #e01a22;
  font-family: "Source Sans";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
  a:link,
  a:visited,
  a:active {
    color: #e01a22;
  }

  @media ${screenSize.mobile} {
    order: 2;
  }
`;
