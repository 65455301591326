import styled from "styled-components";

import { screenSize } from "../../../common/styles/ScreenSizes";
import { headers } from "../../../common/styles/RocketColours";

export const AddAddressContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0 2px 5px 0 rgb(182 182 182 / 50%);
  margin-bottom: 12px;
  width: 100%;
`;

export const AddAddressFormContainer = styled.form`
  width: 100%;
  @media ${screenSize.mobile} {
    margin-left: 0px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;

export const AddressFormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const AddressFormColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media ${screenSize.mobile} {
    flex-direction: column;
  }
`;

export const AddressFormColumnContent = styled.div`
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 12px;
`;

export const AddressFormHeader = styled.h3`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  margin: 12px 0 24px 0;
  padding-left: 18px;

  @media ${screenSize.mobile} {
    line-height: 32px;
  }
`;
