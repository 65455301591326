import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";

import {
  EditAddressRequest,
  DeleteAddressRequest,
  SaveAddressRequest,
  saveAddress,
  editAddress,
  deleteAddress,
  SaveCustomerProfileAddressResponse,
} from "../../api/address/AddressesApi";
import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import { addAddress } from "../customer-profile-address/CustomerProfileAddressSlice";
import { RootState } from "../Store";
import { getCustomerProfileAddressesAsync } from "../customer-profile-address/CustomerProfileAddressSaga";
import {
  AddressSearchActionPayload,
  saveAddressFailed,
  saveAddressSuccess,
  setAddressToBeEdited,
  setDeleteAddressModalVisibility,
  setMatchingAddresses,
  setSearchAddressFailed,
  setFormVisibility,
  setAddressesLimitReached,
} from "./AddAddressSlice";
import {
  queryAddressSearch,
  QueryAddressSearchResponse,
} from "../../api/address/SearchAddressApi";
import { ErrorResponse } from "../../common/error/ErrorResponse";

export function* saveAddressSaga(action: PayloadAction<SaveAddressRequest>) {
  try {
    const saveAddressRequest = action.payload;
    const response: SaveCustomerProfileAddressResponse | ErrorResponse =
      yield call(saveAddress, saveAddressRequest);

    if ("errors" in response) {
      yield put(setAddressesLimitReached(true));
    } else {
      const successMessage = `New address ${response.nickname} added successfully`;
      yield put(saveAddressSuccess(successMessage));
      yield put(addAddress(response));
    }
  } catch {
    const failMessage = `Adding address '${action.payload.Nickname}' failed, please try again`;
    yield put(saveAddressFailed(failMessage));
  }
}

export function* editAddressSaga(action: PayloadAction<EditAddressRequest>) {
  try {
    const editAddressRequest = action.payload;

    const response: SaveCustomerProfileAddressResponse = yield call(
      editAddress,
      editAddressRequest
    );

    const successMessage = `Address ${response.nickname} updated successfully`;
    yield put(saveAddressSuccess(successMessage));
    yield call(getCustomerProfileAddressesAsync);
    yield put(setAddressToBeEdited(null));
  } catch {
    const failMessage = `Updating address '${action.payload.Nickname}' failed, please try again`;
    yield put(saveAddressFailed(failMessage));
    yield put(setAddressToBeEdited(null));
  }
}

export function* deleteAddressSaga(
  action: PayloadAction<DeleteAddressRequest>
) {
  const deleteAddressRequest = action.payload;
  try {
    yield call(deleteAddress, deleteAddressRequest);
    const successMessage = `Address ${deleteAddressRequest.Nickname} deleted`;
    yield put(saveAddressSuccess(successMessage));
    yield put(setDeleteAddressModalVisibility(false));
    yield put(setFormVisibility(false));
    yield put(setAddressToBeEdited(null));
    yield call(getCustomerProfileAddressesAsync);
  } catch {
    const failMessage = `Deleting address '${deleteAddressRequest.Nickname}' failed, please try again`;
    yield put(saveAddressFailed(failMessage));
    yield put(setFormVisibility(false));
    yield put(setAddressToBeEdited(null));
  }
}

export function* getMatchingAddressSuggestionsAsync(
  action: PayloadAction<AddressSearchActionPayload>
) {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      const addressSearchText = action.payload.addressText;
      const response: QueryAddressSearchResponse = yield call(
        queryAddressSearch,
        addressSearchText
      );
      yield put(setMatchingAddresses(response.suggestions));
    } catch {
      yield put(setSearchAddressFailed(true));
    }
  }
}
