import React, { useEffect, useRef, useState } from "react";
import { SettingButton } from "../../styled/SettingButton";
import { RootState } from "../../../store/Store";
import { useDispatch, useSelector } from "react-redux";
import { DateOfBirthDisplay } from "./DateOfBirthDisplay";
import { EditableDateOfBirth } from "./EditableDateOfBirth";
import { useForm } from "react-hook-form";
import {
  cancelDateOfBirthUpdate,
  updateDateOfBirth,
} from "../../../store/customer-profile/CustomerProfileSlice";
import { UpdatePendingSpinner } from "../UpdatePendingSpinner";
import { validateDateOfBirth } from "./validateDateOfBirth";
import { dateOfBirthPlaceholder } from "../../../common/constants/Placeholders";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { SpinnerContainer } from "../../styled/SpinnerContainer";

type FormData = {
  dateOfBirth: string;
};

function isDateOfBirthEmpty(value: string) {
  return !value || value === dateOfBirthPlaceholder;
}

export function DateOfBirthSetting() {
  const dateOfBirthInputName = "dateOfBirth";
  const [isEditing, setIsEditing] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>(null);
  const { isUpdatingDateOfBirth } = useSelector(
    (state: RootState) => state.customerProfile
  );
  const { dateOfBirth: originalDateOfBirth } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );
  const dateOfBirthRef = useRef(originalDateOfBirth);
  const hasPreExistingDateOfBirth = !!originalDateOfBirth;

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isSubmitted, isSubmitSuccessful },
  } = useForm<FormData>();

  const dispatch = useDispatch();

  const onEditButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    clearErrors();
    setIsEditing(true);
    setIsEditClicked(true);
  };

  const onSubmit = (data: FormData) => {
    const newDateOfBirth = data.dateOfBirth;
    const dateOfBirthNotUpdated = newDateOfBirth === originalDateOfBirth;
    if (
      dateOfBirthNotUpdated ||
      (!hasPreExistingDateOfBirth && isDateOfBirthEmpty(newDateOfBirth))
    ) {
      dispatch(cancelDateOfBirthUpdate());
    } else {
      dispatch(updateDateOfBirth(data.dateOfBirth));
    }
    reset();
    setIsEditing(false);
  };

  const buttonText = () => {
    if (isEditing) {
      return "Save date of birth";
    } else if (originalDateOfBirth) {
      return "Edit date of birth";
    } else {
      return "Add date of birth";
    }
  };

  // Focus edit button after going back to read mode
  useEffect(() => {
    if (
      isEditClicked &&
      !isEditing &&
      editButtonRef.current &&
      !isUpdatingDateOfBirth
    ) {
      editButtonRef.current.focus();
    }
  }, [isEditClicked, isEditing, isUpdatingDateOfBirth]);

  // Set default date of birth value
  useEffect(() => {
    dateOfBirthRef.current = originalDateOfBirth;
    setValue(dateOfBirthInputName, originalDateOfBirth);
  }, [originalDateOfBirth, setValue, isSubmitSuccessful]);

  // Reset the form after successful submission and re-editing the form
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  const editMode = (
    <>
      <EditableDateOfBirth
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        id="date-of-birth"
        onAccept={(value: any) => {
          const isDateOfBirthInputEmpty = isDateOfBirthEmpty(value);
          const shouldValidate =
            isSubmitted &&
            (hasPreExistingDateOfBirth || !isDateOfBirthInputEmpty);

          setValue(dateOfBirthInputName, value, {
            shouldDirty: true,
            shouldValidate,
          });
          dateOfBirthRef.current = value;

          if (!shouldValidate) {
            clearErrors();
          }
        }}
        value={dateOfBirthRef.current}
        errorMessage={errors.dateOfBirth?.message}
        required={hasPreExistingDateOfBirth}
        {...register(dateOfBirthInputName, {
          validate: {
            validDate: (value) =>
              validateDateOfBirth(value, originalDateOfBirth),
          },
        })}
      />

      <SpinnerContainer>
        <SettingButton type="submit" data-testid="save-button">
          {buttonText()}
        </SettingButton>
      </SpinnerContainer>
    </>
  );

  const readMode = (
    <>
      <DateOfBirthDisplay />
      <SpinnerContainer>
        {isUpdatingDateOfBirth ? (
          <UpdatePendingSpinner />
        ) : (
          <SettingButton
            data-testid="edit-button"
            ref={editButtonRef}
            type="button"
            withIcon={hasPreExistingDateOfBirth}
            onClick={(e) => onEditButtonClick(e)}
          >
            {buttonText()}
          </SettingButton>
        )}
      </SpinnerContainer>
    </>
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="date-of-birth-form"
      noValidate
    >
      <SettingLine>
        <SettingName htmlFor="date-of-birth">Date of birth:</SettingName>
        {isEditing ? editMode : readMode}
      </SettingLine>
    </form>
  );
}
