import { invalidDateOfBirthErrorMessage } from "../../../common/constants/ErrorMessages";
import { dateOfBirthPlaceholder } from "../../../common/constants/Placeholders";

export function validateDateOfBirth(
  newDateOfBirth: string,
  originalDateOfBirth?: string
): boolean | string {
  try {
    if (
      !originalDateOfBirth &&
      (newDateOfBirth === dateOfBirthPlaceholder || !newDateOfBirth)
    ) {
      return true;
    }
    const splitDate = newDateOfBirth.split("/");
    const year = splitDate[2];
    const month = splitDate[1];
    const day = splitDate[0];
    const dateString = `${year}-${month}-${day}`;
    const date = new Date(dateString);

    const today = new Date();
    const currentDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    if (isNaN(date.getTime())) {
      return invalidDateOfBirthErrorMessage;
    }

    const alphabetical = /[a-zA-Z]+/;
    const dateHasAlphabeticalCharacters = newDateOfBirth.match(alphabetical);
    if (dateHasAlphabeticalCharacters) {
      return invalidDateOfBirthErrorMessage;
    }

    if (date >= currentDate) {
      return invalidDateOfBirthErrorMessage;
    }

    const maximumDate = currentDate;
    maximumDate.setFullYear(currentDate.getFullYear() - 120);
    if (date < maximumDate) {
      return invalidDateOfBirthErrorMessage;
    }

    return true;
  } catch {
    return invalidDateOfBirthErrorMessage;
  }
}
