import React from "react";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { RootState } from "../../../store/Store";

import { SettingLink } from "../../styled/SettingLink";
import editIcon from "../../../assets/icons/edit.svg";
import styled from "styled-components";
import { PostcodeDisplay } from "./PostcodeDisplay";
import { ContainerRow } from "../../../common/styles/Containers";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

export function PostcodeSetting() {
  const { locality: originalPostcode } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const buttonText = originalPostcode ? "Edit postcode" : "Add postcode";

  return (
    <ContainerRow>
      <SettingLine>
        <SettingName>Postcode/suburb:</SettingName>
        <PostcodeDisplay />
        <SettingLink>
          {originalPostcode && (
            <EditIcon
              data-testid="edit-icon"
              src={editIcon}
              alt=""
              aria-hidden
            />
          )}
          <Link data-testid="add-edit-postcode-link" to="/add-edit-postcode">
            {buttonText}
          </Link>
        </SettingLink>
      </SettingLine>
    </ContainerRow>
  );
}
