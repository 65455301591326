import styled from "styled-components";

import plusCircleIcon from "../../assets/icons/plus-circle.svg";

const CircleCloseImage = styled.img`
  height: 20px;
  width: 20px;
  transform: rotate(45deg);
`;

export function CircleCloseAdornment() {
  return <CircleCloseImage src={plusCircleIcon} alt="clear" />;
}
