import { verifyLater } from "../../common/constants/InfoMessages";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ResultPage } from "../common/ResultPage";
import { useReturnUrl } from "../../common/hooks/useReturnUrl";

interface VerificationErrorPageProps {
  backButtonUrl: string | undefined;
}

export function VerificationErrorPage(props: VerificationErrorPageProps) {
  const { handleNavigation } = useReturnUrl(props.backButtonUrl);

  const { verificationErrorPageMessage: sendCodeErrorMessage } = useSelector(
    (state: RootState) => state.verifyMobileNumber
  );

  const returnToGroupProfile = () => {
    handleNavigation();
  };

  return (
    <ResultPage
      hasError={true}
      header="Cannot verify"
      description={sendCodeErrorMessage!}
      buttonText={verifyLater!}
      action={returnToGroupProfile}
    ></ResultPage>
  );
}
