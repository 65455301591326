const dataLayerDeleteImpression = (returnTo: string) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Delete Account Deep Link",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "customer form",
          view_type_details: returnTo,
        },
      },
      form: {
        name: "delete account",
        stage: "start",
      },
    },
  });
};

const dataLayerDeleteSuccessImpression = (returnTo: string) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Delete Account Success from Deep Link",
      },
      customer: {
        auth_state: "authenticated",
      },
      view: {
        context: {
          view_type: "customer form",
          view_type_details: returnTo,
        },
      },
      form: {
        name: "delete account",
        stage: "complete",
      },
    },
  });
};

export { dataLayerDeleteImpression, dataLayerDeleteSuccessImpression };
