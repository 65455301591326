import { HideableDiv } from "ccp-common-ui-components";
import { useSelector } from "react-redux";
import { PageLoader } from "../../common/PageLoader";
import { RootState } from "../../../store/Store";
import { DeleteProfileFailedPage } from "./DeleteProfileFailedPage";
import { DeleteProfileSuccessPage } from "./DeleteProfileSuccessPage";

export function DeleteProfileResultPage() {
  const isSendingProfileDeleteRequest = useSelector(
    (state: RootState) => state.customerProfile.isSendingProfileDeleteRequest
  );

  const isSendingProfileDeleteRequestSuccess = useSelector(
    (state: RootState) => state.customerProfile.isProfileDeleteRequestSuccess
  );

  return (
    <>
      <PageLoader isShown={isSendingProfileDeleteRequest} />
      <HideableDiv
        isHidden={isSendingProfileDeleteRequest}
        data-testid="hideable"
      >
        {isSendingProfileDeleteRequestSuccess ? (
          <DeleteProfileSuccessPage />
        ) : (
          <DeleteProfileFailedPage />
        )}
      </HideableDiv>
    </>
  );
}
