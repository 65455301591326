import { Banner } from "ccp-common-ui-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { BannerContainer } from "../styled/BannerContainer";
import {
  teamMemberNumberLinkSuccessMessage,
  teamMemberNumberLinkErrorMessage,
} from "../../common/constants/InfoMessages";
import { useEffect } from "react";
import { resetTeamMemberNumberSuccessStatus } from "../../store/team-member-number/TeamMemberNumberSlice";

export function TeamMemberNumberBanner() {
  const teamMemberNumber = useSelector(
    (state: RootState) => state.teamMemberNumber
  );

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetTeamMemberNumberSuccessStatus());
    };
  }, [dispatch]);

  const message =
    teamMemberNumber && teamMemberNumber.isTeamMemberNumberLinkSuccess
      ? teamMemberNumberLinkSuccessMessage
      : teamMemberNumberLinkErrorMessage;

  return (
    <>
      {teamMemberNumber &&
        teamMemberNumber.isTeamMemberNumberLinkSuccess !== undefined && (
          <BannerContainer>
            <Banner hasError={!teamMemberNumber.isTeamMemberNumberLinkSuccess}>
              {message}
            </Banner>
          </BannerContainer>
        )}
    </>
  );
}
