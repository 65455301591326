import React, { useState } from "react";
import openEye from "../../assets/icons/visible.svg";
import closedEye from "../../assets/icons/hidden.svg";
import styled from "styled-components";
import { InputField, InputFieldProps } from "../../common/form/InputField";

export interface NewThemedPasswordInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const PasswordThemedInput = styled(InputField)`
  padding-right: ${(props) => (props.hasError ? "94px" : "68px")};
  background-position: top 8px right 80px;
  ::-webkit-credentials-auto-fill-button {
    margin-right: ${(props) => (props.hasError ? "8px" : "0")};
  }
`;

const ShowPasswordButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  margin-left: -78px;
  margin-top: 8px;
  outline-offset: -4px;
  position: absolute;
  &:before {
    position: absolute;
    right: 40px;
    top: 12px;
  }
`;

const ShowPasswordContent = styled.div`
  display: flex;
  color: #4d4d4d;
  font-weight: bold;
  text-decoration: underline;
  width: 58px;
`;

const ShowPasswordImage = styled.img`
  margin-right: 6px;
`;

const NewThemedPasswordInput = React.forwardRef(
  (
    props: NewThemedPasswordInputProps & InputFieldProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const showHidePasswordIcon = isPasswordVisible ? openEye : closedEye;
    const showHidePasswordAccessibilityText = isPasswordVisible
      ? "Hide password"
      : "Show password";
    const showHidePasswordText = isPasswordVisible ? "Hide" : "Show";

    return (
      <div>
        <PasswordThemedInput
          {...props}
          type={isPasswordVisible ? "text" : "password"}
          ref={ref}
          hasError={props.hasError ?? false}
          displaySuccessIcon={props.displaySuccessIcon}
        />
        <ShowPasswordButton
          className="show-password-button"
          type="button"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        >
          <ShowPasswordContent>
            <ShowPasswordImage
              src={showHidePasswordIcon}
              alt=""
              role="presentation"
              aria-hidden
            />
            <span aria-label={showHidePasswordAccessibilityText}>
              {showHidePasswordText}
            </span>
          </ShowPasswordContent>
        </ShowPasswordButton>
      </div>
    );
  }
);

export default NewThemedPasswordInput;
