import React, { forwardRef, Ref } from "react";
import styled from "styled-components";
import { InputFieldProps } from "./InputField";
import { IMaskMixin } from "react-imask";
import IMask from "imask";
import { DateInputBase } from "./DateInputBase";

interface DobProps {
  delimiter?: string;
  onAccept?: (value: string, mask: any) => void;
}
export type DateOfBirthInputProps = InputFieldProps &
  React.InputHTMLAttributes<HTMLInputElement> &
  DobProps;

const DateOfBirthWrapper = styled.div``;

const MaskedStyledInput = IMaskMixin((props) => {
  return <DateInputBase {...props} ref={props.inputRef} />;
});

const blocks = {
  d: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 31,
    placeholderChar: "D",
    maxLength: 2,
  },
  m: {
    mask: IMask.MaskedRange,
    from: 1,
    to: 12,
    placeholderChar: "M",
    maxLength: 2,
  },
  Y: {
    mask: IMask.MaskedRange,
    from: 1000,
    to: 9999,
    placeholderChar: "Y",
    maxLength: 4,
  },
};

export const DateInput = forwardRef(
  (props: DateOfBirthInputProps, ref: Ref<HTMLInputElement>) => {
    let delimiter = "/";

    if (props.delimiter) {
      delimiter = props.delimiter;
    }

    return (
      <DateOfBirthWrapper style={props.style}>
        <MaskedStyledInput
          {...props}
          type="tel"
          value={props.value}
          mask={Date}
          blocks={blocks}
          pattern={"d{" + delimiter + "}`m{" + delimiter + "}`Y"}
          lazy={false}
          overwrite={true}
          autofix={true}
          format={function (date: Date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            let displayDay = day.toString();
            let displayMonth = month.toString();

            if (day < 10) displayDay = `0${day}`;
            if (month < 10) displayMonth = `0${month}`;

            const result = [displayDay, displayMonth, year].join(delimiter);
            return result;
          }}
          parse={function (str: string) {
            const yearMonthDay = str
              .split(delimiter)
              .map((z: string) => Number(z));
            const result = new Date(
              yearMonthDay[2],
              yearMonthDay[1] - 1,
              yearMonthDay[0]
            );
            return result;
          }}
          placeholder="DD/MM/YYYY"
          inputRef={ref}
        />
      </DateOfBirthWrapper>
    );
  }
);
