import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FeaturesResponseItem {
  feature: string;
  isEnabled: boolean;
}

export interface FeaturesResponse {
  items: Array<FeaturesResponseItem> | null | undefined;
}

export const initialState: FeaturesResponse = {
  items: [
    {
      feature: "UseBcpIdentityProvider",
      isEnabled: false,
    },
  ],
};

const featuresSlice = createSlice({
  name: "features",
  initialState,
  reducers: {
    featureFlagsRetrieved: (
      state,
      { payload }: PayloadAction<Array<FeaturesResponseItem>>
    ) => {
      state.items = payload;
    },
  },
});

export const { featureFlagsRetrieved } = featuresSlice.actions;

export const featuresReducer = featuresSlice.reducer;
