import { useEffect, useState } from "react";
import { SubPageHeader } from "../styled/SubPageHeader";
import { Layout } from "../styled/Layout";
import AppFooter from "../../common/AppFooter";
import {
  storeCustomerProfileAddresses,
  storeCustomerProfileAddressLinks,
  updateIsLoadingAddress,
} from "../../store/customer-profile-address/CustomerProfileAddressSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { getCustomerProfileAddressesAction } from "../../store/customer-profile-address/CustomerProfileAddressActions";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";
import AddressList from "./AddressList";
import { PageLoader } from "../common/PageLoader";
import { HideableDiv, Link, ThemedButton } from "ccp-common-ui-components";
import { AddAddressButtonWhenHasAddresses } from "./add-address-form/AddAddressButtonWhenHasAddresses";
import EmptyAddresses from "./EmptyAddresses";
import { AddAddressBanner } from "./add-address-form/AddAddressBanner";
import {
  resetState,
  setAddressToBeEdited,
} from "../../store/add-address/AddAddressSlice";
import {
  AddressContainer,
  AddressHeader,
  ButtonContainer,
  CenteredButtonContainer,
  Description,
  SavedAddressesContainer,
} from "./AddressBookPageStyles";
import { queryCustomerProfileAddressesWithOffsetAndLimit } from "../../api/address/AddressesApi";

const AddressBookPage = () => {
  const dispatch = useDispatch();
  const customerProfileAddresses = useSelector(
    (state: RootState) => state.customerProfileAddress
  );

  const profileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );

  const isAddressSaved = useSelector(
    (state: RootState) => state.addAddress.isAddressSaved
  );

  const { saveAddressFailed, isAddingAddress } = useSelector(
    (state: RootState) => state.addAddress
  );

  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const { addresses, links, isLoadingAddresses } = customerProfileAddresses;

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    const options: ScrollToOptions = {
      top: 0,
      left: 0,
    };
    window.scrollTo(options);
  }, []);

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.Authenticated) {
      dispatch(updateIsLoadingAddress(true));
      dispatch(getCustomerProfileAddressesAction());
      dispatch(setAddressToBeEdited(null));
    }
  }, [dispatch, authenticationStatus]);

  const returnToTop = () => {
    window.scrollTo(0, 0);
  };

  const loadMore = async () => {
    setIsLoadingMore(true);
    var loadedAddresses = await queryCustomerProfileAddressesWithOffsetAndLimit(
      links
    );
    var appendedAddresses = addresses.concat(loadedAddresses.data);

    dispatch(storeCustomerProfileAddresses(appendedAddresses));
    dispatch(storeCustomerProfileAddressLinks(loadedAddresses.links?.next));
    setIsLoadingMore(false);
  };

  return (
    <Layout>
      <SubPageHeader>My address book</SubPageHeader>
      <Description>{`Here you can manage your delivery addresses. You can save a maximum of ${
        profileType === "business" ? "200" : "20"
      } addresses.`}</Description>

      <AddressContainer>
        <PageLoader isShown={isLoadingAddresses || isAddingAddress} />
        <HideableDiv isHidden={isLoadingAddresses || isAddingAddress}>
          <AddAddressBanner isShown={isAddressSaved || saveAddressFailed} />
          <SavedAddressesContainer>
            <AddressHeader>My saved addresses</AddressHeader>
            <AddAddressButtonWhenHasAddresses />
          </SavedAddressesContainer>
          <AddressList addresses={addresses} />
          <EmptyAddresses />
          <ButtonContainer>
            <Link onClick={() => returnToTop()}>Return to top</Link>
            <Link href="/">Cancel</Link>
          </ButtonContainer>
          {links && !isLoadingMore && (
            <CenteredButtonContainer>
              <ThemedButton
                data-testid="load-more-button"
                onClick={() => loadMore()}
              >
                Load more
              </ThemedButton>
            </CenteredButtonContainer>
          )}
          {isLoadingMore && (
            <CenteredButtonContainer>Loading ...</CenteredButtonContainer>
          )}
        </HideableDiv>
      </AddressContainer>

      <AppFooter />
    </Layout>
  );
};

export default AddressBookPage;
