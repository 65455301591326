import styled from "styled-components";
import { Settings } from "../profile/ProfileSettings";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";
import { ThemedButton } from "./ThemedButton";

interface CenteredHeaderProps {
  hasError?: boolean;
}

export const CenteredHeader = styled.h1<CenteredHeaderProps>`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  padding-top: 10px;
  text-align: center;
  color: ${(props) => (props.hasError ? headers.error : headers.success)};
  font-family: "Avant Garde";
`;

export const CenteredParagraph = styled.p`
  color: ${headers.black900};
  opacity: 0.85;
  font-family: "Source Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;

export const InfoContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 50px 25px;
  width: 514px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const InfoPageThemedButton = styled(ThemedButton)`
  min-width: 50%;
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;
