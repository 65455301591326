import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const SettingName = styled.label`
  flex: 0 0 14em;
  order: 1;
  color: #000000;
  font-family: "Source Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;

  @media ${screenSize.mobile} {
    flex: 1 1 auto;
    line-height: 35px;
  }
`;
