import styled from "styled-components";
import { Link } from "ccp-common-ui-components";
import { Settings } from "../profile/ProfileSettings";

export const ChangePasswordContainer = styled(Settings)`
  margin-bottom: 50px;
  padding: 18px 25px;
  position: relative;
`;

export const RequiredFieldsText = styled.p`
  font-size: 16px;
  font-family: "Source Sans", "sans-serif";
  line-height: 19px;
  text-align: left;
`;

interface ThemedInputLabelProps {
  hasError?: boolean;
}

export const ThemedInputLabel = styled.label<ThemedInputLabelProps>`
  display: block;
  color: ${(props) => (props.hasError ? "#e01a22" : "black")};
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
`;

export const ThemedErrorText = styled.div`
  color: #e01a22;
  font-size: 14px;
  padding: 10px 0 0 0;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;
