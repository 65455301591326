export const mobileNumberSuccessfulVerifiedMessage =
  "Your mobile number has been verified successfully. Thank you for helping keep your account secure";
export const backToMyProfile = "Back to my profile";
export const continueMessage = "Continue";
export const verifyLater = "Verify later";

export const mfaPreferenceUpdateSuccessMessage =
  "Your security preference has been updated.";

export const mfaPreferenceModalSuccessMessage =
  "Your security preference has been updated successfully.";

export const teamMemberNumberLinkSuccessMessage =
  "mycoles card successfully linked";
export const teamMemberNumberLinkErrorMessage =
  "Linking mycoles card failed, please try again";
export const invalidTeamMemberNumberErrorMessage =
  "Please enter a valid mycoles card number";
