import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FlybuysState {
  isUnlinkModelOpen: boolean;
  isFlybuysUnlinkSuccess?: boolean;
  isUnlinkRequestLoading: boolean;
  isFlybuysLinkSuccess?: boolean;
  isFlybuysRouteSuccess?: boolean;
  getLinkError?: boolean;
  returnToRoute: string;
}

export const initialState: FlybuysState = {
  isUnlinkModelOpen: false,
  isFlybuysUnlinkSuccess: undefined,
  isUnlinkRequestLoading: false,
  isFlybuysLinkSuccess: undefined,
  isFlybuysRouteSuccess: undefined,
  getLinkError: false,
  returnToRoute: "",
};

export type UnlinkFlybuysActionPayload = {
  loyaltyAccountId: string;
};

export type VerifyFlybuysActionPayload = {
  code: string | null;
  state: string | null;
};

export type GetProfileActionPayload = {
  returnTo: string;
  action: string;
};

export type GetFlybuysRoutePayload = {
  state: string;
};

const flybuysSlice = createSlice({
  name: "flybuys",
  initialState,
  reducers: {
    setIsFlybuysUnlinkModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isUnlinkModelOpen = action.payload;
    },
    unlinkFlybuysAction: (state, action) => {
      state.isUnlinkRequestLoading = true;
      state.isUnlinkModelOpen = false;
      state.isFlybuysUnlinkSuccess = undefined;
      state.isFlybuysLinkSuccess = undefined;
    },
    updateIsFlybuysUnlinkRequestLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUnlinkRequestLoading = action.payload;
      state.isFlybuysUnlinkSuccess = undefined;
    },
    setFlybuysUnlinkSuccess: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isFlybuysUnlinkSuccess = action.payload;
      state.isUnlinkRequestLoading = false;
    },
    verifyFlybuysAccount: (
      state,
      action: PayloadAction<VerifyFlybuysActionPayload>
    ) => {
      state.isFlybuysLinkSuccess = undefined;
    },
    setIsFlybuysLinkSuccess: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isFlybuysLinkSuccess = action.payload;
    },
    setGetLinkError: (state, action: PayloadAction<boolean | undefined>) => {
      state.getLinkError = action.payload;
    },
    setReturnToRoute: (state, action: PayloadAction<string>) => {
      state.returnToRoute = action.payload;
    },
    setFlybuysRouteSuccess: (
      state,
      action: PayloadAction<boolean | undefined>
    ) => {
      state.isFlybuysRouteSuccess = action.payload;
    },
  },
});

export const {
  setIsFlybuysUnlinkModalOpen,
  unlinkFlybuysAction,
  updateIsFlybuysUnlinkRequestLoading,
  setFlybuysUnlinkSuccess,
  verifyFlybuysAccount,
  setIsFlybuysLinkSuccess,
  setGetLinkError,
  setReturnToRoute,
  setFlybuysRouteSuccess,
} = flybuysSlice.actions;

export const flybuysReducer = flybuysSlice.reducer;
