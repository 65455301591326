import styled from "styled-components";
import { screenSize } from "../../../common/styles/ScreenSizes";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

export const flybuysImg = {
  width: 60,
  height: 20,
};

export const FlybuysImage = styled.img`
  height: ${flybuysImg.height}px;
  width: ${flybuysImg.width}px;
  vertical-align: middle;
`;

export const FlybuysImageContainer = styled.div`
  flex: 0 0 12em;
  order: 1;

  @media ${screenSize.mobile} {
    flex: 1 1 auto;
  }
`;

export const ValueMessage = styled.div`
  order: 2;
  flex: 1 1 auto;
  padding-right: 20px;

  @media ${screenSize.mobile} {
    order: 3;
    flex: 0 0 100%;
  }

  @media ${screenSize.tablet} {
    order: 3;
  }
`;

export const LoadingText = styled.div`
  font-size: 14px;
`;
