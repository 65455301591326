import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const SettingLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: 1px solid #f0f0f0;
  padding: 18px;

  @media ${screenSize.mobile} {
    flex-flow: row wrap;
    height: auto;
    padding: 20px 18px;
  }
`;
