import { forwardRef, Ref } from "react";

import styled from "styled-components";
import { UseFormRegisterReturn } from "react-hook-form";
import { ErrorText } from "ccp-common-ui-components";

import { InputFieldProps } from "../../../common/form/InputField";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { SettingInput } from "../../styled/SettingInput";
import { MessageContainer } from "../../styled/MessageContainer";

export type HomePhoneInputProps = InputFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type EditableHomePhoneProps = HomePhoneInputProps & {
  setHomePhone?: (value: string) => void;
  errorMessage?: string;
} & UseFormRegisterReturn;

const EditableHomePhoneContainer = styled.div`
  flex: 1 1 auto;
  order: 2;
  @media ${screenSize.mobile} {
    order: 3;
  }
`;

export const EditableHomePhone = forwardRef(
  (
    { errorMessage, ...props }: EditableHomePhoneProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const hasError = !!errorMessage;
    const errorTextId = "home-phone-error";
    return (
      <EditableHomePhoneContainer data-testid="editable-home-phone">
        <SettingInput
          id="home-phone"
          data-testid="editable-home-phone-input"
          hasError={hasError}
          displaySuccessIcon={false}
          type="tel"
          aria-invalid={hasError}
          aria-describedby={errorTextId}
          {...props}
          ref={ref}
        />
        {hasError && (
          <MessageContainer>
            <ErrorText id={errorTextId} data-testid="error-text">
              {errorMessage}
            </ErrorText>
          </MessageContainer>
        )}
      </EditableHomePhoneContainer>
    );
  }
);
