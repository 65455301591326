import appConfig from "../../config/Configuration";
import { defaultRequestConfig } from "../ServerApi";
import { loginFetch } from "../LoginFetch";

const customerProfileContactsUrl =
  appConfig.serverUrl + "/api/v1/customer-profile/contacts/email";

export interface CustomerProfileContact {
  id: string;
  name: string;
  value: string;
  notes?: string;
  ranking: number;
}

export interface UpdateCustomerProfilePreferencesResponse {
  statusCode?: number;
  message?: string;
}

export interface EditEmailRequest {
  Email: string;
}
export async function getCustomerProfileContactById(
  contactId: string
): Promise<CustomerProfileContact> {
  const route = `${customerProfileContactsUrl}/${contactId}`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function updateCustomerProfileEmailContact(
  contactId: string,
  request: EditEmailRequest
): Promise<boolean> {
  const route = `${customerProfileContactsUrl}/${contactId}`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return response.ok;
  }
}

export async function createCustomerProfileEmailContact(
  request: EditEmailRequest
): Promise<CustomerProfileContact> {
  const route = `${customerProfileContactsUrl}`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function deleteCustomerProfileEmailContact(
  contactId: string
): Promise<void> {
  const route = `${customerProfileContactsUrl}/${contactId}`;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
