import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { IconCircle } from "../common/IconCircle";
import { useEffect } from "react";
import { dataLayerVerifyEmailSuccessImpression } from "../../common/utils/tracking/verifyEmailPageTracking";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";

export const SuccessH2 = styled.h2`
  font-family: "Avant Garde";
  color: ${headers.success};
`;

export function EmailVerifiedSuccess() {
  const returnTo =
    getQueryParameter("returnTo") ||
    `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    dataLayerVerifyEmailSuccessImpression(returnTo);
  }, [returnTo]);

  return (
    <div data-testid="email-verified-success">
      <IconCircle decorative={true} />
      <SuccessH2>Thank you!</SuccessH2>
      <p>Your email address has now been verified</p>
    </div>
  );
}
