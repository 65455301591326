import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";

export const SubPageHeader = styled.h1`
  color: ${headers.black900};
  font-family: "Avant Garde";
  font-size: 27px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin: 0 0 24px 0;

  @media ${screenSize.mobile} {
    font-size: 27px;
    line-height: 32px;
  }
`;
