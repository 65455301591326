import styled from "styled-components";
import { Settings } from "../profile/ProfileSettings";
import { screenSize } from "../../common/styles/ScreenSizes";

export const Container = styled(Settings)`
  margin-bottom: 50px;
  position: relative;
  padding: 18px 25px;
  width: 67.5vw;
  max-width: 55em;

  @media ${screenSize.mobile} {
    width: 100%;
  }

  @media ${screenSize.tablet} {
    width: 80.5vw;
    min-width: 620px;
  }
`;
