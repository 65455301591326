const dataLayerVerifyEmailSuccessImpression = (returnTo: string) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Verify Email Success Page",
      },
      customer: {
        auth_state: "unauthenticated",
      },
      view: {
        context: {
          view_type: "verify email",
          view_type_details: returnTo,
        },
      },
    },
  });
};

const dataLayerVerifyEmailFailureImpression = (returnTo: string) => {
  (window as any).colData = (window as any).colData || [];
  (window as any).colData.push({
    event: "view_loaded",
    data: {
      page: {
        name: "CCP Verify Email Failure Page",
      },
      customer: {
        auth_state: "unauthenticated",
      },
      view: {
        context: {
          view_type: "verify email",
          view_type_details: returnTo,
        },
      },
    },
  });
};

export {
  dataLayerVerifyEmailSuccessImpression,
  dataLayerVerifyEmailFailureImpression,
};
