import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import { headers } from "../../common/styles/RocketColours";

export const SettingValue = styled.div`
  display: flex;
  flex: 1 1 auto;
  order: 2;
  color: ${headers.black900};
  font-family: "Source Sans";
  font-size: 16px;
  line-height: 22px;

  @media ${screenSize.mobile} {
    flex: 1 0 100%;
    order: 4;
  }
`;
