export const headers = {
  black100: "#e8edf2",
  black200: "#e8e8e8",
  black500: "#898d8e",
  black700: "#6e6e6e",
  black800: "#3f4140",
  black900: "#20211f",
  white100: "rgba(255, 255, 255, 0.05)",
  white400: "rgba(255, 255, 255, 0.1)",
  white500: "rgba(255, 255, 255, 0.5)",
  white700: "rgba(255, 255, 255, 0.65)",
  white800: "rgba(255, 255, 255, 0.85)",
  white900: "white",
  midGrey: "rgb(114,114,114)",
  lightGrey: "rgb(239,239,244)",
  success: "#008a22",
  successDark: "#007a1f",
  error: "#e01a22",
  errorDark: "#c70007",
  primary: "#e01a22",
  primaryDark: "#c70007",
  transparentBlack: "#000000",
};
