import { put, call } from "redux-saga/effects";
import { updateCustomerLastName } from "../../api/profile/CustomerProfileApi";
import {
  asyncOperationFailed,
  asyncOperationFinished,
  asyncOperationStarting,
} from "../async-operation/AsyncOperationSlice";
import { updateCustomerLastNameField } from "../customer-profile/CustomerProfileSlice";
import { UpdateLastNameActionPayload } from "./UpdateLastNameActions";

interface UpdateLastNameAction {
  type: string;
  payload: UpdateLastNameActionPayload;
}
export function* updateLastNameSaga(action: UpdateLastNameAction) {
  try {
    yield put(asyncOperationStarting("updateLastName"));
    const lastName = action.payload.lastName;
    yield call(updateCustomerLastName, lastName);
    yield put(updateCustomerLastNameField(lastName));
    yield put(asyncOperationFinished("updateLastName"));
  } catch {
    yield put(asyncOperationFailed("updateLastName"));
  }
}
