import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import AppFooter from "../../common/AppFooter";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import { SubPageHeader } from "../styled/SubPageHeader";
import { ThemedButton } from "../styled/ThemedButton";
import { screenSize } from "../../common/styles/ScreenSizes";
import { HideableDiv } from "ccp-common-ui-components";

import {
  resetVerifyCodeState,
  sendCodeAction,
  VerificationChannel,
  verifyCodeAction,
} from "../../store/verify-mobile-number/VerifyMobileNumberSlice";

import {
  isOneTimeCodeValid,
  maskValue,
  removeAllSpaces,
} from "../profile/Utils/PhoneUtils";
import { RootState } from "../../store/Store";
import { EditableOneTimeCode } from "./EditableOneTimeCode";
import { LineLabel } from "../styled/LineLabel";
import { Layout } from "../styled/Layout";
import { SettingButton } from "../styled/SettingButton";
import { headers } from "../../common/styles/RocketColours";
import { VerifyLaterBanner } from "./VerifyLaterBanner";
import { InformationContainer } from "../common/InformationContainer";
import { createSelector } from "reselect";
import { PageLoader } from "../common/PageLoader";
import configuration from "../../config/Configuration";
import { useIsPersonalCustomer } from "../profile/Utils/CustomerUtils";

const addEditMyPhoneNumberText = "Add/Edit my phone number";
const sendCodeAgainText = "Send Code Again";
const didntReceiveATextText = "Didn't receive a text?";
const sendCodeInfoMessage =
  "We have sent the maximum number of codes to you. If you need another code please wait 5 minutes to re-send.";

const MaskedLabel = styled.label`
  font-weight: bold;
`;

const VerifyButtonContainer = styled(StyledButtonWrapper)`
  padding: 15px;
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

export const VerifyButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const VerifyLink = styled(Link)`
  font-weight: bold;
  color: ${headers.primary};
`;

const VerifyLaterLink = styled(SettingButton)<{ primary?: string }>`
  font-weight: normal;
  color: ${(props) => (props.primary ? "#e01a22" : "#6e6e6e;")};
`;

const selectIsLoading = createSelector(
  (state: RootState) => state.verifyMobileNumber.isSendingCode,
  (state: RootState) => state.verifyMobileNumber.isVerifyingCode,
  (state: RootState) => state.customerProfile.isVerifyingLater,
  (isSendingCode, isVerifyingCode, isVerifyingLater) =>
    isSendingCode || isVerifyingCode || isVerifyingLater
);

type FormData = {
  oneTimeCode: string;
};

interface VerifyMobileNumberPageProps {
  backButtonUrl: string | undefined;
}

export function VerifyMobileNumberPage(prop: VerifyMobileNumberPageProps) {
  const oneTimeCodeInputName = "oneTimeCode";
  const [showBanner, setShowBanner] = useState(false);

  const mobile = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mobile
  );

  const mfaPreference = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mfaPreference
  );

  const verifyCodeErrorMessage = useSelector(
    (state: RootState) => state.verifyMobileNumber.verifyCodeErrorMessage
  );

  const sendCodeErrorMessage = useSelector(
    (state: RootState) => state.verifyMobileNumber.verificationErrorPageMessage
  );

  const isLoading = useSelector(selectIsLoading);

  const maskedLabel = (
    <>
      <span>Enter the one-time code we sent to </span>
      <MaskedLabel>{maskValue(mobile)}. </MaskedLabel>
      <span>Not your mobile number? </span>
    </>
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors, isSubmitted },
  } = useForm<FormData>();

  useEffect(() => {
    dispatch(resetVerifyCodeState());
  }, [dispatch]);

  const setOneTimeCodeValue = (value: string) => {
    const sanitizedValue = removeAllSpaces(value);
    const isNotEmpty = !sanitizedValue;
    const shouldValidate = isSubmitted && isNotEmpty;

    setValue(oneTimeCodeInputName, sanitizedValue, {
      shouldDirty: true,
      shouldValidate,
    });

    if (!shouldValidate) {
      clearErrors();
    }
  };

  const onSubmit = (data: FormData) => {
    dispatch(
      verifyCodeAction({
        verificationCode: data.oneTimeCode,
        channel: VerificationChannel.Sms,
      })
    );

    reset();
  };

  const sendCodeAgain = (e: React.MouseEvent) => {
    e.preventDefault();

    dispatch(
      sendCodeAction({
        mobile,
      })
    );
  };

  const verifyLater = (e: React.MouseEvent) => {
    if (
      mfaPreference?.toLocaleLowerCase() === "high" ||
      mfaPreference?.toLocaleLowerCase() === "medium"
    ) {
      setShowBanner(true);
      return;
    } else {
      setShowBanner(false);
      history.push("/");
    }
  };
  const isPersonalCustomer = useIsPersonalCustomer();
  const isSecurityPreferenceCustomerTypeFeatureFlagOn =
    configuration.securityPreferenceCustomerType;

  const verifyLaterComponent =
    !isSecurityPreferenceCustomerTypeFeatureFlagOn || !isPersonalCustomer ? (
      <CenteredDiv>
        <VerifyLaterLink onClick={verifyLater} data-testid="verify-later-link">
          Verify Later
        </VerifyLaterLink>
      </CenteredDiv>
    ) : null;
  const sendCodeAgainComponent = sendCodeErrorMessage ? (
    <InformationContainer message={sendCodeInfoMessage} />
  ) : (
    <>
      <span>{didntReceiveATextText}</span>
      <SettingButton
        onClick={sendCodeAgain}
        data-testid="send-code-again-button"
        type="button"
      >
        {sendCodeAgainText}
      </SettingButton>
    </>
  );

  const registerOptions = register(oneTimeCodeInputName, {
    required: true,
    validate: {
      validMobileNumber: (value) => isOneTimeCodeValid(value),
    },
    onChange: (e) => {
      setOneTimeCodeValue(e.target.value);
    },
  });
  const otcFieldRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    otcFieldRef.current?.focus();
  });

  return (
    <Layout>
      <SubPageHeader>Verify my phone number</SubPageHeader>
      <Container>
        <PageLoader isShown={isLoading} />
        <HideableDiv isHidden={isLoading}>
          {showBanner && (
            <VerifyLaterBanner
              data-testid="verify-later-banner"
              backButtonUrl={prop.backButtonUrl}
            />
          )}
          <InputDiv role="status">
            {maskedLabel}
            <VerifyLink
              data-testid="add-edit-mobile-link"
              to="/add-edit-mobile"
            >
              {addEditMyPhoneNumberText}
            </VerifyLink>
          </InputDiv>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="verify-mobile-number-form"
            noValidate
          >
            <InputDiv>
              <LineLabel htmlFor="editable-one-time-code-input">
                One time code
              </LineLabel>
              <EditableOneTimeCode
                autoComplete="off"
                id="editable-one-time-code-input"
                {...registerOptions}
                ref={(e) => {
                  registerOptions.ref(e);
                  otcFieldRef.current = e;
                }}
                errorMessage={
                  verifyCodeErrorMessage
                    ? verifyCodeErrorMessage
                    : errors.oneTimeCode?.message
                }
              />
            </InputDiv>

            {sendCodeAgainComponent}

            <VerifyButtonContainer>
              <VerifyButton data-testid="save-button" type="submit">
                Submit verification code
              </VerifyButton>
            </VerifyButtonContainer>
          </form>
          {verifyLaterComponent}
        </HideableDiv>
      </Container>
      <AppFooter />
    </Layout>
  );
}
