import { ThemedButton } from "ccp-common-ui-components";
import { useDispatch } from "react-redux";
import { deleteAccount } from "../../store/delete-customer-profile/view/DeleteAccountSlice";
import {
  DeleteAccountButtons,
  DeleteAccountYesButton,
  DeleteAccountStandardIcon,
  DeleteAccountText,
} from "./DeleteAccountStyles";

import AlertIcon from "../../assets/icons/alert-icon.svg";

export function DeleteAccountConfirmation({ returnTo }: { returnTo: string }) {
  const dispatch = useDispatch();

  const sendDeleteAccount = () => {
    dispatch(deleteAccount());
  };

  const cancelAccountDelete = () => {
    window.location.assign(returnTo);
  };

  return (
    <>
      <DeleteAccountStandardIcon src={AlertIcon} alt="alert" />
      <h2>Delete account?</h2>
      <DeleteAccountText>
        Send us a request to delete your Coles Group account, which includes
        Coles and Coles Express.
      </DeleteAccountText>
      <DeleteAccountText>
        We&#39;ll delete your account within 10 business days. This cannot be
        undone.
      </DeleteAccountText>
      <DeleteAccountButtons>
        <ThemedButton onClick={cancelAccountDelete}>
          No, keep account
        </ThemedButton>
        <DeleteAccountYesButton onClick={sendDeleteAccount}>
          Send request
        </DeleteAccountYesButton>
      </DeleteAccountButtons>
    </>
  );
}
