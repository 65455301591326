import { useDispatch, useSelector } from "react-redux";
import { updateFirstNameAction } from "../../store/update-first-name/UpdateFirstNameActions";
import { RootState } from "../../store/Store";
import { UpdateFailureMessage } from "./UpdateFailureMessage";
import InlineEditProfileSetting from "./InlineEditProfileSetting";
import ReadonlyProfileSetting from "./ReadonlyProfileSetting";
import { UpdateSuccessMessage } from "./UpdateSuccessMessage";
import ValidationFailureMessage from "./ValidationFailureMessage";
import { asyncOperationReset } from "../../store/async-operation/AsyncOperationSlice";

export interface FirstNameProfileSettingProps {
  firstName: string;
  allowNameEditing: boolean;
}

const isValid = (value: string) => {
  return value.length <= 31 && /^[a-z '-]+$/i.test(value);
};

const sanitize = (value: string) => {
  return `${value}`.trim().replace(/ {2,}/g, " ");
};

const ValidationFailedMessage = (props: any) => (
  <ValidationFailureMessage
    {...props}
    text="Your first name cannot include special characters or symbols. Please try again."
    id="first-name-error"
  />
);

const UpdateSuccessfulMessage = (props: any) => (
  <UpdateSuccessMessage {...props} text="Your first name has been updated." />
);

const UpdateFailedMessage = (props: any) => (
  <UpdateFailureMessage {...props}>
    We weren’t able to update your first name. Please try again later.
  </UpdateFailureMessage>
);

function FirstNameProfileSetting(props: FirstNameProfileSettingProps) {
  const dispatch = useDispatch();
  const updateStatus = useSelector(
    (state: RootState) => state.asyncOperation.updateFirstName.status
  );

  const onEdit = () => {
    dispatch(asyncOperationReset("updateFirstName"));
  };

  const onSave = (value: string) => {
    dispatch(
      updateFirstNameAction({
        firstName: value,
      })
    );
  };

  return props.allowNameEditing ? (
    <InlineEditProfileSetting
      name="First name"
      value={props.firstName}
      id="first-name"
      startEditLabel="Edit first name"
      saveLabel="Save first name"
      onEdit={onEdit}
      onSave={onSave}
      saveStatus={updateStatus}
      isValid={isValid}
      sanitize={sanitize}
      InvalidMessage={ValidationFailedMessage}
      SuccessMessage={UpdateSuccessfulMessage}
      FailureMessage={UpdateFailedMessage}
    />
  ) : (
    <ReadonlyProfileSetting
      name="First name"
      displayValue={props.firstName}
      id="first-name"
    />
  );
}

export default FirstNameProfileSetting;
