export function getQueryParameter(
  paramName: string,
  location: Pick<Location, "search"> = window.location
): string | null {
  const params = new URLSearchParams(location.search);
  let paramValue: string | null = null;
  params.forEach(function (value, key) {
    if (key.toLowerCase() === paramName.toLowerCase()) {
      paramValue = value;
    }
  });
  return paramValue;
}

export function removeQueryParameter(
  paramName: string,
  location: Pick<Location, "href"> = window.location
): string {
  const url = new URL(location.href);
  url.searchParams.delete(paramName);
  return url.toString();
}

export function redirect(
  url: string,
  location: Pick<Location, "href"> = window.location
): void {
  location.href = url;
}

export function rewriteTo(
  url: string,
  history: Pick<History, "replaceState"> = window.history
): void {
  history.replaceState({}, "", url);
}
