import React from "react";

import styled from "styled-components";
import { UseFormRegisterReturn } from "react-hook-form";
import { ErrorText } from "ccp-common-ui-components";

import { InputFieldProps } from "../../common/form/InputField";
import { screenSize } from "../../common/styles/ScreenSizes";
import { SettingInput } from "../styled/SettingInput";
import { MessageContainer } from "../styled/MessageContainer";

export type MobileNumberInputProps = InputFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type EditableMobileNumberProps = MobileNumberInputProps & {
  setMobileNumber?: (value: string) => void;
  errorMessage?: string;
} & UseFormRegisterReturn;

const EditableMobileNumberContainer = styled.div`
  flex: 1 1 auto;
  order: 2;
  width: 100%;
  @media ${screenSize.mobile} {
    order: 2;
  }
`;

const MobileNumberInput = styled(SettingInput)`
  max-width: 100%;
`;

export const EditableMobileNumber = React.forwardRef(
  (
    props: MobileNumberInputProps & EditableMobileNumberProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!props.errorMessage;
    const errorTextId = "mobile-number-error";
    return (
      <EditableMobileNumberContainer data-testid="editable-mobile-number">
        <MobileNumberInput
          id="mobile-number"
          data-testid="editable-mobile-number-input"
          hasError={hasError}
          displaySuccessIcon={false}
          type="tel"
          aria-invalid={hasError}
          aria-describedby={errorTextId}
          autoComplete="tel-local"
          required
          {...props}
          ref={ref}
        />
        {hasError && (
          <MessageContainer>
            <ErrorText id={errorTextId} data-testid="error-text">
              {props.errorMessage}
            </ErrorText>
          </MessageContainer>
        )}
      </EditableMobileNumberContainer>
    );
  }
);
