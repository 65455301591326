import React from "react";
import {
  calculatePasswordStrengthScore,
  evaluatePasswordStrength,
  PasswordStrength,
} from "./PasswordStrengthCalculator";
import "./PasswordStrengthChecker.css";
import styled from "styled-components";

/* This code was imported from the universal-login-spa's password checker.
   Source: https://dev.azure.com/colesgroup/CustomerIdentity/_git/universal-login-spa?path=%2Fsrc%2Fcommon%2Fpassword-strength-checker */

export interface PasswordStrengthCheckerProps {
  password: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
}

const PasswordAccessibility = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export function PasswordStrengthChecker(props: PasswordStrengthCheckerProps) {
  const passwordStrengthScore = calculatePasswordStrengthScore(
    props.password,
    props.firstName,
    props.lastName,
    props.mobile,
    props.email
  );

  const passwordStrength = evaluatePasswordStrength(passwordStrengthScore);
  const showPasswordStrength = props.password && props.password.length >= 8;
  const getPasswordAccessabilityText = `Password strength is ${passwordStrength};`;

  let strengthColorClass = "";

  if (showPasswordStrength) {
    switch (passwordStrength) {
      case PasswordStrength.Weak:
        strengthColorClass = "weak-password";
        break;
      case PasswordStrength.Moderate:
        strengthColorClass = "moderate-password";
        break;
      case PasswordStrength.Strong:
        strengthColorClass = "strong-password";
        break;
      default:
        break;
    }
  }

  return (
    <>
      <div className="password-strength-checker">
        <div className="strength-bar">
          <div
            data-testid="strength-bar-one"
            className={`strength-bar-background ${strengthColorClass}`}
          />
        </div>

        <div className="strength-bar">
          <div
            data-testid="strength-bar-two"
            className={`strength-bar-background ${
              passwordStrength !== PasswordStrength.Weak
                ? strengthColorClass
                : ""
            }`}
          />
        </div>

        <div className="strength-bar">
          <div
            data-testid="strength-bar-three"
            className={`strength-bar-background ${
              passwordStrength === PasswordStrength.Strong
                ? strengthColorClass
                : ""
            }`}
          />
        </div>
      </div>
      <span className="strength-text">
        {showPasswordStrength ? (
          <>
            <PasswordAccessibility aria-live="polite">
              {getPasswordAccessabilityText}
            </PasswordAccessibility>
            <span aria-hidden={true}>{passwordStrength}</span>
          </>
        ) : (
          ""
        )}
      </span>
    </>
  );
}
