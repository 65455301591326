import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UpdatePasswordErrorState {
  currentPasswordError: string | null;
  newPasswordError: string | null;
}

export const initialState: UpdatePasswordErrorState = {
  currentPasswordError: null,
  newPasswordError: null,
};

export interface PasswordErrors {
  currentIsInvalid: boolean;
  newIsInvalid: boolean;
}

const updatePasswordSlice = createSlice({
  name: "updatePassword",
  initialState,
  reducers: {
    currentPasswordChanged: (state) => {
      state.currentPasswordError = null;
    },
    newPasswordChanged: (state) => {
      state.newPasswordError = null;
    },
    updatePasswordErrorOccurred: (
      state,
      action: PayloadAction<PasswordErrors>
    ) => {
      const { currentIsInvalid, newIsInvalid } = action.payload;
      if (currentIsInvalid) {
        state.currentPasswordError =
          "The password you entered does not match your current password. Please try again.";
      }
      if (newIsInvalid) {
        state.newPasswordError =
          "The password entered doesn't meet the minimum security requirements. It must be at least 8 characters in length and contain at least one number and one character.";
      }
    },
    clearPasswordDetails: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  currentPasswordChanged,
  newPasswordChanged,
  updatePasswordErrorOccurred,
  clearPasswordDetails,
} = updatePasswordSlice.actions;

export const updatePasswordReducer = updatePasswordSlice.reducer;
