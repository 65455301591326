import { ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "./styles/ScreenSizes";
import { useMediaPredicate } from "react-media-hook";
import { redirect } from "./utils/NavigationHelpers";
import configuration from "../config/Configuration";
import { useSelector } from "react-redux";
import { RootState } from "../store/Store";
import { AuthenticationStatus } from "../store/check-authentication/CheckAuthenticationSlice";

const SmallBackButton = styled(ThemedButton)`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const BackButtonTextElement = styled.span`
  &:before {
    content: "\\2039";
    transform: scale(1.5);
    display: inline-block;
    margin-right: 0.5em;
  }
`;

const MobileBackButtonBanner = styled(ThemedButton)`
  display: flex;
  border-radius: 0;
  width: 100%;

  &::after {
    display: none;
  }
`;

const BackButtonBannerTextElement = styled(BackButtonTextElement)`
  text-decoration: underline;
`;

export function BackButtonContainer({ returnTo }: BackButtonContainerProps) {
  const isMobileView = useMediaPredicate(screenSize.mobile);
  const buttonText = "Return to shop";

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );
  const isUserAuthenticated =
    authenticationStatus === AuthenticationStatus.Authenticated;

  const isReturnUrlInAllowedList = configuration.allowedReturnUrls?.some(
    (x) => x.toLowerCase() === returnTo?.toLowerCase()
  );

  return (
    <>
      {returnTo &&
        isUserAuthenticated &&
        isReturnUrlInAllowedList &&
        (isMobileView ? (
          <MobileBackButtonBanner
            data-testid="back-button-banner"
            onClick={() => redirect(returnTo)}
          >
            <BackButtonBannerTextElement>
              {buttonText}
            </BackButtonBannerTextElement>
          </MobileBackButtonBanner>
        ) : (
          <SmallBackButton
            data-testid="small-back-button"
            onClick={() => redirect(returnTo)}
          >
            <BackButtonTextElement>{buttonText}</BackButtonTextElement>
          </SmallBackButton>
        ))}
    </>
  );
}

export interface BackButtonContainerProps {
  returnTo?: string | null;
}
