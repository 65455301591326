import { createAction } from "@reduxjs/toolkit";
import { GetLoyaltyAccountsPayload } from "./LoyaltyAccountsSlice";

export const getLoyaltyAccountsAction = createAction<GetLoyaltyAccountsPayload>(
  "loyalty-accounts/get"
);

export const getLoyaltyAccountsErrorAction = createAction(
  "loyalty-accounts/get/error"
);
