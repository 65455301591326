import styled from "styled-components";
import Modal, { Props as ReactModalProps } from "react-modal";
import { CloseButton, ThemedButton } from "ccp-common-ui-components";
import alertIcon from "../../assets/icons/alert-icon.svg";
import React, { useRef } from "react";
import { headers } from "../../common/styles/RocketColours";
import { NonThemedButton } from "../../components/styled/NonThemedButton";

const StyledModal = styled(Modal)`
  height: 270px;
  max-width: 458px;
  position: absolute;
  bottom: 40px;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  outline: none;
  overflow: hidden;
  padding: 12px 0 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: 1px solid #979797;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 ${headers.transparentBlack};

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    margin: auto 12px;
    padding: 10px 0 5px;
  }
`;

export const ModalHeader = styled.div`
  padding: 0 12px;
`;

export const ModalH1 = styled.h1`
  margin-top: 24px;
  margin-right: 36px;
`;

export const ModalCloseButton = styled(CloseButton)`
  float: right;
  margin-right: 6px;
`;

export const ModalContent = styled.div`
  overflow-y: scroll;
  margin: 0px;
  padding: 0 12px 12px;
`;

const ButtonContainer = styled.div`
  align-self: left;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-family: "Source Sans";
  font-size: 16px;
  line-height: 22px;
  margin: 4px 0;
  text-align: center;
  padding-top: 20px;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    font-size: 14px;
  }
`;

const StyledIconWrapper = styled.div`
  position: relative;
  height: 50px;
  padding-top: 32px;
`;

const StyledStatusIcon = styled.img`
  position: absolute;
  top: 20%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const CenteredHeader = styled.h3`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
  color: #000a12;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Avant Garde";

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    font-size: 16px;
  }
`;

const CenteredContent = styled.div`
  color: ${headers.black900};
  font-family: "Source Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  padding: 10px 30px 28px 30px;
  text-align: justify;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    font-size: 12px;
    padding: 10px 15px 28px 15px;
  }
`;

const Separator = styled.div`
  height: 1px;
  width: 2%;
  margin: 0 5px;
`;

export const ActionButton = styled(ThemedButton)`
  display: inline-block;
  width: 47%;
  height: 39px;
  line-height: 39px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-align: center;
  }
`;

export const NonThemedActionButton = styled(NonThemedButton)`
  display: inline-block;
  width: 47%;
  height: 39px;
  line-height: 39px;
  text-align: center;

  @media (max-width: ${(props) => props.theme.mobileBreakpoint}) {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
`;

export interface ActionModalProps extends ReactModalProps {
  children?: any;
  onSuccess: any;
  header: string;
  blurb: string;
  yesbutton: string;
  nobutton: string;
}

export function ActionModal(props: ActionModalProps) {
  Modal.setAppElement("#root");
  const headingRef = useRef<HTMLHeadingElement>(null);

  return (
    <StyledModal
      role="dialog"
      aria-modal="true"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      id="action-modal"
      {...props}
      style={{
        overlay: {
          backgroundColor: "none",
        },
      }}
    >
      {props.children}
      <div>
        <ModalCloseButton
          role="button"
          data-testid="action-close-button"
          onClick={(e) => {
            if (props.onRequestClose) {
              props?.onRequestClose(e);
            }
          }}
        ></ModalCloseButton>
      </div>
      <StyledIconWrapper aria-hidden>
        <StyledStatusIcon src={alertIcon} alt="" />
      </StyledIconWrapper>
      <CenteredHeader
        tabIndex={-1}
        id="dialog-title"
        role="alert"
        ref={headingRef}
      >
        {props.header}
      </CenteredHeader>
      <CenteredContent id="dialog-description">
        {props.blurb}
        <ButtonContainer>
          <ActionButton
            role="button"
            aria-label="cancel"
            data-testid="cancel-button"
            onClick={(e: React.MouseEvent) => {
              if (props.onRequestClose) {
                props?.onRequestClose(e);
              }
            }}
          >
            {props.nobutton}
          </ActionButton>
          <Separator></Separator>
          <NonThemedActionButton
            role="button"
            aria-label="send delete request"
            data-testid="send-delete-request-button"
            onClick={(e: React.MouseEvent) => {
              if (props.onSuccess) {
                props?.onSuccess(e);
              }
            }}
          >
            {props.yesbutton}
          </NonThemedActionButton>
        </ButtonContainer>
      </CenteredContent>
    </StyledModal>
  );
}
