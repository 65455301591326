import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { updateCustomerMfaPreference } from "../../../api/profile/CustomerProfileApi";
import { unableToUpdateMfaPreferenceErrorMessage } from "../../../common/constants/ErrorMessages";
import {
  updateMfaPreferenceFailed,
  updateMfaPreferenceSuccess,
} from "../../../store/customer-profile/CustomerProfileSlice";

export function* updateMfaPreferenceSaga(action: PayloadAction<string>) {
  try {
    const mfaPreference = action.payload;

    yield call(updateCustomerMfaPreference, mfaPreference);
    yield put(updateMfaPreferenceSuccess(mfaPreference));
  } catch {
    yield put(
      updateMfaPreferenceFailed(unableToUpdateMfaPreferenceErrorMessage)
    );
  }
}
