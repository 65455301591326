import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";

import styled from "styled-components";

import { SettingValue } from "../../styled/SettingValue";
import { UpdateSuccessMessage } from "../UpdateSuccessMessage";
import { MessageContainer } from "../../styled/MessageContainer";
import { UpdateFailureMessage } from "../UpdateFailureMessage";

const StyledMessageContainer = styled(MessageContainer)`
  flex-direction: column;
`;

export function HomePhoneDisplay() {
  const { homePhone } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const isHomePhoneUpdated = useSelector(
    (state: RootState) => state.customerProfile.isHomePhoneUpdated
  );
  const errorMessage = useSelector(
    (state: RootState) => state.customerProfile.homePhoneErrorMessage
  );

  return (
    <SettingValue data-testid="home-phone-display">
      <StyledMessageContainer>
        <span>{homePhone}</span>

        {isHomePhoneUpdated && (
          <UpdateSuccessMessage
            data-testid="update-success-message"
            text="Your home phone has been updated."
          />
        )}

        {errorMessage && (
          <UpdateFailureMessage data-testid="update-failure-message">
            {errorMessage}
          </UpdateFailureMessage>
        )}
      </StyledMessageContainer>
    </SettingValue>
  );
}
