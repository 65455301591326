import { MobileVerificationError } from "../../api/verifyMobileNumber/MobileVerificationApiClient";

export const unexpectedVerifyMobileErrorMessage = "Something went wrong.";

export function getVerifyMobileErrorMessage(error: unknown) {
  let errorMessage: string;
  if (error instanceof MobileVerificationError) {
    switch (error.data?.code) {
      case "TOO_MANY_VERIFY_CODE_REQUESTS":
        errorMessage = "You entered in a code too many times.";
        break;

      case "TOO_MANY_SEND_CODE_REQUESTS":
        errorMessage =
          "You have requested a code too many times. Wait 5 minutes to try again.";
        break;

      default:
        errorMessage = unexpectedVerifyMobileErrorMessage;
        break;
    }
  } else {
    errorMessage = unexpectedVerifyMobileErrorMessage;
  }
  return errorMessage;
}
