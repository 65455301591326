import React from "react";
import styled from "styled-components";
import { ThemedButton } from "../../components/styled/ThemedButton";
import { screenSize } from "../styles/ScreenSizes";
import errorImage from "../../assets/images/displayError.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkAuthenticationAction } from "../../store/check-authentication/CheckAuthenticationActions";
import { StyledButtonWrapper } from "../../components/styled/StyledButtonWrapper";
import { headers } from "../styles/RocketColours";

const ImageWrapper = styled.div`
  position: relative;
  height: 60px;
`;

export const CenteredHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 27px;
  padding-top: 10px;
  text-align: center;
  color: ${headers.black900};
  font-family: "Avant Garde";
`;

const CenteredParagraph = styled.p`
  color: ${headers.black900};
  opacity: 0.85;
  font-family: "Source Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
`;

const ThemedRefreshButton = styled(ThemedButton)`
  min-width: 50%;
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

const StyledErrorImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 240px;
  max-width: 140px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

function ErrorPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  function handleClick() {
    dispatch(checkAuthenticationAction(history.location.pathname));
    history.goBack();
  }

  return (
    <div>
      <>
        <ImageWrapper aria-hidden>
          <StyledErrorImage src={errorImage} alt="" />
        </ImageWrapper>
        <CenteredHeader>Oops! Something went wrong</CenteredHeader>
        <CenteredParagraph>
          We can’t seem to find what you’re looking for. Try refreshing the page
          or come back a little later.
        </CenteredParagraph>
        <StyledButtonWrapper>
          <ThemedRefreshButton onClick={() => handleClick()}>
            Refresh this page
          </ThemedRefreshButton>
        </StyledButtonWrapper>
      </>
    </div>
  );
}

export default ErrorPage;
