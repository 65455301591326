import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum AuthenticationStatus {
  Unknown,
  Checking,
  Failed,
  Authenticated,
}

export interface AuthenticationState {
  status: AuthenticationStatus;
  isLoading: boolean;
}

const initialState: AuthenticationState = {
  status: AuthenticationStatus.Unknown,
  isLoading: true,
};

const authenticationSlice = createSlice({
  name: "checkAuthentication",
  initialState,
  reducers: {
    checkAuthentication: (state) => {
      state.status = AuthenticationStatus.Checking;
      state.isLoading = true;
    },
    checkAuthenticationSuccess: (state) => {
      state.status = AuthenticationStatus.Authenticated;
      state.isLoading = false;
    },
    checkAuthenticationFailed: (state) => {
      state.status = AuthenticationStatus.Failed;
      state.isLoading = false;
    },
    toggleIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  checkAuthentication,
  checkAuthenticationSuccess,
  checkAuthenticationFailed,
  toggleIsLoading,
} = authenticationSlice.actions;

export const authenticationReducer = authenticationSlice.reducer;
