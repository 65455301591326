import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { optionIsRequiredErrorMessage } from "../../common/constants/ErrorMessages";

export interface PostcodeFields {
  postcode: string;
  suburb: string;
  state: string;
}

export interface CustomerProfileFields {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  homePhone: string;
  dateOfBirth: string;
  locality?: PostcodeFields;
  isMobileVerified?: boolean;
  mfaPreference?: string;
  pendingDeletion?: boolean;
  profileType: string;
}

export interface CustomerProfileState {
  profileFields: CustomerProfileFields;
  isUpdatingPassword: boolean;
  field: string;
  isLoadingCustomerProfile: boolean;
  isLoadingUpdatePassword: boolean;
  isUpdatingDateOfBirth: boolean;
  isDateOfBirthUpdated: boolean;
  dateOfBirthErrorMessage?: string;
  isUpdatingHomePhone: boolean;
  isHomePhoneUpdated: boolean;
  homePhoneErrorMessage?: string;
  isUpdatingMfaPreference: boolean;
  isMfaPreferenceUpdated: boolean;
  mfaPreferenceErrorMessage?: string;
  isMfaPreferenceUpdateSuccessBannerShown: boolean;
  isVerifyingLater: boolean;
  isVerifyLaterDone: boolean;
  isProfileDeleteRequestSuccess?: boolean;
  isSendingProfileDeleteRequest: boolean;
  isUpdatingPostcode: boolean;
  isPostcodeUpdated: boolean;
  postcodeErrorMessage?: string;
}

export type FieldChanged = {
  field: string;
};

export interface VerifyLaterPayload {
  mfaPreference: string;
}

export const initialState: CustomerProfileState = {
  profileFields: {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    homePhone: "",
    dateOfBirth: "",
    locality: {
      postcode: "",
      suburb: "",
      state: "",
    },
    mfaPreference: "",
    isMobileVerified: false,
    pendingDeletion: false,
    profileType: "",
  },
  isUpdatingPassword: false,
  field: "",
  isLoadingCustomerProfile: true,
  isLoadingUpdatePassword: false,
  isUpdatingDateOfBirth: false,
  isDateOfBirthUpdated: false,
  isUpdatingHomePhone: false,
  isHomePhoneUpdated: false,
  isUpdatingMfaPreference: false,
  isMfaPreferenceUpdated: false,
  isMfaPreferenceUpdateSuccessBannerShown: false,
  isVerifyingLater: false,
  isVerifyLaterDone: false,
  isProfileDeleteRequestSuccess: undefined,
  isSendingProfileDeleteRequest: false,
  isUpdatingPostcode: false,
  isPostcodeUpdated: false,
  postcodeErrorMessage: undefined,
};

const customerProfileSlice = createSlice({
  name: "customerProfile",
  initialState,
  reducers: {
    storeCustomerProfile: (
      state,
      { payload }: PayloadAction<CustomerProfileFields>
    ) => {
      state.profileFields = { ...state.profileFields, ...payload };
    },
    setUserUpdatesPasswordFlag: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingPassword = payload;
    },
    updateIsLoadingUpdatePassword: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdatePassword = action.payload;
    },
    updateIsLoadingCustomerProfile: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCustomerProfile = action.payload;
    },
    updateCustomerMobileField: (state, action: PayloadAction<string>) => {
      state.profileFields.mobile = action.payload;
    },
    updateCustomerHomephoneField: (state, action: PayloadAction<string>) => {
      state.profileFields.homePhone = action.payload;
    },
    updateCustomerFirstNameField: (state, action: PayloadAction<string>) => {
      state.profileFields.firstName = action.payload;
    },
    updateCustomerLastNameField: (state, action: PayloadAction<string>) => {
      state.profileFields.lastName = action.payload;
    },
    updateField: (state, action: PayloadAction<FieldChanged>) => {
      state.field = action.payload.field;
    },
    updateDateOfBirth: (state, action: PayloadAction<string>) => {
      state.isUpdatingDateOfBirth = true;
      state.isDateOfBirthUpdated = false;
      state.dateOfBirthErrorMessage = undefined;
    },
    cancelDateOfBirthUpdate: (state) => {
      state.isUpdatingDateOfBirth = false;
      state.isDateOfBirthUpdated = false;
      state.dateOfBirthErrorMessage = undefined;
    },
    updateDateOfBirthSuccess: (state, action: PayloadAction<string>) => {
      state.profileFields.dateOfBirth = action.payload;
      state.isUpdatingDateOfBirth = false;
      state.isDateOfBirthUpdated = true;
      state.dateOfBirthErrorMessage = undefined;
    },
    updateDateOfBirthFailed: (state, action: PayloadAction<string>) => {
      state.isUpdatingDateOfBirth = false;
      state.isDateOfBirthUpdated = false;
      state.dateOfBirthErrorMessage = action.payload;
    },
    updatePostcode: (state, action: PayloadAction<PostcodeFields>) => {
      state.isUpdatingPostcode = true;
      state.isPostcodeUpdated = false;
      state.postcodeErrorMessage = undefined;
    },
    updatePostcodeSuccess: (state, action: PayloadAction<PostcodeFields>) => {
      state.profileFields.locality = action.payload;
      state.isUpdatingPostcode = false;
      state.isPostcodeUpdated = true;
      state.postcodeErrorMessage = undefined;
    },
    updatePostcodeFailed: (state, action: PayloadAction<string>) => {
      state.isUpdatingPostcode = false;
      state.isPostcodeUpdated = false;
      state.postcodeErrorMessage = action.payload;
    },
    cancelPostcodeUpdate: (state) => {
      state.isUpdatingPostcode = false;
      state.isPostcodeUpdated = false;
      state.postcodeErrorMessage = undefined;
    },
    updateHomePhone: (state, action: PayloadAction<string>) => {
      state.isUpdatingHomePhone = true;
      state.isHomePhoneUpdated = false;
      state.homePhoneErrorMessage = undefined;
    },
    cancelHomePhoneUpdate: (state) => {
      state.isUpdatingHomePhone = false;
      state.isHomePhoneUpdated = false;
      state.homePhoneErrorMessage = undefined;
    },
    updateHomePhoneSuccess: (state, action: PayloadAction<string>) => {
      state.profileFields.homePhone = action.payload;
      state.isUpdatingHomePhone = false;
      state.isHomePhoneUpdated = true;
      state.homePhoneErrorMessage = undefined;
    },
    updateHomePhoneFailed: (state, action: PayloadAction<string>) => {
      state.isUpdatingHomePhone = false;
      state.isHomePhoneUpdated = false;
      state.homePhoneErrorMessage = action.payload;
    },
    cancelMfaPreferenceUpdate: (state) => {
      state.isUpdatingMfaPreference = false;
      state.isMfaPreferenceUpdated = false;
      state.mfaPreferenceErrorMessage = optionIsRequiredErrorMessage;
    },
    updateMfaPreference: (state, action: PayloadAction<string>) => {
      state.isUpdatingMfaPreference = true;
      state.isMfaPreferenceUpdated = false;
      state.mfaPreferenceErrorMessage = undefined;
    },
    updateMfaPreferenceSuccess: (state, action: PayloadAction<string>) => {
      state.profileFields.mfaPreference = action.payload;
      state.isUpdatingMfaPreference = false;
      state.isMfaPreferenceUpdated = true;
      state.mfaPreferenceErrorMessage = undefined;
    },
    updateMfaPreferenceFailed: (state, action: PayloadAction<string>) => {
      state.isUpdatingMfaPreference = false;
      state.isMfaPreferenceUpdated = false;
      state.mfaPreferenceErrorMessage = action.payload;
    },
    hideMfaPreferenceUpdatedBanner: (state) => {
      state.isMfaPreferenceUpdateSuccessBannerShown = false;
      state.mfaPreferenceErrorMessage = undefined;
    },
    setMfaPreferenceUpdateBannerErrorMessage: (
      state,
      action: PayloadAction<string>
    ) => {
      state.mfaPreferenceErrorMessage = action.payload;
      state.isMfaPreferenceUpdateSuccessBannerShown = false;
    },
    verifyMobileUpdateMfaPreferenceSuccess: (
      state,
      action: PayloadAction<string>
    ) => {
      state.profileFields.mfaPreference = action.payload;
      state.isMfaPreferenceUpdateSuccessBannerShown = true;
      state.mfaPreferenceErrorMessage = undefined;
    },
    verifyLater: (state, action: PayloadAction<VerifyLaterPayload>) => {
      state.isVerifyingLater = true;
      state.isVerifyLaterDone = false;
    },
    verifyLaterSuccess: (state, action: PayloadAction<string>) => {
      state.profileFields.mfaPreference = action.payload;
      state.isVerifyingLater = false;
      state.isVerifyLaterDone = true;
    },
    customerProfilePageClosing: (state) => {
      state.isHomePhoneUpdated = false;
      state.homePhoneErrorMessage = undefined;
      state.isDateOfBirthUpdated = false;
      state.dateOfBirthErrorMessage = undefined;
      state.isMfaPreferenceUpdated = false;
      state.mfaPreferenceErrorMessage = undefined;
      state.isMfaPreferenceUpdateSuccessBannerShown = false;
      state.mfaPreferenceErrorMessage = undefined;
    },
    clearFieldValue: (state) => {
      state.field = "";
    },
    deleteProfileSuccess: (state) => {
      state.isProfileDeleteRequestSuccess = true;
      state.isSendingProfileDeleteRequest = false;
      state.isLoadingCustomerProfile = false;
    },
    sendProfileDeleteRequest: (state) => {
      state.isProfileDeleteRequestSuccess = undefined;
      state.isSendingProfileDeleteRequest = true;
      state.isLoadingCustomerProfile = true;
    },
    deleteProfileFailed: (state, action: PayloadAction<string>) => {
      state.isProfileDeleteRequestSuccess = false;
      state.isSendingProfileDeleteRequest = false;
      state.isLoadingCustomerProfile = false;
    },
  },
});

export const {
  storeCustomerProfile,
  setUserUpdatesPasswordFlag,
  updateIsLoadingUpdatePassword,
  updateIsLoadingCustomerProfile,
  updateCustomerMobileField,
  updateCustomerFirstNameField,
  updateCustomerLastNameField,
  updateField,
  updateDateOfBirth,
  cancelDateOfBirthUpdate,
  updateDateOfBirthSuccess,
  updateDateOfBirthFailed,
  updateHomePhone,
  cancelHomePhoneUpdate,
  updateHomePhoneSuccess,
  updateHomePhoneFailed,
  cancelMfaPreferenceUpdate,
  updateMfaPreference,
  updateMfaPreferenceSuccess,
  updateMfaPreferenceFailed,
  hideMfaPreferenceUpdatedBanner,
  setMfaPreferenceUpdateBannerErrorMessage,
  verifyMobileUpdateMfaPreferenceSuccess,
  verifyLater,
  verifyLaterSuccess,
  customerProfilePageClosing,
  clearFieldValue,
  deleteProfileSuccess,
  deleteProfileFailed,
  sendProfileDeleteRequest,
  updatePostcode,
  updatePostcodeSuccess,
  updatePostcodeFailed,
  cancelPostcodeUpdate,
} = customerProfileSlice.actions;

export const customerProfileReducer = customerProfileSlice.reducer;
