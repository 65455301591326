import React from "react";
import styled from "styled-components";
import successIcon from "../../assets/icons/icon_check-success.svg";
import { headers } from "../../common/styles/RocketColours";

const Icon = styled.img`
  width: 18px;
  height: 18px;
  padding-right: 8px;
  display: inline-block;
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
`;

const Message = styled.span`
  color: ${headers.black900};
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
`;

export interface UpdateSuccessMessageProps {
  text: string;
}

export function UpdateSuccessMessage({
  text,
  ...props
}: UpdateSuccessMessageProps) {
  return (
    <Container {...props}>
      <Icon src={successIcon} alt="" aria-hidden />
      <Message role="status">{text}</Message>
    </Container>
  );
}
