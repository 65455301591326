import { useHistory } from "react-router-dom";
import { ResultPage } from "../../common/ResultPage";

export function DeleteProfileSuccessPage() {
  const history = useHistory();

  const returnToGroupProfile = () => {
    history.push("/");
  };

  return (
    <ResultPage
      hasError={false}
      header="Request sent"
      description="We’ll delete your account within 10 business days. You can keep using your account until it’s deleted."
      buttonText="Done"
      action={returnToGroupProfile}
    ></ResultPage>
  );
}
