import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import Setting from "./ProfileSetting";
import { Link } from "react-router-dom";
import { MfaSetting } from "./MfaSetting/MfaSetting";
import { useFeatureFlags } from "../../common/feature-flags/UseFeatureFlags";

export const passwordFillerLength: number = 8;
export const Settings = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

const PasswordFiller = styled.div`
  height: 8px;
  width: 8px;
  background-color: #414240;
  border-radius: 50%;
  display: inline-block;
  margin: 0px 3px;
`;

function SecuritySettings() {
  const { isBcpFeatureEnabled } = useFeatureFlags();
  return (
    <Settings>
      <Setting
        name="Password"
        value={[...Array(passwordFillerLength)].map((_, position) => {
          return (
            <PasswordFiller data-testid="password-filler" key={position} />
          );
        })}
        link={<Link to="/change-password">Change password</Link>}
        linkEnabled={true}
      />
      {!isBcpFeatureEnabled && <MfaSetting />}
    </Settings>
  );
}

export default SecuritySettings;
