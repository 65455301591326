import { HideableDiv, Link, ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const VerifyButtonContainer = styled.div`
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const FormHelpText = styled.p`
  margin-top: 0;
`;

export const VerifyButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const StyledHideableDiv = styled(HideableDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-bottom: 12px;
`;
