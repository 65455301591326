import { useSelector } from "react-redux";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import { RootState } from "../../store/Store";
import FlybuysErrorPage from "./FlybuysErrorPage";
import VerifyFlybuysAccount from "./VerifyFlybuysAccount";

const VerifyFlybuysAccountPage = () => {
  const { path } = useRouteMatch();

  const { isFlybuysLinkSuccess } = useSelector(
    (state: RootState) => state.flybuys
  );

  return (
    <>
      <Route exact path={`${path}/error`}>
        {typeof isFlybuysLinkSuccess !== "undefined" ? (
          <FlybuysErrorPage />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route exact path={path}>
        <VerifyFlybuysAccount />
      </Route>
    </>
  );
};

export default VerifyFlybuysAccountPage;
