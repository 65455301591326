import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PreferredStore {
  displayAddress: string;
  status: PreferredStoreStatus;
}

export enum PreferredStoreStatus {
  Unknown = "Unknown",
  NotFound = "NotFound",
  Found = "Found",
  Error = "Error",
}

const initialState: PreferredStore = {
  displayAddress: "",
  status: PreferredStoreStatus.Unknown,
};

const preferredStoreSlice = createSlice({
  name: "preferredStore",
  initialState,
  reducers: {
    storePreferredStore: (
      state: PreferredStore,
      { payload }: PayloadAction<PreferredStore>
    ) => {
      state.displayAddress = payload.displayAddress;
      state.status = payload.status;
    },
  },
});

export const { storePreferredStore } = preferredStoreSlice.actions;
export const preferredStoreReducer = preferredStoreSlice.reducer;
