import { useHistory } from "react-router-dom";
import { ResultPage } from "../../common/ResultPage";

export function DeleteProfileFailedPage() {
  const history = useHistory();

  const returnToGroupProfile = () => {
    history.push("/");
  };

  return (
    <ResultPage
      hasError={true}
      header="Something went wrong"
      description="We couldn't send your request to delete your account. Please try again."
      buttonText="Done"
      action={returnToGroupProfile}
    ></ResultPage>
  );
}
