import { Loader } from "ccp-common-ui-components";
import { ScreenReaderText } from "../styled/ScreenReaderText";

export interface PageLoaderProps {
  isShown?: boolean;
  screenReaderMessage?: string;
}

export function PageLoader({
  isShown,
  screenReaderMessage = "Loading...",
}: PageLoaderProps) {
  return (
    <>
      {isShown && (
        <>
          <Loader isShown={true} />
          <ScreenReaderText role="status">
            {screenReaderMessage}
          </ScreenReaderText>
        </>
      )}
    </>
  );
}
