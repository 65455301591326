/* This code was imported from the universal-login-spa's password checker.
   Source: https://dev.azure.com/colesgroup/CustomerIdentity/_git/universal-login-spa?path=%2Fsrc%2Fcommon%2Fpassword-strength-checker */

export function calculateBaseScore(password: string): number {
  return (password?.length ?? 0) * 4;
}

export function calculateNumericalScore(password: string): number {
  return password.replace(/[^0-9]/g, "").length >= 3 ? 5 : 0;
}

export function calculateSpecialCharacterScore(password: string): number {
  return password.replace(/[A-Za-z0-9]/g, "").length >= 2 ? 5 : 0;
}

export function calculateLowerAndUpperCaseScore(password: string): number {
  const hasUpperCase = password.match(/[A-Z]/);
  const hasLowerCase = password.match(/[a-z]/);
  return hasUpperCase && hasLowerCase ? 10 : 0;
}

export function calculateAlphanumericScore(password: string): number {
  const hasLetters = password.match(/[A-Za-z]/);
  const hasNumbers = password.match(/[0-9]/);
  return hasLetters && hasNumbers ? 15 : 0;
}

export function calculateNumericAndSpecialCharactersScore(
  password: string
): number {
  const hasNumbers = password.match(/[0-9]/);

  const hasSpecialCharacters = password.match(/[^A-Za-z0-9]/g);

  return hasNumbers && hasSpecialCharacters ? 15 : 0;
}

export function calculateLettersAndSpecialCharactersScore(
  password: string
): number {
  const hasLetters = password.match(/[A-Za-z]/);

  const hasSpecialCharacters = password.match(/[^A-Za-z0-9]/g);

  return hasLetters && hasSpecialCharacters ? 15 : 0;
}

export function calculateRepetitiveCharactersScore(password: string): number {
  let result = 0;
  password.split("").map((val, index, charArray) => {
    const firstOccurrenceOfCharacterIndex = charArray.indexOf(val);
    if (firstOccurrenceOfCharacterIndex !== index) {
      result -= 1;
    }
    return result;
  });
  return result;
}

export function calculatedBlacklistedWordsScore(password: string): number {
  const lowerCasePassword = password.toLowerCase();
  const blacklistedWords = [
    "coles",
    "colesonline",
    "password",
    "shopping",
    "liquorland",
    "vintagecellars",
    "firstchoice",
    "firstchoiceliquor",
  ];

  let result = 0;
  blacklistedWords.map((blacklistedValue) => {
    if (lowerCasePassword.includes(blacklistedValue)) {
      result = -50;
      return result;
    }
    return result;
  });

  return result;
}

export function calculateEmailLocalPartScore(
  password: string,
  email: string
): number {
  if (!email || !email.includes("@")) {
    return 0;
  }

  const lowerCasePassword = password.toLowerCase();
  const lowerCaseEmail = email.toLowerCase();

  const lastAtSymbolInEmail = lowerCaseEmail.lastIndexOf("@");
  const emailLocalPart = lowerCaseEmail.substring(0, lastAtSymbolInEmail);
  if (lowerCasePassword.includes(emailLocalPart)) {
    return -100;
  }
  return 0;
}

export function calculateFirstAndLastNameScore(
  password: string,
  firstName: string,
  lastName: string
): number {
  password = password.toLowerCase();
  firstName = firstName?.toLowerCase();
  lastName = lastName?.toLowerCase();

  const passwordContainsFirstName = firstName && password.includes(firstName);
  const passwordContainsLastName = lastName && password.includes(lastName);

  if (passwordContainsFirstName || passwordContainsLastName) {
    return -50;
  }

  return 0;
}

export function calculateMobileScore(password: string, mobile: string): number {
  if (mobile && password.includes(mobile)) {
    return -50;
  }
  return 0;
}

export function calculatePasswordStrengthScore(
  password: string,
  firstName: string,
  lastName: string,
  mobile: string,
  email: string
): number {
  if (!password) {
    return 0;
  }

  let result: number = calculateBaseScore(password);

  result += calculateNumericalScore(password);
  result += calculateSpecialCharacterScore(password);
  result += calculateLowerAndUpperCaseScore(password);
  result += calculateAlphanumericScore(password);
  result += calculateNumericAndSpecialCharactersScore(password);
  result += calculateLettersAndSpecialCharactersScore(password);
  result += calculateRepetitiveCharactersScore(password);
  result += calculatedBlacklistedWordsScore(password);
  result += calculateEmailLocalPartScore(password, email);
  result += calculateFirstAndLastNameScore(password, firstName, lastName);
  result += calculateMobileScore(password, mobile);

  return Math.min(result, 100);
}

export enum PasswordStrength {
  Weak = "Weak",
  Moderate = "Moderate",
  Strong = "Strong",
}

export function evaluatePasswordStrength(score: number): PasswordStrength {
  if (score < 60) {
    return PasswordStrength.Weak;
  } else if (score < 80) {
    return PasswordStrength.Moderate;
  } else {
    return PasswordStrength.Strong;
  }
}
