import styled from "styled-components";
import { headers } from "../styles/RocketColours";
import { InputField } from "./InputField";

export const DateInputBase = styled(InputField)`
  color: ${(props) => {
    return !props.defaultValue || props.defaultValue === props.placeholder
      ? headers.midGrey
      : "inherit";
  }};
`;
