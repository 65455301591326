import React, { useEffect } from "react";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import Setting from "./ProfileSetting";
import * as features from "../../features";
import FirstNameProfileSetting from "./FirstNameProfileSetting";
import {
  customerProfilePageClosing,
  CustomerProfileState,
} from "../../store/customer-profile/CustomerProfileSlice";
import LastNameProfileSetting from "./LastNameProfileSetting";
import { DateOfBirthSetting } from "./DateOfBirthProfileSetting/DateOfBirthProfileSetting";
import { HomePhoneSetting } from "./HomePhoneProfileSetting/HomePhoneProfileSetting";
import { MobileNumberSetting } from "./MobileNumberProfileSetting/MobileNumberSetting";
import { useDispatch } from "react-redux";
export const passwordFillerLength: number = 8;

export const Settings = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

export interface ProfileSettingsProps {
  customerProfile: CustomerProfileState;
  allowNameEditing?: boolean;
  refactoredMobileField?: boolean;
}

function ProfileSettings({
  allowNameEditing = features.allowNameEditing,
  customerProfile,
}: ProfileSettingsProps) {
  const { profileFields } = customerProfile;
  const { firstName, lastName, email } = profileFields;
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(customerProfilePageClosing());
    },
    [dispatch]
  );

  return (
    <Settings>
      <FirstNameProfileSetting
        firstName={firstName}
        allowNameEditing={allowNameEditing}
      />
      <LastNameProfileSetting
        lastName={lastName}
        allowNameEditing={allowNameEditing}
      />
      <Setting name="Email address" value={email} linkEnabled={true} />
      <MobileNumberSetting />
      <HomePhoneSetting />
      <DateOfBirthSetting />
    </Settings>
  );
}
export default ProfileSettings;
