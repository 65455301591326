import styled from "styled-components";
import { setShowDeleteAccount } from "../../store/user-acceptances/UserAcceptancesSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ActionModal } from "./ActionModal";
import { sendProfileDeleteRequest } from "../../store/customer-profile/CustomerProfileSlice";

export const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

export function ActionModalContainer() {
  const dispatch = useDispatch();

  const { showDeleteAccount } = useSelector(
    (state: RootState) => state.userAcceptances
  );

  const closeModal = () => {
    dispatch(setShowDeleteAccount(false));
  };

  const deleteAccountRequest = () => {
    dispatch(sendProfileDeleteRequest());
  };

  const deleteHeader = `Delete account?`;
  const deleteBlurb = `Send us a request to delete your Coles Group account, which includes
  Coles and Coles Express. We'll delete your account within 10 business
  days. This cannot be undone.`;
  const deleteYesButton = `Send request`;
  const deleteNoButton = `No, keep account`;

  return (
    <>
      {showDeleteAccount && <BackgroundDiv />}
      <ActionModal
        onSuccess={() => deleteAccountRequest()}
        onRequestClose={() => closeModal()}
        isOpen={showDeleteAccount}
        shouldCloseOnEsc={true}
        header={deleteHeader}
        blurb={deleteBlurb}
        yesbutton={deleteYesButton}
        nobutton={deleteNoButton}
      />
    </>
  );
}
