import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PostcodeSuggestion } from "../../api/postcode/SearchPostcodeApi";

export interface AutoSuggestOption {
  value: any;
  label: string;
}

export interface AddEditPostcodeState {
  isPostcodeSaved: boolean;
  savePostcodeFailed: boolean;
  saveSuccessMessage: string;
  saveFailedMessage: string;
  matchingPostcodes: AutoSuggestOption[];
  searchPostcodeFailed: boolean;
}

export type PostcodeSearchActionPayload = {
  postcodeText: string;
};

export const initialState: AddEditPostcodeState = {
  isPostcodeSaved: false,
  savePostcodeFailed: false,
  saveSuccessMessage: "",
  saveFailedMessage: "",
  matchingPostcodes: [],
  searchPostcodeFailed: false,
};

const addEditPostcodeSlice = createSlice({
  name: "addEditPostcode",
  initialState,
  reducers: {
    savePostcodeSuccess: (state, action: PayloadAction<string>) => {
      state.isPostcodeSaved = true;
      state.saveSuccessMessage = action.payload;
      state.saveFailedMessage = "";
    },
    savePostcodeFailed: (state, action: PayloadAction<string>) => {
      state.isPostcodeSaved = false;
      state.savePostcodeFailed = true;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = action.payload;
    },
    clearPostcodeNotifications: (state) => {
      state.isPostcodeSaved = false;
      state.savePostcodeFailed = false;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = "";
    },
    setMatchingPostcodes: (
      state,
      { payload }: PayloadAction<PostcodeSuggestion[]>
    ) => {
      state.matchingPostcodes = payload.map((item) => {
        return {
          label: `${item.suburb}, ${item.state}, ${item.postcode}`,
          value: item,
        };
      });
      state.searchPostcodeFailed = false;
    },
    setSearchPostcodeFailed: (state, { payload }: PayloadAction<boolean>) => {
      state.searchPostcodeFailed = payload;
    },
    getMatchingPostcodeSuggestions: (
      state,
      action: PayloadAction<PostcodeSearchActionPayload>
    ) => {},
    resetState: (state) => {
      state.isPostcodeSaved = false;
      state.savePostcodeFailed = false;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = "";
      state.matchingPostcodes = [];
      state.searchPostcodeFailed = false;
    },
  },
});

export const {
  savePostcodeSuccess,
  savePostcodeFailed,
  setMatchingPostcodes,
  setSearchPostcodeFailed,
  getMatchingPostcodeSuggestions,
  clearPostcodeNotifications,
  resetState,
} = addEditPostcodeSlice.actions;

export const addEditPostcodeReducer = addEditPostcodeSlice.reducer;
