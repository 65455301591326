import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import {
  PreferredStore,
  PreferredStoreStatus,
} from "../../store/query-preferred-store/QueryPreferredStoreSlice";
import { RootState } from "../../store/Store";
import ReadonlyProfileSetting from "./ReadonlyProfileSetting";
import locationMarkerIcon from "../../assets/icons/location-marker.svg";
import warningIcon from "../../assets/icons/warning.svg";

const Container = styled.div`
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px;
  }
`;

const PreferredStoreProfileSetting = () => {
  const errorMessage =
    "Oops! Something went wrong when trying to load your preferred store. Please refresh this page or try again later.";
  const placeHolderText = "No preferred store added yet";
  const { displayAddress, status }: PreferredStore = useSelector(
    (state: RootState) => state.preferredStore
  );

  return (
    <Container>
      <ReadonlyProfileSetting
        name="Preferred store"
        displayValue={displayAddress}
        placeholderText={
          status === PreferredStoreStatus.NotFound ? placeHolderText : undefined
        }
        errorMessage={
          status === PreferredStoreStatus.Error ? errorMessage : undefined
        }
        iconSrc={
          status === PreferredStoreStatus.Error
            ? warningIcon
            : locationMarkerIcon
        }
        id="preferred-store"
      />
    </Container>
  );
};

export default PreferredStoreProfileSetting;
