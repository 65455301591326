import { HideableDiv, ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LinkButton = styled(ThemedButton)`
  margin-bottom: 12px;
  width: 300px;

  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export const StyledHideableDiv = styled(HideableDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
