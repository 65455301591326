import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  EditAddressRequest,
  SaveAddressRequest,
  DeleteAddressRequest,
} from "../../api/address/AddressesApi";

import { AddressSuggestion } from "../../api/address/SearchAddressApi";

import { CustomerProfileAddressFields } from "../customer-profile-address/CustomerProfileAddressSlice";

export interface AutoSuggestOption {
  id: string;
  label: string;
}

export interface AddAddressState {
  showAddAddressForm: boolean;
  showDeleteAddressModal: boolean;
  isAddingAddress: boolean;
  isAddressSaved: boolean;
  saveAddressFailed: boolean;
  saveSuccessMessage: string;
  saveFailedMessage: string;
  matchingAddresses: AutoSuggestOption[];
  searchAddressFailed: boolean;
  addressToBeDeleted: DeleteAddressRequest | null;
  addressToBeEdited: CustomerProfileAddressFields | null;
  isAddressesLimitReached: boolean;
}

export type AddressSearchActionPayload = {
  addressText: string;
};

export const initialState: AddAddressState = {
  showAddAddressForm: false,
  showDeleteAddressModal: false,
  isAddingAddress: false,
  isAddressSaved: false,
  saveAddressFailed: false,
  saveSuccessMessage: "",
  saveFailedMessage: "",
  matchingAddresses: [],
  searchAddressFailed: false,
  addressToBeDeleted: null,
  addressToBeEdited: null,
  isAddressesLimitReached: false,
};

const addAddressSlice = createSlice({
  name: "addAddress",
  initialState,
  reducers: {
    setFormVisibility: (state, { payload }: PayloadAction<boolean>) => {
      state.showAddAddressForm = payload;
    },
    setDeleteAddressModalVisibility: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.showDeleteAddressModal = payload;
    },
    saveAddress: (state, action: PayloadAction<SaveAddressRequest>) => {
      state.isAddingAddress = true;
      state.isAddressSaved = false;
    },
    editAddress: (state, action: PayloadAction<EditAddressRequest>) => {
      state.isAddingAddress = true;
      state.isAddressSaved = false;
    },
    deleteAddress: (state, action: PayloadAction<DeleteAddressRequest>) => {
      state.isAddingAddress = true;
      state.isAddressSaved = false;
    },
    setAddressTobeDeleted: (
      state,
      action: PayloadAction<DeleteAddressRequest | null>
    ) => {
      state.addressToBeDeleted = action.payload;
    },
    setAddressToBeEdited: (
      state,
      action: PayloadAction<CustomerProfileAddressFields | null>
    ) => {
      state.addressToBeEdited = action.payload;
    },
    saveAddressSuccess: (state, action: PayloadAction<string>) => {
      state.isAddingAddress = false;
      state.isAddressSaved = true;
      state.saveSuccessMessage = action.payload;
      state.saveFailedMessage = "";
      state.isAddressesLimitReached = false;
    },
    saveAddressFailed: (state, action: PayloadAction<string>) => {
      state.isAddingAddress = false;
      state.isAddressSaved = false;
      state.saveAddressFailed = true;
      state.isAddressesLimitReached = false;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = action.payload;
    },
    setAddressesLimitReached: (state, action: PayloadAction<boolean>) => {
      state.isAddingAddress = false;
      state.isAddressSaved = false;
      state.saveAddressFailed = true;
      state.saveSuccessMessage = "";
      state.isAddressesLimitReached = action.payload;
    },
    clearAddressNotifications: (state) => {
      state.isAddressSaved = false;
      state.saveAddressFailed = false;
      state.isAddingAddress = false;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = "";
    },
    setMatchingAddresses: (
      state,
      { payload }: PayloadAction<AddressSuggestion[]>
    ) => {
      state.matchingAddresses = payload.map((item) => {
        return {
          id: item.globalAddressKey,
          label: item.text,
        };
      });
      state.searchAddressFailed = false;
    },
    setSearchAddressFailed: (state, { payload }: PayloadAction<boolean>) => {
      state.searchAddressFailed = payload;
    },
    getMatchingAddressSuggestions: (
      state,
      action: PayloadAction<AddressSearchActionPayload>
    ) => {},
    resetState: (state) => {
      state.showAddAddressForm = false;
      state.showDeleteAddressModal = false;
      state.isAddingAddress = false;
      state.isAddressSaved = false;
      state.saveAddressFailed = false;
      state.isAddressesLimitReached = false;
      state.saveSuccessMessage = "";
      state.saveFailedMessage = "";
      state.matchingAddresses = [];
      state.searchAddressFailed = false;
    },
  },
});

export const {
  setFormVisibility,
  setDeleteAddressModalVisibility,
  saveAddress,
  editAddress,
  deleteAddress,
  setAddressTobeDeleted,
  setAddressToBeEdited,
  saveAddressSuccess,
  saveAddressFailed,
  setMatchingAddresses,
  setSearchAddressFailed,
  getMatchingAddressSuggestions,
  clearAddressNotifications,
  setAddressesLimitReached,
  resetState,
} = addAddressSlice.actions;

export const addAddressReducer = addAddressSlice.reducer;
