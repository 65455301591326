import { call } from "redux-saga/effects";
import { updatePassword, UpdatePasswordResponse } from "../../api/ServerApi";
import { put } from "redux-saga-test-plan/matchers";
import { updatePasswordErrorOccurred } from "./UpdatePasswordSlice";
import { updateIsLoadingUpdatePassword } from "../customer-profile/CustomerProfileSlice";
import { history } from "../../common/utils/history";
import { UpdatePasswordActionPayload } from "./UpdatePasswordActions";
import { PayloadAction } from "@reduxjs/toolkit";

export function* updatePasswordSaga(
  action: PayloadAction<UpdatePasswordActionPayload>
) {
  try {
    const response: UpdatePasswordResponse = yield call(
      updatePassword,
      action.payload.data
    );
    if (response.errors) {
      const passwordErrors = {
        currentIsInvalid: Boolean(response.errors.CurrentPassword),
        newIsInvalid: Boolean(response.errors.NewPassword),
      };
      yield put(updatePasswordErrorOccurred(passwordErrors));
    } else {
      yield call(routeToPasswordSuccessPage);
    }
  } catch {
    yield call(routeToPasswordFailedPage);
  } finally {
    yield put(updateIsLoadingUpdatePassword(false));
  }
}

export function* routeToPasswordSuccessPage() {
  yield history.push("/change-password/success");
}

export function* routeToPasswordFailedPage() {
  yield history.push("/change-password/failed");
}
