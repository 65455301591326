import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { updateCustomerHomePhone } from "../../../api/profile/CustomerProfileApi";
import { unableToUpdateHomePhoneErrorMessage } from "../../../common/constants/ErrorMessages";
import {
  updateHomePhoneFailed,
  updateHomePhoneSuccess,
} from "../../../store/customer-profile/CustomerProfileSlice";

export function* updateHomePhoneSaga(action: PayloadAction<string>) {
  try {
    const homePhone = action.payload;

    yield call(updateCustomerHomePhone, homePhone);
    yield put(updateHomePhoneSuccess(homePhone));
  } catch {
    yield put(updateHomePhoneFailed(unableToUpdateHomePhoneErrorMessage));
  }
}
