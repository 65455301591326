import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AppFooter from "../../common/AppFooter";
import { SubPageHeader } from "../styled/SubPageHeader";
import { TeamMemberNumber } from "./TeamMemberNumber";
import { invalidTeamMemberNumberErrorMessage } from "../../common/constants/ErrorMessages";

import { Link } from "ccp-common-ui-components";
import { RootState } from "../../store/Store";
import { removeAllSpaces } from "../profile/Utils/PhoneUtils";
import { Container } from "../styled/Container";
import { InputDiv } from "../styled/InputDiv";
import { LineLabel } from "../styled/LineLabel";
import { Layout } from "../styled/Layout";
import { PageLoader } from "../common/PageLoader";
import { useFeatureFlags } from "../../common/feature-flags/UseFeatureFlags";
import { linkTeamMemberNumberAction } from "../../store/team-member-number/TeamMemberNumberSlice";
import {
  LinkButton,
  LinkButtonContainer,
  StyledHideableDiv,
} from "./TeamMemberLinkPageStyles";

type FormData = {
  teamMemberNumber: string;
};

export function TeamMemberLinkPage() {
  const teamMemberNumberInputName = "teamMemberNumber";
  const { isBcpFeatureEnabled } = useFeatureFlags();

  const subtitleMessage = `Link mycoles card`;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<FormData>();

  const memberNumber = useSelector(
    (state: RootState) => state.teamMemberNumber
  );

  const isLoading = memberNumber.isSendteamMemberNumberLinkRequest;

  const onSubmit = (data: FormData) => {
    const sanitizedValue = removeAllSpaces(data.teamMemberNumber);
    const isValid = /^[0-9]{13}$/.test(sanitizedValue);
    if (!isValid) {
      return invalidTeamMemberNumberErrorMessage;
    }

    dispatch(
      linkTeamMemberNumberAction({
        memberId: data.teamMemberNumber,
      })
    );
  };

  const isValidTeamMemberNumber = (value: string): boolean | string => {
    const sanitizedValue = removeAllSpaces(value);
    const isValid = /^[0-9]{13}$/.test(sanitizedValue);
    if (!isValid) {
      return invalidTeamMemberNumberErrorMessage;
    }
    return isValid;
  };

  const isMemberNumberEmpty = (value: string) => {
    return !value;
  };

  const setTeamMemberNumberValue = (value: string) => {
    const sanitizedTeamMemberNumber = removeAllSpaces(value);
    const isNotEmpty = !isMemberNumberEmpty(sanitizedTeamMemberNumber);

    const shouldValidate = isSubmitted && isNotEmpty;
    setValue(teamMemberNumberInputName, sanitizedTeamMemberNumber, {
      shouldDirty: true,
      shouldValidate,
    });
    if (!shouldValidate) {
      clearErrors();
    }
  };

  return (
    <Layout>
      <SubPageHeader>{subtitleMessage}</SubPageHeader>
      <Container>
        <PageLoader isShown={isLoading} />
        <StyledHideableDiv isHidden={isLoading}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-edit-team-member-number-form"
            noValidate
          >
            <InputDiv>
              <LineLabel htmlFor="team-member-number">
                mycoles card number
              </LineLabel>
              <TeamMemberNumber
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                id="team-member-number"
                hasValidationError={!!errors.teamMemberNumber?.message}
                successfullyLinked={
                  memberNumber && memberNumber.isTeamMemberNumberLinkSuccess
                }
                {...register(teamMemberNumberInputName, {
                  validate: {
                    validTeamMemberNumber: (value) =>
                      isValidTeamMemberNumber(value),
                  },
                  onChange: (e: any) => {
                    setTeamMemberNumberValue(e.target.value);
                  },
                })}
              />
            </InputDiv>
            <LinkButtonContainer>
              {!isBcpFeatureEnabled && (
                <LinkButton data-testid="save-button" type="submit">
                  Link card
                </LinkButton>
              )}
              <Link href="/">Cancel</Link>
            </LinkButtonContainer>
          </form>
        </StyledHideableDiv>
      </Container>
      <AppFooter />
    </Layout>
  );
}
