import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";

import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import { RootState } from "../Store";

import {
  PostcodeSearchActionPayload,
  setMatchingPostcodes,
  setSearchPostcodeFailed,
} from "./AddEditPostcodeSlice";
import {
  QueryPostcodeSearchResponse,
  queryPostcodeSearch,
} from "../../api/postcode/SearchPostcodeApi";
import {
  PostcodeUpdateRequest,
  updateCustomerPostcode,
} from "../../api/profile/CustomerProfileApi";
import {
  PostcodeFields,
  updatePostcodeFailed,
  updatePostcodeSuccess,
} from "../customer-profile/CustomerProfileSlice";
import { unableToUpdatePostcodeErrorMessage } from "../../common/constants/ErrorMessages";

export function* getMatchingPostcodeSuggestionsAsync(
  action: PayloadAction<PostcodeSearchActionPayload>
) {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      const postcodeSearchText = action.payload.postcodeText;

      const response: QueryPostcodeSearchResponse = yield call(
        queryPostcodeSearch,
        postcodeSearchText
      );
      yield put(setMatchingPostcodes(response.suggestions));
    } catch (e: any) {
      yield put(setSearchPostcodeFailed(true));
    }
  }
}

export function* updatePostcodeSaga(action: PayloadAction<PostcodeFields>) {
  try {
    const updatedPostcodeFields = action.payload;

    const postcodeUpdateRequest: PostcodeUpdateRequest = {
      suburb: updatedPostcodeFields.suburb,
      state: updatedPostcodeFields.state,
      postcode: updatedPostcodeFields.postcode,
    };
    yield call(updateCustomerPostcode, postcodeUpdateRequest);

    yield put(updatePostcodeSuccess(updatedPostcodeFields));
  } catch {
    yield put(updatePostcodeFailed(unableToUpdatePostcodeErrorMessage));
  }
}
