import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select } from "redux-saga/effects";
import {
  LoyaltyAccountsResponse,
  queryLoyaltyAccounts,
} from "../../api/loyaltyAccounts/LoyaltyAccountsApiClient";
import { AuthenticationStatus } from "../check-authentication/CheckAuthenticationSlice";
import { RootState } from "../Store";
import {
  GetLoyaltyAccountsPayload,
  storeLoyaltyAccounts,
  updateIsLoadingLoyaltyAccounts,
  updateLoyaltyAccountsError,
} from "./LoyaltyAccountsSlice";
import { redirect } from "../../common/utils/NavigationHelpers";
import { routeToReturnToPage } from "../flybuys/FlybuysSaga";
import { setFlybuysRouteSuccess } from "../flybuys/FlybuysSlice";
import { history } from "../../common/utils/history";

export function* getLoyaltyAccountsAsync(
  action: PayloadAction<GetLoyaltyAccountsPayload>
) {
  const status: AuthenticationStatus = yield select(
    (state: RootState) => state.authentication.status
  );
  if (status === AuthenticationStatus.Authenticated) {
    try {
      yield put(updateIsLoadingLoyaltyAccounts(true));
      const response: LoyaltyAccountsResponse = yield call(
        queryLoyaltyAccounts,
        action.payload.returnTo,
        action.payload.action
      );

      yield put(storeLoyaltyAccounts(response));
      yield put(updateIsLoadingLoyaltyAccounts(false));
      yield put(setFlybuysRouteSuccess(true));

      if (
        history.location.pathname === "/integration-flybuys" &&
        response.flybuys.linkFlybuysUrl
      ) {
        yield call(redirect, response.flybuys.linkFlybuysUrl);
        return;
      }

      if (
        response.flybuys.hasError &&
        history.location.pathname === "/integration-flybuys"
      ) {
        yield call(
          routeToReturnToPage,
          decodeURIComponent(action.payload.returnTo || "")
        );
        return;
      }

      if (
        action.payload.returnTo &&
        history.location.pathname === "/integration-flybuys"
      ) {
        yield call(redirect, decodeURIComponent(action.payload.returnTo));
      }
    } catch {
      yield put(updateLoyaltyAccountsError(true));
      yield put(updateIsLoadingLoyaltyAccounts(false));
      yield put(setFlybuysRouteSuccess(false));
      if (history.location.pathname === "/integration-flybuys") {
        yield call(routeToErrorPage);
      }
    }
  }
}

export function* routeToErrorPage() {
  yield history.push(`/integration-flybuys/error`);
}
