import { ErrorText } from "ccp-common-ui-components";
import { InputFieldProps } from "ccp-common-ui-components/dist/inputs/InputField";
import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import styled from "styled-components";
import { InputField } from "../../common/form/InputField";
import { MessageContainer } from "../styled/MessageContainer";
import { screenSize } from "../../common/styles/ScreenSizes";

export type OneTimeCodeInputProps = InputFieldProps &
  React.InputHTMLAttributes<HTMLInputElement>;

type EditableOneTimeCodeProps = OneTimeCodeInputProps & {
  setMobileNumber?: (value: string) => void;
  errorMessage?: string;
} & UseFormRegisterReturn;

const OtcInputField = styled(InputField)`
  width: 105px;
`;

const EditableOneTimeCodeContainer = styled.div`
  flex: 1 1 auto;
  order: 2;
  @media ${screenSize.mobile} {
    order: 3;
  }
`;

export const EditableOneTimeCode = React.forwardRef(
  (
    props: OneTimeCodeInputProps & EditableOneTimeCodeProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const hasError = !!props.errorMessage;
    const errorTextId = "editable-one-time-code-input-error";
    return (
      <EditableOneTimeCodeContainer data-testid="editable-one-time-code">
        <OtcInputField
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          id="otc-input"
          data-testid="editable-one-time-code-input"
          hasError={hasError}
          displaySuccessIcon={false}
          type="tel"
          aria-required="true"
          aria-invalid={hasError}
          aria-describedby={errorTextId}
          {...props}
          ref={ref}
        />
        {hasError && (
          <MessageContainer>
            <ErrorText id={errorTextId} role="alert" data-testid="error-text">
              {props.errorMessage}
            </ErrorText>
          </MessageContainer>
        )}
      </EditableOneTimeCodeContainer>
    );
  }
);
