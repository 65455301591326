import { useEffect } from "react";
import configuration from "../../config/Configuration";

export function LogoutRedirect() {
  const logoutUrl = configuration.serverUrl + "/api/v1/auth/logout";
  useEffect(() => {
    window.location.assign(logoutUrl);
  }, [logoutUrl]);
  return <></>;
}
