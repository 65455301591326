import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";

export const useIsBusinessCustomer = () => {
  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );
  return customerProfileType?.toLowerCase() === "business";
};

export const useIsPersonalCustomer = () => {
  const customerProfileType = useSelector(
    (state: RootState) => state.customerProfile.profileFields.profileType
  );
  return customerProfileType?.toLowerCase() === "personal";
};
