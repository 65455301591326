import { VerificationChannel } from "../../store/verify-mobile-number/VerifyMobileNumberSlice";
import appConfig from "../../config/Configuration";
import { loginFetch } from "../LoginFetch";
import { defaultRequestConfig } from "../ServerApi";

const mobileVerificationBaseRoute = `${appConfig.serverUrl}/api/v1/verifymobile`;
const sendCodeRoute = "/sendcode";
const verifyCodeRoute = "/verifycode";

export interface MobileVerificationSendCodeRequest {
  mobile: string;
}

export interface MobileVerificationSendCodeResponse extends Response {
  data: {
    sentTo: string;
    channel: VerificationChannel;
  };
}

export interface MobileVerificationVerifyCodeRequest {
  verificationCode: string;
  channel?: VerificationChannel;
  mfaPreference: string;
}

export interface MobileVerificationVerifyCodeResponse extends Response {
  data: {
    verified: boolean;
  };
}

export type MobileVerificationErrorCode =
  | "TOO_MANY_SEND_CODE_REQUESTS"
  | "TOO_MANY_VERIFY_CODE_REQUESTS";

export interface MobileVerificationErrorResponseData {
  code: MobileVerificationErrorCode;
}

export interface MobileVerificationErrorResponse {
  error: MobileVerificationErrorResponseData;
}

interface IMobileVerificationError {
  data?: MobileVerificationErrorResponseData;
}

export class MobileVerificationError
  extends Error
  implements IMobileVerificationError
{
  data?: MobileVerificationErrorResponseData;
  constructor({ data }: IMobileVerificationError) {
    super();
    this.data = data;
  }
}

export async function sendCode(
  request: MobileVerificationSendCodeRequest
): Promise<MobileVerificationSendCodeResponse> {
  const route = mobileVerificationBaseRoute + sendCodeRoute;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(request),
  });

  const responseBody = await response.json();
  if (!response.ok) {
    const errorResponseBody = responseBody as MobileVerificationErrorResponse;
    const errorObject = new MobileVerificationError({
      data: errorResponseBody.error,
    });
    throw errorObject;
  }

  return { ...responseBody };
}

export async function verifyCode(
  request: MobileVerificationVerifyCodeRequest
): Promise<MobileVerificationVerifyCodeResponse> {
  const route = mobileVerificationBaseRoute + verifyCodeRoute;
  const response = await loginFetch(route, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify(request),
  });

  const responseBody = await response.json();

  if (!response.ok) {
    const errorResponseBody = responseBody as MobileVerificationErrorResponse;
    const errorObject = new MobileVerificationError({
      data: errorResponseBody.error,
    });
    throw errorObject;
  }

  return { ...responseBody };
}
