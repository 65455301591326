import styled from "styled-components";
import { MessageContainer } from "../styled/MessageContainer";
import { headers } from "../../common/styles/RocketColours";
import { screenSize } from "../../common/styles/ScreenSizes";

const SettingLine = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 32px 18px;

  @media ${screenSize.mobile} {
    flex-flow: row wrap;
    height: auto;
    padding: 24px 18px 28px 18px;
  }
`;

const SettingName = styled.label`
  flex: 0 0 14em;
  order: 1;
  font-family: "Source Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  align-self: baseline;

  @media ${screenSize.mobile} {
    flex: 1 1 100%;
  }
`;

const SettingValue = styled.div<{ isPlaceholder?: boolean }>`
  display: flex;
  order: 2;
  color: ${(props) => (props.isPlaceholder ? "#414240" : headers.black900)};
  font-family: "Source Sans";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;

  @media ${screenSize.mobile} {
    order: 4;
  }
`;

const NoMessage = styled.span``;

const ValueMessageContainer = styled.div`
  order: 2;
  display: flex;

  @media ${screenSize.mobile} {
    order: 3;
    padding: 16px 0 0 0;
  }

  @media ${screenSize.tablet} {
    order: 3;
  }
`;

const Icon = styled.img`
  padding: 0 10px 0 0;
  align-self: baseline;

  @media ${screenSize.mobile} {
    padding: 0 8px 0 0;
  }
`;

export interface ReadonlyProfileSettingProps {
  readonly name: string;
  readonly displayValue: string;
  readonly placeholderText?: string;
  readonly errorMessage?: string;
  readonly iconSrc?: string;
  readonly id?: string;
}

function ReadonlyProfileSetting(props: ReadonlyProfileSettingProps) {
  return (
    <>
      <SettingLine>
        <SettingName>{props.name}:</SettingName>
        <ValueMessageContainer>
          {!(!props.errorMessage || !props.placeholderText) ? undefined : (
            <Icon src={props.iconSrc} role="presentation" alt="" aria-hidden />
          )}
          <SettingValue
            id={`${props.id}-value`}
            role={props.errorMessage ? "alert" : undefined}
            aria-live={props.errorMessage ? "polite" : undefined}
            isPlaceholder={Boolean(props.placeholderText)}
          >
            {props.errorMessage || props.placeholderText || props.displayValue}
          </SettingValue>
          <MessageContainer>
            <NoMessage />
          </MessageContainer>
        </ValueMessageContainer>
      </SettingLine>
    </>
  );
}

export default ReadonlyProfileSetting;
