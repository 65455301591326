import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";
import Setting from "./ProfileSetting";
import { Link } from "react-router-dom";
import { MarketingPreference } from "../../store/customer-profile-preferences/CustomerProfilePreferencesSlice";
import { groupMarketingPreferences } from "../../store/customer-profile-preferences/CustomerProfilePreferencesUtils";

import editIcon from "../../assets/icons/edit.svg";
import colesExpressLogo from "../../assets/logos/coles-express-logo.svg";
import colesSupermarketsLogo from "../../assets/logos/coles-logo.svg";

const EditIcon = styled.img`
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 3px 0 0;
`;

const Settings = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  background-color: #ffffff;
  border-radius: 3px;
  border-top: 1px #c7c7c7 solid;
  box-shadow: 0 2px 5px 0 rgba(182, 182, 182, 0.5);
  margin: 0 0 12px 0;

  @media ${screenSize.mobile} {
    margin: 0 -24px 12px -24px;
  }
`;

const EditLink = styled(Link)`
  display: flex;
`;

const PreferenceLogo = styled.img`
  max-height: 22px;
`;

export interface MarketingPreferencesProps {
  preferences: MarketingPreference[];
}

const getBrandSpecificLogo = (brand: string) => {
  return () => {
    switch (brand) {
      case "cexp":
        return <PreferenceLogo alt={brand} src={colesExpressLogo} />;
      case "supermarket":
        return <PreferenceLogo alt={brand} src={colesSupermarketsLogo} />;
      default:
        return <PreferenceLogo alt={brand} src={colesSupermarketsLogo} />;
    }
  };
};

const getBrandSpecificDescriptor = (brand: string) => {
  switch (brand) {
    case "cexp":
      return "Coles Express";
    case "supermarket":
      return "Coles Supermarkets";
    default:
      return "Coles Supermarkets";
  }
};

const MarketingPreferences = (props: MarketingPreferencesProps) => {
  const groupedPreferences = groupMarketingPreferences(props.preferences);

  return (
    <Settings>
      {groupedPreferences &&
        groupedPreferences.length &&
        groupedPreferences.length > 0 &&
        groupedPreferences.map((pref) => (
          <Setting
            key={pref.brand}
            name={pref.brand}
            nameComponent={getBrandSpecificLogo(pref.brand)}
            value={`Manage your marketing preferences for ${getBrandSpecificDescriptor(
              pref.brand
            )}`}
            link={
              <EditLink to={`/marketing-preferences/${pref.brand}`}>
                <EditIcon
                  data-testid="edit-icon"
                  src={editIcon}
                  alt=""
                  aria-hidden
                />
                Edit preferences
              </EditLink>
            }
            linkEnabled={true}
          />
        ))}
    </Settings>
  );
};

export default MarketingPreferences;
