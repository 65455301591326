import { useDispatch, useSelector } from "react-redux";
import { TermsAndConditionsModal } from "ccp-common-ui-components";
import { RootState } from "../../store/Store";
import { setShowTermsAndConditions } from "../../store/user-acceptances/UserAcceptancesSlice";

export function TermsAndConditionsModalContainer() {
  const dispatch = useDispatch();

  const { showTermsAndConditions } = useSelector(
    (state: RootState) => state.userAcceptances
  );

  const closeModal = () => {
    dispatch(setShowTermsAndConditions(false));
  };

  return (
    <TermsAndConditionsModal
      isOpen={showTermsAndConditions}
      shouldCloseOnEsc={true}
      onRequestClose={() => closeModal()}
    />
  );
}
