import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { history } from "../../common/utils/history";
import {
  FlybuysVerifyResponse,
  unlinkLoyaltyAccountNumber,
  verifyFlybuysAccountNumber,
} from "../../api/loyaltyAccounts/LoyaltyAccountsApiClient";
import {
  setFlybuysUnlinkSuccess,
  setIsFlybuysLinkSuccess,
  setReturnToRoute,
  UnlinkFlybuysActionPayload,
  updateIsFlybuysUnlinkRequestLoading,
  VerifyFlybuysActionPayload,
} from "./FlybuysSlice";
import { getFlybuysVerifyReturnTo } from "./getFlybuysVerifyReturnTo";
import { getLoyaltyAccountsAction } from "../loyalty-accounts/LoyaltyAccountsActions";
import { redirect } from "../../common/utils/NavigationHelpers";
import configuration from "../../config/Configuration";

export function* unlinkFlybuysSaga(
  action: PayloadAction<UnlinkFlybuysActionPayload>
) {
  try {
    yield call(unlinkLoyaltyAccountNumber, action.payload.loyaltyAccountId);
    yield put(updateIsFlybuysUnlinkRequestLoading(false));
    yield put(setFlybuysUnlinkSuccess(true));
    yield put(getLoyaltyAccountsAction({}));
    yield call(redirect, configuration.flybuysLogoutUrl);
  } catch (error) {
    yield put(setFlybuysUnlinkSuccess(false));
  }
}

export function* verifyFlybuysAccountSaga(
  action: PayloadAction<VerifyFlybuysActionPayload>
) {
  try {
    const response: FlybuysVerifyResponse = yield call(
      verifyFlybuysAccountNumber,
      action.payload.code,
      action.payload.state
    );

    yield put(setReturnToRoute(response.returnTo || ""));
    yield put(setIsFlybuysLinkSuccess(true));
  } catch (error) {
    const returnToUrl = getFlybuysVerifyReturnTo(error);
    yield put(setReturnToRoute(returnToUrl));
    yield put(setIsFlybuysLinkSuccess(false));
  }
}

export function* routeToReturnToPage(returnTo: string) {
  yield history.push(`/integration-flybuys/error?returnTo=${returnTo}`);
}

export function* routeToErrorPage() {
  yield history.push(`/integration-flybuys/error`);
}
