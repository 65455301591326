import styled from "styled-components";
import { SettingLine } from "../styled/SettingLine";
import { SettingInput } from "../styled/SettingInput";
import { SettingName } from "../styled/SettingName";
import { MessageContainer } from "../styled/MessageContainer";
import { SpinnerContainer } from "../styled/SpinnerContainer";
import { SettingButton } from "../styled/SettingButton";
import { SettingValue } from "../styled/SettingValue";
import { SettingLink } from "../styled/SettingLink";
import { screenSize } from "../../common/styles/ScreenSizes";
import { Ref } from "react";

const NoMessage = styled.span``;
const ValueMessageContainer = styled.div`
  order: 2;
  flex: 1 1 auto;
  padding-right: 20px;

  @media ${screenSize.mobile} {
    order: 3;
    flex: 0 0 100%;
  }

  @media ${screenSize.tablet} {
    order: 3;
  }
`;

export interface SettingProps {
  readonly name: string;
  readonly value: string | JSX.Element[];
  readonly link?: string | React.ReactNode;
  readonly handleClick?: (event: SettingClickEvent) => void;
  readonly handleChange?: (event: SettingChangeEvent) => void;
  readonly status?: SettingFieldStatus;
  readonly id?: string;
  readonly linkEnabled: boolean;
  readonly MessageComponent?: React.FunctionComponent;
  readonly SpinnerComponent?: React.FunctionComponent;
  readonly editButtonRef?: React.Ref<HTMLButtonElement>;
  readonly inputRef?: Ref<HTMLInputElement>;
  readonly nameComponent?: React.FunctionComponent;
}

export type SettingClickEvent = React.MouseEvent;
export type SettingChangeEvent = React.ChangeEvent<HTMLInputElement>;
export enum SettingFieldStatus {
  Readable,
  Editable,
  PendingUpdate,
}

function ProfileSetting(props: SettingProps) {
  const settingProps: {
    htmlFor?: string;
    id?: string;
    error?: string | null;
  } = {};
  if (props.status === SettingFieldStatus.Editable) {
    settingProps.htmlFor = settingProps.id = props.id;
  }

  const handleClick = (event: SettingClickEvent) => {
    if (props.handleClick) {
      props.handleClick(event);
    }
  };

  const settingValue =
    props.status === SettingFieldStatus.Editable ? (
      <SettingInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        ref={props.inputRef}
        id={settingProps.id}
        onChange={props.handleChange}
        value={props.value as string}
        type="text"
        aria-invalid={props.linkEnabled ? "false" : "true"}
        aria-describedby={`${props.id}-error`}
        hasError={!props.linkEnabled}
        displaySuccessIcon={false}
        required
      />
    ) : (
      <SettingValue id={`${props.id}-value`}>{props.value}</SettingValue>
    );

  const settingLink =
    props.link && typeof props.link === "string" ? (
      <SettingButton
        ref={props.editButtonRef}
        id={`${props.id}-button`}
        onClick={handleClick}
        withIcon={props.status !== SettingFieldStatus.Editable}
        disabled={!props.linkEnabled}
      >
        {props.link}
      </SettingButton>
    ) : (
      <SettingLink>{props.link}</SettingLink>
    );

  const MessageComponent = props.MessageComponent || NoMessage;
  const SpinnerComponent = props.SpinnerComponent;
  const NameComponent = props.nameComponent;

  return (
    <>
      <SettingLine>
        {NameComponent ? (
          <SettingName htmlFor={settingProps.htmlFor}>
            <NameComponent />
          </SettingName>
        ) : (
          <SettingName htmlFor={settingProps.htmlFor}>
            {props.name}:
          </SettingName>
        )}
        <ValueMessageContainer>
          {settingValue}
          <MessageContainer>
            <MessageComponent />
          </MessageContainer>
        </ValueMessageContainer>
        <SpinnerContainer>
          {SpinnerComponent ? <SpinnerComponent /> : settingLink}
        </SpinnerContainer>
      </SettingLine>
    </>
  );
}

export default ProfileSetting;
