import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const MessageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  order: 5;
  max-width: fit-content;

  @media ${screenSize.mobile} {
    max-width: none;
  }
`;
