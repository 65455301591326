import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { updatePasswordAction } from "../../store/update-password/UpdatePasswordActions";
import {
  currentPasswordChanged,
  newPasswordChanged,
} from "../../store/update-password/UpdatePasswordSlice";
import { RootState } from "../../store/Store";
import { PasswordStrengthChecker } from "../../common/password-strength-checker/PasswordStrengthChecker";
import {
  setUserUpdatesPasswordFlag,
  updateIsLoadingUpdatePassword,
} from "../../store/customer-profile/CustomerProfileSlice";
import AppFooter from "../../common/AppFooter";
import ThemedPasswordInput from "./ThemedPasswordInput";
import {
  newPasswordMatches,
  validateNewPassword,
} from "./validation/validation";
import { Layout } from "../styled/Layout";
import { InputDiv } from "../styled/InputDiv";
import { Password } from "../../api/ServerApi";
import { HideableDiv, ThemedButton } from "ccp-common-ui-components";
import { PageLoader } from "../common/PageLoader";
import {
  ChangePasswordContainer,
  RequiredFieldsText,
  ThemedInputLabel,
  ThemedErrorText,
  CancelLink,
  ButtonsContainer,
} from "./ChangePasswordFormStyles";

interface ChangePasswordFormProps {
  onCancel(): void;
}

export function ChangePasswordForm({ onCancel }: ChangePasswordFormProps) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
    watch,
  } = useForm<Password>();

  const [userTriedToSubmit, setUserTriedToSubmit] = React.useState(false);

  function revalidate(
    name: "currentPassword" | "newPassword" | "confirmNewPassword"
  ) {
    if (userTriedToSubmit) {
      trigger(name);
    }
  }

  const onSubmit = (data: Password) => {
    setUserTriedToSubmit(true);
    dispatch(updateIsLoadingUpdatePassword(true));
    dispatch(setUserUpdatesPasswordFlag(true));
    dispatch(
      updatePasswordAction({
        data,
      })
    );
  };
  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );
  const passwords = useSelector((state: RootState) => state.updatePassword);
  const isLoadingUpdatePassword = customerProfile.isLoadingUpdatePassword;

  const currentPasswordFormError =
    errors.currentPassword &&
    (errors.currentPassword.message
      ? errors.currentPassword.message
      : "Current password is required");

  const newPasswordFormError =
    errors.newPassword &&
    (errors.newPassword.message
      ? errors.newPassword.message
      : "New password is required");

  const newPasswordValue = watch("newPassword");
  const confirmNewPasswordValue = watch("confirmNewPassword");

  const newPasswordsMatch = () => {
    if (!newPasswordValue || !confirmNewPasswordValue) {
      return false;
    }
    return newPasswordValue === confirmNewPasswordValue;
  };

  const currentPasswordHasErrors = () => {
    return !!errors.currentPassword || !!passwords.currentPasswordError;
  };

  const newPasswordHasErrors = () => {
    return !!errors.newPassword || !!passwords.newPasswordError;
  };

  const confirmNewPasswordHasErrors = () => {
    return !!errors.confirmNewPassword;
  };

  const newPasswordDisplaySuccessIcon = () => {
    return newPasswordsMatch() && !newPasswordHasErrors();
  };

  const confirmNewPasswordDisplaySuccessIcon = () => {
    return (
      newPasswordsMatch() &&
      !newPasswordHasErrors() &&
      !confirmNewPasswordHasErrors()
    );
  };

  return (
    <Layout>
      <ChangePasswordContainer>
        <PageLoader isShown={isLoadingUpdatePassword} />
        <HideableDiv isHidden={isLoadingUpdatePassword} data-testid="hideable">
          <RequiredFieldsText role="status">
            All fields are required unless marked as optional
          </RequiredFieldsText>
          <form
            id="change-password-form"
            data-testid="change-password-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <div>
              <InputDiv>
                <ThemedInputLabel
                  htmlFor="CurrentPassword"
                  hasError={currentPasswordHasErrors()}
                >
                  Current password:
                </ThemedInputLabel>
                <ThemedPasswordInput
                  id="CurrentPassword"
                  aria-invalid={!!errors.currentPassword}
                  aria-describedby="CurrentPassword-error"
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  data-testid="CurrentPassword-input"
                  {...register("currentPassword", {
                    required: true,
                    onChange: (e) => {
                      revalidate("currentPassword");
                      dispatch(currentPasswordChanged());
                    },
                  })}
                  hasError={currentPasswordHasErrors()}
                  displaySuccessIcon={false}
                />
                {(errors.currentPassword || passwords.currentPasswordError) && (
                  <ThemedErrorText id="CurrentPassword-error">
                    {currentPasswordFormError}
                    {passwords.currentPasswordError}
                  </ThemedErrorText>
                )}
              </InputDiv>

              <InputDiv>
                <ThemedInputLabel
                  htmlFor="NewPassword"
                  hasError={newPasswordHasErrors()}
                >
                  New password:
                </ThemedInputLabel>
                <ThemedPasswordInput
                  id="NewPassword"
                  aria-invalid={!!errors.newPassword}
                  aria-describedby="NewPassword-error"
                  data-testid="NewPassword-input"
                  {...register("newPassword", {
                    required: true,
                    validate: (newPassword) =>
                      validateNewPassword(
                        newPassword,
                        customerProfile.profileFields.firstName,
                        customerProfile.profileFields.lastName,
                        customerProfile.profileFields.mobile,
                        customerProfile.profileFields.email
                      ),
                    onChange: (e) => {
                      revalidate("newPassword");
                      dispatch(newPasswordChanged());
                    },
                  })}
                  hasError={newPasswordHasErrors()}
                  displaySuccessIcon={newPasswordDisplaySuccessIcon()}
                />

                {(errors.newPassword || passwords.newPasswordError) && (
                  <ThemedErrorText id="NewPassword-error">
                    {newPasswordFormError}
                    {passwords.newPasswordError}
                  </ThemedErrorText>
                )}
              </InputDiv>

              <InputDiv>
                <ThemedInputLabel
                  htmlFor="ConfirmNewPassword"
                  hasError={confirmNewPasswordHasErrors()}
                >
                  Confirm new password:
                </ThemedInputLabel>
                <ThemedPasswordInput
                  id="ConfirmNewPassword"
                  aria-invalid={confirmNewPasswordHasErrors()}
                  aria-describedby="ConfirmNewPassword-error"
                  data-testid="ConfirmNewPassword-input"
                  {...register("confirmNewPassword", {
                    required: true,
                    validate: (confirmNewPassword: string) =>
                      newPasswordMatches(newPasswordValue, confirmNewPassword),
                    onChange: () => revalidate("confirmNewPassword"),
                  })}
                  hasError={confirmNewPasswordHasErrors()}
                  displaySuccessIcon={confirmNewPasswordDisplaySuccessIcon()}
                />
                {errors.confirmNewPassword && (
                  <ThemedErrorText id="ConfirmNewPassword-error">
                    {errors.confirmNewPassword.message
                      ? errors.confirmNewPassword.message
                      : "Confirm new password is required"}
                  </ThemedErrorText>
                )}
              </InputDiv>
            </div>
            <div>
              <span>
                Your new password must contain at least 8 characters and one
                number
              </span>

              <PasswordStrengthChecker
                password={newPasswordValue}
                firstName={customerProfile.profileFields.firstName}
                lastName={customerProfile.profileFields.lastName}
                mobile={customerProfile.profileFields.mobile}
                email={customerProfile.profileFields.email}
              />
            </div>
            <ButtonsContainer>
              <ThemedButton>Save password</ThemedButton>
              <CancelLink tabIndex={0} onClick={onCancel}>
                Cancel
              </CancelLink>
            </ButtonsContainer>
          </form>
        </HideableDiv>
      </ChangePasswordContainer>
      <AppFooter />
    </Layout>
  );
}
