export const invalidDateOfBirthErrorMessage =
  "Please enter a valid date of birth in the format DD/MM/YYYY.";
export const invalidHomePhoneErrorMessage =
  "Please enter a valid home number. For example: 03 1111 2222";
export const invalidMobileNumberErrorMessage =
  "Please enter a valid mobile number that begins with digits 0 and 4. For example: 0411 222 333";
export const unableToUpdateHomePhoneErrorMessage =
  "We weren’t able to update your home phone. Please try again later.";
export const oneTimeCodeIsRequiredErrorMessage = "One time code is required.";
export const invalidOneTimeCodeErrorMessage =
  "The one time code should be 6 numbers. Check code and try again.";
export const oneTimeCodeVerificationFailedMessage =
  "The code you have entered is incorrect. Please check the code and try again.";
export const unableToUpdateMfaPreferenceErrorMessage =
  "We weren’t able to update your security preference. Please try again later.";
export const invalidTeamMemberNumberErrorMessage =
  "Please enter a valid mycoles card number";
export const optionIsRequiredErrorMessage = "Please select an option.";
export const unableToUpdatePostcodeErrorMessage =
  "We weren’t able to update your postcode. Please try again later.";
