import appConfig from "../../config/Configuration";
import { defaultRequestConfig } from "../ServerApi";
import { loginFetch } from "../LoginFetch";

const postcodeSearchUrl = appConfig.serverUrl + "/api/v1/localities/search";

export interface QueryPostcodeSearchResponse {
  suggestions: PostcodeSuggestion[];
}
export interface PostcodeSuggestion {
  suburb: string;
  state: string;
  postcode: string;
}

export async function queryPostcodeSearch(
  searchTerm: string
): Promise<QueryPostcodeSearchResponse> {
  const response = await loginFetch(postcodeSearchUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify({ searchTerm }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}
