import { Fragment, useEffect, useRef } from "react";
import { Layout } from "../../styled/Layout";
import AppFooter from "../../../common/AppFooter";
import { RadioButton } from "./RadioButton";
import { RouterLink } from "../../styled/RouterLink";
import { RootState } from "../../../store/Store";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { mfaPreferences } from "./mfaPreferences";
import { validateMfaPreference } from "./validateMfaPreference";
import { startMfaPreferenceFlow } from "../../../store/verify-mobile-number/VerifyMobileNumberSlice";
import {
  cancelMfaPreferenceUpdate,
  updateMfaPreference,
} from "../../../store/customer-profile/CustomerProfileSlice";
import { ThemedButton, HideableDiv } from "ccp-common-ui-components";
import { SubPageHeader } from "../../styled/SubPageHeader";
import {
  ChangeMfaContainer,
  Description,
  RadioButtonError,
  RadioButtonGroup,
  StyledUpdateFailureMessage,
  Buttons,
  CancelLink,
} from "./MfaPreferenceStyles";
import { MfaPreferenceSuccessPage } from "./MfaPreferenceSuccessPage";
import { PageLoader } from "../../common/PageLoader";
import { MfaPreferenceUpdateFailedBanner } from "./MfaPreferenceUpdateFailedBanner";
import { getCustomerProfileAction } from "../../../store/customer-profile/CustomerProfileActions";
import { getReturnToLink } from "../../../common/utils/RedirectionHelpers";

const errorMessage =
  "You must have a verified mobile number to enable security features.";

const inputName = "mfaPreference";

type FormData = {
  mfaPreference: string;
};

const MfaPreferencePage = () => {
  const radioGroupRef = useRef<any>(null);
  const editButtonRef = useRef<HTMLButtonElement>(null);

  const customerProfile = useSelector(
    (state: RootState) => state.customerProfile
  );

  const { isLoadingCustomerProfile } = customerProfile;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProfileAction());
  }, [dispatch]);

  const { isUpdatingMfaPreference, isMfaPreferenceUpdated } = useSelector(
    (state: RootState) => state.customerProfile
  );

  const { mfaPreference, isMobileVerified } = useSelector(
    (state: RootState) => state.customerProfile.profileFields
  );

  const showLoader = isUpdatingMfaPreference || isLoadingCustomerProfile;

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    shouldFocusError: false,
  });

  useEffect(() => {
    if (mfaPreference) {
      reset();
    }
  }, [mfaPreference, reset]);

  const onSubmit = (data: FormData) => {
    const isMfaPreferenceEmpty = !data.mfaPreference;
    const isMfaPreferenceUnchanged =
      !isMfaPreferenceEmpty && data.mfaPreference === mfaPreference;

    if (isMfaPreferenceUnchanged) {
      return;
    }

    if (isMfaPreferenceEmpty) {
      dispatch(cancelMfaPreferenceUpdate());
    } else {
      dispatch(updateMfaPreference(data.mfaPreference));
    }
    editButtonRef.current?.focus();
  };

  return (
    <Layout>
      <SubPageHeader>Change my security preference</SubPageHeader>
      <PageLoader isShown={showLoader} />
      <MfaPreferenceUpdateFailedBanner />
      <HideableDiv isHidden={showLoader}>
        {isMfaPreferenceUpdated ? (
          <MfaPreferenceSuccessPage />
        ) : (
          <ChangeMfaContainer>
            <Description>This field is mandatory</Description>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <RadioButtonGroup
                tabIndex={-1}
                ref={radioGroupRef}
                role="radiogroup"
                aria-labelledby="radio-group-label"
              >
                {mfaPreferences.map((p) => {
                  const isDefault = p.value === mfaPreference;
                  const isCurrentlySelected = p.value === watch(inputName);
                  const hasError = errors.mfaPreference && isCurrentlySelected;
                  return (
                    <Fragment key={p.value}>
                      <RadioButton
                        id={`radio-input-${p.value}`}
                        {...register(inputName, {
                          validate: (value) =>
                            validateMfaPreference(value, isMobileVerified),
                        })}
                        value={p.value}
                        defaultChecked={isDefault}
                        label={p.label}
                        description={p.description}
                        hasError={hasError!}
                        aria-invalid={hasError!}
                      />
                      {hasError && (
                        <RadioButtonError>
                          <StyledUpdateFailureMessage textColor="primary">
                            {errorMessage}{" "}
                            <RouterLink
                              to="/add-edit-mobile"
                              onClick={() =>
                                dispatch(startMfaPreferenceFlow(p.value))
                              }
                            >
                              Add / Verify my mobile number
                            </RouterLink>
                          </StyledUpdateFailureMessage>
                        </RadioButtonError>
                      )}
                    </Fragment>
                  );
                })}
              </RadioButtonGroup>
              <Buttons>
                <ThemedButton aria-label="Save preference for security level">
                  Save preference
                </ThemedButton>

                <CancelLink tabIndex={0} href={getReturnToLink()}>
                  Cancel
                </CancelLink>
              </Buttons>
            </form>
          </ChangeMfaContainer>
        )}
      </HideableDiv>

      <AppFooter />
    </Layout>
  );
};

export default MfaPreferencePage;
