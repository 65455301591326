import configuration from "../../config/Configuration";
import { getQueryParameter } from "./NavigationHelpers";

export const getReturnToLink = (returnToUrl?: string) => {
  const returnTo = returnToUrl || getQueryParameter("returnTo") || "/";

  const isReturnUrlInAllowedList =
    configuration.allowedReturnUrls?.includes(returnTo);

  if (isReturnUrlInAllowedList) {
    return returnTo;
  }

  return `/`;
};
