import { call, put } from "redux-saga/effects";
import { updateCustomerMobileNumber } from "../../../api/profile/CustomerProfileApi";
import {
  deleteMobileFailed,
  deleteMobileSuccess,
} from "../../../store/verify-mobile-number/VerifyMobileNumberSlice";

export function* deleteMobileNumberSaga() {
  try {
    yield call(updateCustomerMobileNumber, "");
    yield put(deleteMobileSuccess());
  } catch {
    yield put(deleteMobileFailed("You mobile number could not be deleted."));
  }
}
