import { useEffect, useRef } from "react";
import { IconCircle } from "./IconCircle";
import { StyledButtonWrapper } from "../styled/StyledButtonWrapper";
import {
  CenteredHeader,
  CenteredParagraph,
  InfoContainer,
  InfoPageThemedButton,
} from "../styled/InfoPage";
import AppFooter from "../../common/AppFooter";
import { Layout } from "../styled/Layout";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

const VerificationErrorPageLayout = styled(Layout)`
  width: 514px;
  @media ${screenSize.mobile} {
    width: 100%;
  }
`;

export interface ResultPageProps {
  header: string;
  description: string;
  buttonText: string;
  action: Function;
  hasError: boolean;
}

export function ResultPage(props: ResultPageProps) {
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    headingRef.current?.focus();
  }, []);

  function buttonClick() {
    props.action();
  }

  return (
    <VerificationErrorPageLayout>
      <InfoContainer>
        <IconCircle hasError={props.hasError} decorative={true} />
        <CenteredHeader
          tabIndex={-1}
          ref={headingRef}
          hasError={props.hasError}
        >
          {props.header}
        </CenteredHeader>
        <CenteredParagraph>{props.description}</CenteredParagraph>
        <StyledButtonWrapper>
          <InfoPageThemedButton onClick={buttonClick}>
            {props.buttonText}
          </InfoPageThemedButton>
        </StyledButtonWrapper>
      </InfoContainer>
      <AppFooter />
    </VerificationErrorPageLayout>
  );
}
