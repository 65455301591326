import { call, put } from "redux-saga/effects";
import { queryFeatureFlags } from "../../api/ServerApi";
import { FeaturesResponseItem, featureFlagsRetrieved } from "./FeaturesSlice";

export function* getFeaturesAsync() {
  try {
    const response: Array<FeaturesResponseItem> = yield call(queryFeatureFlags);

    if (response.length) {
      yield put(featureFlagsRetrieved(response));
    }
  } catch {
    //TODO: Log exception
  }
}
