import { ValidateResult } from "react-hook-form";
import { MfaPreferenceLevel } from "./MfaPreferenceLevel";

export function validateMfaPreference(
  mfaPreference?: string,
  isMobileVerified?: boolean
): ValidateResult {
  return (
    !mfaPreference ||
    isMobileVerified ||
    mfaPreference.toLowerCase() === MfaPreferenceLevel.Low.toLowerCase()
  );
}
