import { put, call } from "redux-saga/effects";
import { updateCustomerFirstName } from "../../api/profile/CustomerProfileApi";
import {
  asyncOperationFailed,
  asyncOperationFinished,
  asyncOperationStarting,
} from "../async-operation/AsyncOperationSlice";
import { updateCustomerFirstNameField } from "../customer-profile/CustomerProfileSlice";
import { UpdateFirstNameActionPayload } from "./UpdateFirstNameActions";

interface UpdateFirstNameAction {
  type: string;
  payload: UpdateFirstNameActionPayload;
}
export function* updateFirstNameSaga(action: UpdateFirstNameAction) {
  try {
    yield put(asyncOperationStarting("updateFirstName"));
    const firstName = action.payload.firstName;
    yield call(updateCustomerFirstName, firstName);
    yield put(updateCustomerFirstNameField(firstName));
    yield put(asyncOperationFinished("updateFirstName"));
  } catch {
    yield put(asyncOperationFailed("updateFirstName"));
  }
}
