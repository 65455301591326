import React from "react";
import { InputDiv } from "../../components/styled/InputDiv";
import { Banner } from "ccp-common-ui-components";
import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";

const deleteProfileBannerText =
  "Deleting your account is now in progress - it can take up to 10 days. You can still use your account until it's deleted.";

interface DeleteProfileBannerProps {
  isShown: boolean;
}

const DeleteBannerContainer = styled(Banner)`
  background-color: ${headers.white900};
`;

export function DeleteProfileBanner(props: DeleteProfileBannerProps) {
  const deleteBannerContent = <div>{deleteProfileBannerText} </div>;

  return (
    <>
      {props.isShown && (
        <InputDiv data-testid="delete-profile-banner">
          <DeleteBannerContainer hasError={true} isWarning={true}>
            {deleteBannerContent}
          </DeleteBannerContainer>
        </InputDiv>
      )}
    </>
  );
}
