import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const SpinnerContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  order: 3;
  align-self: center;
  justify-content: flex-end;

  @media ${screenSize.mobile} {
    order: 2;
    flex: 0 0 auto;
  }
`;
