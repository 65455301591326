import {
  invalidHomePhoneErrorMessage,
  invalidMobileNumberErrorMessage,
  invalidOneTimeCodeErrorMessage,
  oneTimeCodeIsRequiredErrorMessage,
} from "../../../common/constants/ErrorMessages";

export const removeAllSpaces = (value: string) => {
  return `${value}`.trim().replace(/ /g, "");
};

export const isValidHomePhone = (value: string): boolean | string => {
  if (value === null || value === undefined || value === "") {
    return true;
  }

  const sanitizedValue = removeAllSpaces(value);
  const isValidHomePhone = /^(02|03|04|07|08)[0-9]{8}$/.test(sanitizedValue);

  if (!isValidHomePhone) {
    return invalidHomePhoneErrorMessage;
  }

  return isValidHomePhone;
};

export const isPhoneNumberEmpty = (value: string) => {
  return !value;
};

export const isValidMobileNumber = (value: string): boolean | string => {
  const sanitizedValue = removeAllSpaces(value);
  const isValidMobileNumber = /^04[0-9]{8}$/.test(sanitizedValue);
  const isValid = isValidMobileNumber;
  if (!isValid) {
    return invalidMobileNumberErrorMessage;
  }

  return isValid;
};

const numericalOnlyRegex = /^[0-9]*$/g;
const maxOneTimeCodeLength = 6;

export function isOneTimeCodeValid(oneTimeCode: string): boolean | string {
  if (!oneTimeCode) {
    return oneTimeCodeIsRequiredErrorMessage;
  }
  oneTimeCode = oneTimeCode.trim();

  const isValid =
    oneTimeCode.match(numericalOnlyRegex) &&
    oneTimeCode.length === maxOneTimeCodeLength;

  return !!isValid || invalidOneTimeCodeErrorMessage;
}

export const maskValue = (mobile: string) => {
  const charactersToReplace = mobile?.substring(2, 7);
  return mobile?.replace(charactersToReplace, "*****");
};
