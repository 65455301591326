/**
 * Wrapper for fetch that if the response returns a 401, and has a loginUri in the response, will redirect there.
 */
export async function loginFetch(
  input: RequestInfo,
  init?: RequestInit | undefined
): Promise<Response> {
  const response = await fetch(input, init);
  if (response.status === 401) {
    const json = await response.json();
    if (json.loginUri) {
      window.location.assign(json.loginUri);
    }
  }
  return Promise.resolve(response);
}
