export function getMetaTagContent(propertyName: string): string {
  const metaTags = document.getElementsByTagName("meta");
  for (let i = 0; i < metaTags.length; i++) {
    const property = metaTags[i].getAttribute("property");
    if (property === propertyName) {
      const content = metaTags[i].getAttribute("content");
      if (content) {
        return content;
      }
      throw new Error(
        `Could not read content for property '${propertyName}': ${content}`
      );
    }
  }
  throw new Error(`Could not find property '${propertyName}'`);
}
