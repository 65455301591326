import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { deleteMobileNumber } from "../../../store/verify-mobile-number/VerifyMobileNumberSlice";
import { InputDiv } from "../../styled/InputDiv";
import { SettingButton } from "../../styled/SettingButton";
import { Banner } from "ccp-common-ui-components";
import { RootState } from "../../../store/Store";
import { useReturnUrl } from "../../../common/hooks/useReturnUrl";

const DeleteMobileButton = styled(SettingButton)`
  padding: 0;
`;

const deleteMobileBannerText =
  "By deleting your mobile number you won't receive timely notifications on your account. You also won’t be able to use any of your additional account security features.";

interface DeleteMobilePhoneBannerProps {
  isShown: boolean;
  backButtonUrl: string | undefined;
}

export function DeleteMobilePhoneBanner(props: DeleteMobilePhoneBannerProps) {
  const errorMessage = useSelector(
    (state: RootState) => state.verifyMobileNumber.deleteMobileErrorMessage
  );

  const dispatch = useDispatch();
  const { handleNavigation } = useReturnUrl(props.backButtonUrl);

  const deleteMobile = () => {
    dispatch(deleteMobileNumber());
    //delete was successfull
    if (typeof errorMessage === "undefined") {
      handleNavigation();
    }
  };

  const bannerText = errorMessage || deleteMobileBannerText;
  const buttonText = errorMessage ? "Try again" : "Delete mobile number";

  const deleteBannerContent = (
    <div>
      {bannerText}{" "}
      <DeleteMobileButton
        onClick={deleteMobile}
        data-testid="delete-mobile-button"
      >
        {buttonText}
      </DeleteMobileButton>
    </div>
  );

  return (
    <>
      {props.isShown && (
        <InputDiv data-testid="delete-mobile-banner">
          <Banner hasError={true}>{deleteBannerContent}</Banner>
        </InputDiv>
      )}
    </>
  );
}
