import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { ActionModal } from "../../common/modals/ActionModal";
import {
  setIsFlybuysUnlinkModalOpen,
  unlinkFlybuysAction,
} from "../../store/flybuys/FlybuysSlice";

export const BackgroundDiv = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
`;

export function FlybuysUnlinkModalContainer() {
  const dispatch = useDispatch();

  const showUnlinkModal = useSelector(
    (state: RootState) => state.flybuys.isUnlinkModelOpen
  );

  const { flybuys } = useSelector((state: RootState) => state.loyaltyAccounts);

  const closeModal = () => {
    dispatch(setIsFlybuysUnlinkModalOpen(false));
  };

  const unlinkCardRequest = () => {
    dispatch(
      unlinkFlybuysAction({ loyaltyAccountId: flybuys.loyaltyAccountId })
    );
  };

  const unlinkHeader = `Unlink flybuys?`;
  const unlinkBlurb = `Unlinking your flybuys account means you will no longer be earning points at checkout when you shop with our other Coles Group accounts.`;
  const unlinkButton = `Unlink flybuys`;
  const cancelButton = `Cancel`;

  return (
    <>
      {showUnlinkModal && <BackgroundDiv />}
      <ActionModal
        onSuccess={() => unlinkCardRequest()}
        onRequestClose={() => closeModal()}
        isOpen={showUnlinkModal}
        shouldCloseOnEsc={true}
        header={unlinkHeader}
        blurb={unlinkBlurb}
        yesbutton={unlinkButton}
        nobutton={cancelButton}
      />
    </>
  );
}
