import { Banner } from "ccp-common-ui-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/Store";
import { BannerContainer } from "../../styled/BannerContainer";
import { mfaPreferenceUpdateSuccessMessage } from "../../../common/constants/InfoMessages";

export function MfaPreferenceUpdateSuccessBanner() {
  const { isMfaPreferenceUpdateSuccessBannerShown } = useSelector(
    (state: RootState) => state.customerProfile
  );

  return (
    <>
      {isMfaPreferenceUpdateSuccessBannerShown && (
        <BannerContainer>
          <Banner>{mfaPreferenceUpdateSuccessMessage}</Banner>
        </BannerContainer>
      )}
    </>
  );
}
