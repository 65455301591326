import appConfig from "../../config/Configuration";
import { defaultRequestConfig } from "./../ServerApi";
import { loginFetch } from "./../LoginFetch";
import { PreferredStoreStatus } from "../../store/query-preferred-store/QueryPreferredStoreSlice";

const customerProfileUrl = appConfig.serverUrl + "/api/v1/customer-profile";

export interface UpdateProfileResponse {
  statusCode?: number;
  message?: string;
}

export interface QueryCustomerProfileResponse {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  homePhone: string;
  preferredStore: {
    displayAddress: string;
    status: PreferredStoreStatus;
  };
  dateOfBirth: string;
  locality: {
    postcode: string;
    suburb: string;
    state: string;
  };
  isMobileVerified?: boolean;
  mfaPreference: string;
  pendingDeletion?: boolean;
  profileType: string;
}

export async function queryCustomerProfile(): Promise<QueryCustomerProfileResponse> {
  const customerProfileUrlWithParams = `${customerProfileUrl}`;

  const response = await loginFetch(customerProfileUrlWithParams, {
    ...defaultRequestConfig,
    method: "GET",
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}

export async function updateCustomerFirstName(
  firstName: string
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ firstName }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateCustomerLastName(lastName: string): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ lastName }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateCustomerMobileNumber(
  mobile: string
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ mobile }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateCustomerDateOfBirth(
  dateOfBirth: string
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ dateOfBirth: dateOfBirth }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateCustomerHomePhone(
  homePhone: string
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ homePhone: homePhone }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export interface PostcodeUpdateRequest {
  suburb: string;
  state: string;
  postcode: string;
}

export async function updateCustomerPostcode(
  postcodeUpdateRequest: PostcodeUpdateRequest
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ locality: postcodeUpdateRequest }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateCustomerMfaPreference(
  mfaPreferenceLevel: string
): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "PATCH",
    body: JSON.stringify({ mfaPreference: mfaPreferenceLevel }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function deleteCustomerProfile(): Promise<void> {
  const response = await loginFetch(customerProfileUrl, {
    ...defaultRequestConfig,
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
