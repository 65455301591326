import { SettingLine } from "../../styled/SettingLine";
import { SettingName } from "../../styled/SettingName";
import { MfaDisplay } from "./MfaDisplay";
import { Link } from "react-router-dom";
import { SettingLink } from "../../styled/SettingLink";
import { useIsPersonalCustomer } from "../Utils/CustomerUtils";
import configuration from "../../../config/Configuration";
import { MfaDisplayStatic } from "./MfaDisplayStatic";

export function MfaSetting() {
  const isPersonalCustomer = useIsPersonalCustomer();
  const isSecurityPreferenceCustomerTypeFeatureFlagOn =
    configuration.securityPreferenceCustomerType;

  const showChangeSecurityPreference =
    !isSecurityPreferenceCustomerTypeFeatureFlagOn || !isPersonalCustomer;

  return (
    <SettingLine>
      <SettingName id="radio-group-label">
        {showChangeSecurityPreference
          ? "Security level preference:"
          : "Multi-factor authentication:"}
      </SettingName>
      {showChangeSecurityPreference ? <MfaDisplay /> : <MfaDisplayStatic />}
      <SettingLink>
        {showChangeSecurityPreference && (
          <Link to="/change-security-level">Change preference</Link>
        )}
      </SettingLink>
    </SettingLine>
  );
}
