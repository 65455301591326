import appConfig from "../../config/Configuration";
import { defaultRequestConfig } from "../ServerApi";
import { loginFetch } from "../LoginFetch";

const addressSearchUrl = appConfig.serverUrl + "/api/v1/address/search";

export interface QueryAddressSearchResponse {
  suggestions: Array<AddressSuggestion>;
}

export interface AddressSuggestion {
  globalAddressKey: string;
  text: string;
}

export async function queryAddressSearch(
  addressText: string
): Promise<QueryAddressSearchResponse> {
  const response = await loginFetch(addressSearchUrl, {
    ...defaultRequestConfig,
    method: "POST",
    body: JSON.stringify({ addressText }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  } else {
    return await response.json();
  }
}
