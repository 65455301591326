import { call, put } from "redux-saga/effects";
import { deleteCustomerProfile } from "../../api/profile/CustomerProfileApi";
import { history } from "../../common/utils/history";
import {
  deleteProfileFailed,
  deleteProfileSuccess,
} from "../customer-profile/CustomerProfileSlice";

export function* SendProfileDeleteRequestSaga() {
  try {
    yield call(deleteCustomerProfile);
    yield put(deleteProfileSuccess());
  } catch {
    yield put(
      deleteProfileFailed(
        "We weren’t able to delete the customer profile. Please try again later."
      )
    );
  } finally {
    history.push("/delete-profile-result");
  }
}
