import { useHistory } from "react-router-dom";
import { redirect } from "../utils/NavigationHelpers";
import { getReturnToLink } from "../utils/RedirectionHelpers";

export function useReturnUrl(returnToUrl: string | undefined) {
  const history = useHistory();

  const handleNavigation = () => {
    const returnTo = getReturnToLink(returnToUrl);
    if (returnTo !== "/") {
      return redirect(returnTo);
    }
    history.push("/");
  };

  return {
    handleNavigation,
  };
}
