import { PropsWithChildren } from "react";
import styled from "styled-components";
import failureIcon from "../../assets/icons/warning.svg";
import { headers } from "../../common/styles/RocketColours";

type ColorType = "primary";

const Icon = styled.img`
  width: 18px;
  height: 16px;
  padding-right: 8px;
  display: inline-block;
  content: initial;
  background-repeat: no-repeat;
  background-position: center;
`;

interface MessageProps {
  color?: ColorType;
}

const Message = styled.span<MessageProps>`
  font-size: 14px;
  color: ${(props) => {
    switch (props.color) {
      case "primary":
        return headers.primary;

      default:
        return headers.black900;
    }
  }};
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 12px;
`;

export interface UpdateFailureMessageProps {
  id?: string;
  textColor?: ColorType;
}

export function UpdateFailureMessage({
  id,
  children,
  textColor,
  ...props
}: PropsWithChildren<UpdateFailureMessageProps>) {
  return (
    <Container {...props}>
      <Icon src={failureIcon} alt="" aria-hidden />
      <Message id={id} color={textColor} role="status">
        {children}
      </Message>
    </Container>
  );
}
