import { MfaPreference } from "./MfaPreference";
import { MfaPreferenceLevel } from "./MfaPreferenceLevel";

export const mfaPreferences: MfaPreference[] = [
  {
    description: "Always provide Two Step Authentication when I login.",
    label: MfaPreferenceLevel.High,
    value: MfaPreferenceLevel.High.toLowerCase(),
  },
  {
    description:
      "Only undertake Two Step Authentication when something changes with my account.",
    label: MfaPreferenceLevel.Medium,
    value: MfaPreferenceLevel.Medium.toLowerCase(),
  },
  {
    description:
      "Only undertake Two Step Authentication for critical changes with my account.",
    label: MfaPreferenceLevel.Low,
    value: MfaPreferenceLevel.Low.toLowerCase(),
  },
];
