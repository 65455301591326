import React from "react";
import styled from "styled-components";
import colesLogoWhite from "../../assets/logos/coles-logo-white.svg";

const Header = styled.div`
  height: 79px;
  display: flex;
  justify-items: center;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-bottom: 0px solid;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #e8e8e8;
  background-color: #e01a22;
  @media (max-width: 460px) {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border: none;
  }
`;
const HeaderLogo = styled.img`
  height: 36px;
  width: auto;
`;

export function ThemedHeader() {
  return (
    <Header>
      <HeaderLogo src={colesLogoWhite} alt="Coles logo" />
    </Header>
  );
}
