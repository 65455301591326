import styled from "styled-components";
import { Settings } from "../../profile/ProfileSettings";
import { screenSize } from "../../../common/styles/ScreenSizes";
import { Link } from "ccp-common-ui-components";

export const MarketingPreferencesContainer = styled(Settings)`
  margin: 25px 25px 50px 25px;
  padding: 18px 25px;
  position: relative;
`;

export const MarketingPreferencesDescription = styled.p`
  font-size: 14px;
  line-height: 19px;
  text-align: left;
  margin-bottom: 18px;

  @media ${screenSize.mobile} {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const MarketingPreferencesButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-top: 12px;
  align-items: center;
  border-top: 1px solid black;
`;

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-right: auto;
  margin-left: auto;
`;
