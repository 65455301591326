import styled from "styled-components";
import { headers } from "../../common/styles/RocketColours";
import infoIcon from "../../assets/icons/info.svg";

const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InfoMessage = styled.p`
  margin: 0 0 0 8px;
  color: ${headers.black700};
`;

export interface InformationContainerProps {
  message: string;
}

export function InformationContainer(props: InformationContainerProps) {
  return (
    <InfoContainer>
      <img src={infoIcon} alt="" aria-hidden />
      <InfoMessage>{props.message}</InfoMessage>
    </InfoContainer>
  );
}
