import { Link, ThemedButton } from "ccp-common-ui-components";
import styled from "styled-components";
import { screenSize } from "../../common/styles/ScreenSizes";

export const CancelLink = styled(Link)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const FormHelpText = styled.p`
  margin-top: 0;
`;

export const VerifyButtonContainer = styled.div`
  padding: 15px;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const VerifyButton = styled(ThemedButton)`
  @media ${screenSize.mobile} {
    width: 90%;
  }
`;

export const CircleCloseContainer = styled.button`
  width: 20px;
  height: 20px;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  background-color: transparent;
  cursor: pointer;
`;
