import styled from "styled-components";
import { SettingValue } from "../../styled/SettingValue";
import { MessageContainer } from "../../styled/MessageContainer";

const DisplayValue = styled.span`
  flex-basis: 100%;
`;

export function MfaDisplayStatic() {
  return (
    <SettingValue data-testid="mfa-preference-display-static">
      <MessageContainer>
        <DisplayValue>One-time codes are sent to your mobile.</DisplayValue>
      </MessageContainer>
    </SettingValue>
  );
}
