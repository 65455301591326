import styled from "styled-components";

import warningIcon from "../../assets/icons/warning.svg";

const WarningIconImage = styled.img`
  height: 20px;
  width: 20px;
`;

export function WarningIcon() {
  return <WarningIconImage src={warningIcon} alt="error" />;
}
