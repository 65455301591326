import groupBy from "lodash.groupby";

import { CustomerProfilePreference } from "../../api/preferences/CustomerProfilePreferencesApi";

import {
  MarketingPreference,
  ReceiptPreference,
  MarketingPreferencesBrand,
  ReceiptPreferencesBrand,
  SecondaryEmailInfo,
} from "./CustomerProfilePreferencesSlice";

const digitalReceipts = "communication.digitalReceipts";
const marketing = "communication.marketing";
const secondaryEmail =
  "brand.supermarket.content.all.mode.secondemail.preferredemail";
const emailOnline = "brand.supermarket.content.online.mode.email";

export const generateMarketingPreferences = (
  prefs: CustomerProfilePreference[]
): MarketingPreference[] => {
  const mp = prefs
    .filter((item) => item.group === marketing && item.type === "boolean")
    .map((item) => {
      const splitName = item.name.split(".");
      return {
        group: item.group,
        name: item.name,
        type: item.type,
        brand: splitName[1],
        content: splitName[3],
        mode: splitName[5],
        value: JSON.parse(item.value),
      };
    });

  if (mp[0] && mp[0].brand === "cexp") {
    return mp.reverse();
  }
  return mp;
};

export const groupMarketingPreferences = (
  prefs: MarketingPreference[]
): MarketingPreferencesBrand[] => {
  const grouped = groupBy(prefs, "brand");
  return Object.keys(grouped).map((i) => {
    return {
      brand: i,
      preferences: grouped[i],
    };
  });
};

export const groupReceiptPreferences = (
  prefs: ReceiptPreference[]
): ReceiptPreferencesBrand[] => {
  const grouped = groupBy(prefs, "brand");
  return Object.keys(grouped).map((i) => {
    return {
      brand: i,
      preferences: grouped[i],
    };
  });
};

export const generateReceiptPreferences = (
  prefs: CustomerProfilePreference[]
): ReceiptPreference[] => {
  const mp = prefs
    .filter((item) => item.group === digitalReceipts && item.type === "boolean")
    .map((item) => {
      const splitName = item.name.split(".");
      return {
        group: item.group,
        name: item.name,
        type: item.type,
        brand: splitName[1],
        content: splitName[3],
        mode: splitName[5],
        value: JSON.parse(item.value),
      };
    });

  if (mp[0] && mp[0].brand === "cexp") {
    return mp.reverse();
  }
  return mp;
};

export const getSecondaryEmailInfo = (
  prefs: CustomerProfilePreference[]
): SecondaryEmailInfo => {
  const mp = prefs
    .filter(
      (item) => item.group === digitalReceipts && item.name === secondaryEmail
    )
    .map((item) => {
      const splitName = item.name.split(".");
      return {
        group: item.group,
        name: item.name,
        type: item.type,
        brand: splitName[1],
        content: splitName[3],
        mode: splitName[5],
        value: item.value,
      };
    });

  return mp[0];
};

export const getOnlineShoppingEmailValue = (
  prefs: CustomerProfilePreference[]
): SecondaryEmailInfo => {
  const mp = prefs
    .filter(
      (item) => item.group === digitalReceipts && item.name === emailOnline
    )
    .map((item) => {
      const splitName = item.name.split(".");
      return {
        group: item.group,
        name: item.name,
        type: item.type,
        brand: splitName[1],
        content: splitName[3],
        mode: splitName[5],
        value: item.value,
      };
    });

  return mp[0];
};
