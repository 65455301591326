import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sendProfileDeleteRequest } from "../customer-profile/CustomerProfileSlice";

export interface UserAcceptancesState {
  showTermsAndConditions: boolean;
  showDeleteAccount: boolean;
  showCollectionStatement: boolean;
}

const initialState: UserAcceptancesState = {
  showTermsAndConditions: false,
  showDeleteAccount: false,
  showCollectionStatement: false,
};

export const userAcceptancesSlice = createSlice({
  name: "userAcceptances",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendProfileDeleteRequest, (state) => {
      state.showDeleteAccount = false;
    });
  },
  reducers: {
    setShowTermsAndConditions: (state, action: PayloadAction<boolean>) => {
      state.showTermsAndConditions = action.payload;
    },
    setShowDeleteAccount: (state, action: PayloadAction<boolean>) => {
      state.showDeleteAccount = action.payload;
    },
    setShowCollectionStatement: (state, action: PayloadAction<boolean>) => {
      state.showCollectionStatement = action.payload;
    },
  },
});

export const {
  setShowTermsAndConditions,
  setShowDeleteAccount,
  setShowCollectionStatement,
} = userAcceptancesSlice.actions;

export default userAcceptancesSlice.reducer;
