import { useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { getQueryParameter } from "../../common/utils/NavigationHelpers";
import { AuthenticationStatus } from "../../store/check-authentication/CheckAuthenticationSlice";

import {
  DeleteAccountContainer,
  DeleteAccountContent,
} from "./DeleteAccountStyles";
import { PageLoader } from "../common/PageLoader";
import ErrorPage from "../../common/error/ErrorPage";

import { DeleteAccountConfirmation } from "./DeleteAccountConfirmation";
import { DeleteAccountError } from "./DeleteAccountError";
import { DeleteAccountSuccess } from "./DeleteAccountSuccess";
import configuration from "../../config/Configuration";
import { useEffect } from "react";
import { dataLayerDeleteImpression } from "../../common/utils/tracking/deleteAccountPageTracking";

const DeleteAccountPage = () => {
  const returnTo =
    getQueryParameter("returnTo") ||
    `${window.location.protocol}//${window.location.host}`;

  useEffect(() => {
    dataLayerDeleteImpression(returnTo);
  }, [returnTo]);

  const isReturnUrlInAllowedList =
    configuration.allowedReturnUrls?.includes(returnTo);

  const authenticationStatus = useSelector(
    (state: RootState) => state.authentication.status
  );

  const {
    isDeleteAccountSuccess,
    isDeleteAccountFailed,
    isDeleteAccountPending,
  } = useSelector((state: RootState) => state.deleteAccount);

  const showDeleteAccountConfirmation =
    !isDeleteAccountPending &&
    !isDeleteAccountSuccess &&
    !isDeleteAccountFailed;
  const showPendingLoader = isDeleteAccountPending;
  const showDeleteAccountSuccess = isDeleteAccountSuccess;
  const showDeleteAccountError = isDeleteAccountFailed;

  const showAuthenticationLoader =
    authenticationStatus === AuthenticationStatus.Checking;

  const showDeleteAccountPage =
    authenticationStatus === AuthenticationStatus.Authenticated &&
    isReturnUrlInAllowedList;

  return (
    <DeleteAccountContainer>
      {showAuthenticationLoader ? (
        <PageLoader isShown={showAuthenticationLoader} />
      ) : (
        <DeleteAccountContent>
          {showDeleteAccountPage ? (
            <>
              <PageLoader isShown={showPendingLoader} />
              {showDeleteAccountConfirmation ? (
                <DeleteAccountConfirmation returnTo={returnTo} />
              ) : null}
              {showDeleteAccountSuccess ? (
                <DeleteAccountSuccess returnTo={returnTo} />
              ) : null}
              {showDeleteAccountError ? <DeleteAccountError /> : null}
            </>
          ) : (
            <ErrorPage />
          )}
        </DeleteAccountContent>
      )}
    </DeleteAccountContainer>
  );
};

export default DeleteAccountPage;
