import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";
import { updateCustomerMfaPreference } from "../../api/profile/CustomerProfileApi";
import {
  VerifyLaterPayload,
  verifyLaterSuccess,
} from "../customer-profile/CustomerProfileSlice";

export function* verifyLaterSaga(action: PayloadAction<VerifyLaterPayload>) {
  try {
    const mfaPreferenceLevel = action.payload.mfaPreference;

    yield call(updateCustomerMfaPreference, mfaPreferenceLevel);
    yield put(verifyLaterSuccess(mfaPreferenceLevel));
  } catch {}
}
