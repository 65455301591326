import { createSlice } from "@reduxjs/toolkit";

export interface DeleteAccountState {
  isDeleteAccountSuccess: boolean;
  isDeleteAccountFailed: boolean;
  isDeleteAccountPending: boolean;
}

export const initialState: DeleteAccountState = {
  isDeleteAccountSuccess: false,
  isDeleteAccountFailed: false,
  isDeleteAccountPending: false,
};

const deleteAccountSlice = createSlice({
  name: "deleteAccount",
  initialState,
  reducers: {
    deleteAccountSuccess: (state) => {
      state.isDeleteAccountSuccess = true;
      state.isDeleteAccountFailed = false;
      state.isDeleteAccountPending = false;
    },
    deleteAccount: (state) => {
      state.isDeleteAccountSuccess = false;
      state.isDeleteAccountFailed = false;
      state.isDeleteAccountPending = true;
    },
    deleteAccountFailed: (state) => {
      state.isDeleteAccountSuccess = false;
      state.isDeleteAccountFailed = true;
      state.isDeleteAccountPending = false;
    },
  },
});

export const { deleteAccountSuccess, deleteAccountFailed, deleteAccount } =
  deleteAccountSlice.actions;

export const deleteAccountReducer = deleteAccountSlice.reducer;
