import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { RootState } from "../../../store/Store";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from "@mui/material/Autocomplete";
import debounce from "lodash.debounce";

import { CircleCloseAdornment } from "../../common/CircleCloseAdornment";
import { LocationPinAdornment } from "./LocationPinAdornment";
import { DeleteIcon } from "./DeleteIcon";
import { WarningIcon } from "../../common/WarningIcon";
import { SettingName } from "../../styled/SettingName";

import {
  saveAddress,
  setFormVisibility,
  getMatchingAddressSuggestions,
  setMatchingAddresses,
  AutoSuggestOption,
  editAddress,
  setDeleteAddressModalVisibility,
  setAddressTobeDeleted,
} from "../../../store/add-address/AddAddressSlice";

import {
  AddAddressContainer,
  AddAddressFormContainer,
  AddressFormColumnContent,
  AddressFormColumnContainer,
  AddressFormColumn,
  Buttons,
} from "./AddAddressStyles";

import { AddAddressInput } from "./AddAddressInput";
import {
  EditAddressRequest,
  SaveAddressRequest,
} from "../../../api/address/AddressesApi";

import { AddressFormHeader } from "./AddAddressStyles";
import {
  ThemedAddAddressButton,
  CancelLink,
  DeleteLink,
} from "./AddAddressStylesOverride";
import {
  isValidHomePhone,
  removeAllSpaces,
} from "../../profile/Utils/PhoneUtils";

import { MessageContainer } from "../../styled/MessageContainer";
import { ErrorText } from "ccp-common-ui-components";
import { CustomerProfileAddressFields } from "../../../store/customer-profile-address/CustomerProfileAddressSlice";
import {
  AutocompleteDropDownMessage,
  AutocompleteSettingName,
  StyledInput,
  StyledPopper,
} from "../../../common/styles/Autocomplete";

export type AddAddressFormData = {
  nickName: string;
  globalAddressKey: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

interface AddAddressFormProps {
  address: CustomerProfileAddressFields | null;
  setEditAddress: (arg0: CustomerProfileAddressFields | null) => void | null;
}

const theme = createTheme({});

export const isNickNameUniqueExceptSelf = (
  addresses: CustomerProfileAddressFields[],
  newNickname: string,
  oldNickname: string
) => {
  return !addresses
    .filter((a) => a.nickname !== oldNickname)
    .some((a) => a.nickname === newNickname);
};

export default function AddAddressForm(props: AddAddressFormProps) {
  const AUTOCOMPLETE_INPUT_LIMIT = 7;
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);
  const [autocompleteInput, setAutocompleteInput] = useState<string>("");
  const [autocompleteValue, setAutocompleteValue] =
    useState<AutoSuggestOption | null>(null);

  const setEditAddress = props.setEditAddress;

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setValue,
    formState: { isSubmitSuccessful, errors },
    trigger,
    control,
  } = useForm<AddAddressFormData>();

  useEffect(() => {
    if (props.address) {
      const aggregatedAddress = [
        props.address.addressLine1,
        props.address.addressLine2,
        props.address.addressLine3,
        props.address.city,
        props.address.state,
        props.address.postcode,
      ]
        .filter(Boolean)
        .join(", ");

      setAutocompleteInput(aggregatedAddress);
      setAutocompleteValue({
        id: "placeholderString",
        label: aggregatedAddress,
      });
    }
  }, [props.address]);

  const [userTriedToSubmit, setUserTriedToSubmit] = useState(false);

  const revalidate = (
    name:
      | "nickName"
      | "firstName"
      | "lastName"
      | "email"
      | "phone"
      | "globalAddressKey"
  ) => {
    if (userTriedToSubmit) {
      trigger(name);
    }
  };

  const onAutocompleteInput = (newValue: string, reason: string) => {
    setAutocompleteInput(newValue);
    if (newValue.length >= AUTOCOMPLETE_INPUT_LIMIT) {
      debouncedGetAddressSuggestions();
    }
  };

  const getAddressSuggestions = () => {
    dispatch(getMatchingAddressSuggestions({ addressText: autocompleteInput }));
  };

  const clearAddressSuggestions = useCallback(() => {
    setAutocompleteInput("");
    setAutocompleteValue(null);
    setValue("globalAddressKey", "");
    dispatch(setMatchingAddresses([]));
  }, [dispatch, setValue]);

  const debouncedGetAddressSuggestions = debounce(getAddressSuggestions, 300);

  const nickNameInputName = "nickName";
  const recipientFirstNameInputName = "firstName";
  const recipientLastNameInputName = "lastName";
  const emailInputName = "email";
  const phoneInputName = "phone";

  const { showAddAddressForm, matchingAddresses, searchAddressFailed } =
    useSelector((state: RootState) => state.addAddress);

  const nickname =
    props.address && props.address.nickname ? props.address.nickname : "";

  const customerFirstName = useSelector(
    (state: RootState) => state.customerProfile.profileFields.firstName
  );
  const recipientFirstName =
    props.address && props.address.recipientFirstName
      ? props.address.recipientFirstName
      : customerFirstName;

  const customerLastName = useSelector(
    (state: RootState) => state.customerProfile.profileFields.lastName
  );

  const recipientLastName =
    props.address && props.address.recipientLastName
      ? props.address.recipientLastName
      : customerLastName;

  const editEmail = props.address?.contacts.find(
    (contact) => contact.key === "email"
  )?.value;

  const profileEmail = useSelector(
    (state: RootState) => state.customerProfile.profileFields.email
  );

  const customerEmail = editEmail ?? profileEmail;

  const customerMobile = useSelector(
    (state: RootState) => state.customerProfile.profileFields.mobile
  );

  const customerHomePhone = useSelector(
    (state: RootState) => state.customerProfile.profileFields.homePhone
  );

  const editPhoneNumber = props.address?.contacts.find(
    (contact) => contact.key === "phone"
  )?.value;

  const customerPhoneNumber = customerMobile ?? customerHomePhone;

  const phoneNumber = editPhoneNumber ?? customerPhoneNumber;

  const addresses = useSelector(
    (state: RootState) => state.customerProfileAddress.addresses
  );

  const isNicknameUnique = useCallback(
    (newNickname: string) => {
      return !addresses.some((a) => a.nickname === newNickname);
    },
    [addresses]
  );

  useEffect(() => {
    register(nickNameInputName, {
      required: "Please enter an address name.",
      validate: (value) =>
        (props.address
          ? isNickNameUniqueExceptSelf(addresses, value, props.address.nickname)
          : isNicknameUnique(value)) ||
        "This address name has already been used. Please try a different name.",
    });
  }, [nickname, isNicknameUnique, register, addresses, props.address]);

  useEffect(() => {
    register(recipientFirstNameInputName, {
      required: "Please enter recipient first name.",
      pattern: {
        value: /^[a-zA-Z-' ]*$/,
        message:
          "Recipient first name cannot include special characters or symbols. Please try again.",
      },
    });
  }, [recipientFirstName, register]);

  useEffect(() => {
    register(recipientLastNameInputName, {
      required: "Please enter recipient last name.",
      pattern: {
        value: /^[a-zA-Z-' ]*$/,
        message:
          "Recipient last name cannot include special characters or symbols. Please try again.",
      },
    });
  }, [recipientLastName, register]);

  useEffect(() => {
    register(emailInputName, {
      required: "Please enter a valid email address.",
      pattern: {
        value: /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,100})$/,
        message: "Please enter a valid email address.",
      },
    });
  }, [customerEmail, register]);

  useEffect(() => {
    register(phoneInputName, {
      required: "Please enter a valid phone number. For example: 0411 222 333",
      validate: (value) =>
        typeof isValidHomePhone(value) !== "string" ||
        "Please enter a valid phone number. For example: 0411 222 333",
    });
  }, [phoneNumber, register]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      setUserTriedToSubmit(false);
      clearAddressSuggestions();
      clearErrors();
      reset();
    }
  }, [isSubmitSuccessful, clearAddressSuggestions, clearErrors, reset]);

  const onSubmit = (data: AddAddressFormData) => {
    setUserTriedToSubmit(true);

    if (props.address && props.address.id) {
      const editRequest: EditAddressRequest = {
        Id: props.address?.id,
        CustomerNotes: "",
        Nickname: data.nickName,
        GlobalAddressKey: data.globalAddressKey,
        RecipientFirstName: data.firstName,
        RecipientLastName: data.lastName,
        Email: data.email,
        Phone: removeAllSpaces(data.phone),
      };

      clearErrors();
      dispatch(editAddress(editRequest));
      dispatch(setFormVisibility(false));
    } else {
      const addRequest: SaveAddressRequest = {
        Nickname: data.nickName,
        GlobalAddressKey: data.globalAddressKey,
        RecipientFirstName: data.firstName,
        RecipientLastName: data.lastName,
        Email: data.email,
        Phone: removeAllSpaces(data.phone),
      };

      clearErrors();
      dispatch(saveAddress(addRequest));
      dispatch(setFormVisibility(false));
    }
  };

  const formHeaderText = `${props.address ? "Edit" : "Add"} a delivery address`;

  return (
    <>
      {showAddAddressForm && (
        <AddAddressContainer>
          <AddAddressFormContainer
            onSubmit={handleSubmit(onSubmit)}
            data-testid="add-address-form"
          >
            <AddressFormHeader>{formHeaderText}</AddressFormHeader>
            <AddressFormColumnContainer>
              <AddressFormColumn>
                <AddressFormColumnContent>
                  <SettingName>Address name:</SettingName>
                  <AddAddressInput
                    id="nick-name"
                    name={nickNameInputName}
                    value={nickname}
                    defaultValue={nickname}
                    setValue={setValue}
                    errorMessage={errors.nickName?.message}
                    errorTextId="nick-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContent>
                <AddressFormColumnContent>
                  <SettingName htmlFor="first-name">
                    Recipient first name:
                  </SettingName>
                  <AddAddressInput
                    id="first-name"
                    name={recipientFirstNameInputName}
                    value={recipientFirstName}
                    defaultValue={recipientFirstName}
                    setValue={setValue}
                    errorMessage={errors.firstName?.message}
                    errorTextId="first-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContent>
                <AddressFormColumnContent>
                  <SettingName htmlFor="last-name">
                    Recipient last name:
                  </SettingName>
                  <AddAddressInput
                    id="last-name"
                    name={recipientLastNameInputName}
                    value={recipientLastName}
                    defaultValue={recipientLastName}
                    setValue={setValue}
                    errorMessage={errors.lastName?.message}
                    errorTextId="last-name-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContent>
              </AddressFormColumn>
              <AddressFormColumn>
                <AddressFormColumnContent>
                  <AutocompleteSettingName htmlFor="address-input">
                    Delivery address:
                  </AutocompleteSettingName>
                  <ThemeProvider theme={theme}>
                    <Controller
                      name="globalAddressKey"
                      control={control}
                      render={() => (
                        <Autocomplete
                          filterOptions={(x) => x}
                          id="delivery-address-autocomplete"
                          inputValue={autocompleteInput}
                          value={autocompleteValue}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id ? true : false
                          }
                          onChange={(event, value, reason) => {
                            if (value && typeof value !== "string") {
                              setAutocompleteValue(
                                value && value.id ? value : null
                              );
                              setValue(
                                "globalAddressKey",
                                value ? value!.id : ""
                              );
                              revalidate("globalAddressKey");
                            }
                          }}
                          onClose={(event: React.SyntheticEvent) => {
                            setAutocompleteOpen(false);
                          }}
                          options={matchingAddresses || []}
                          onInputChange={(event, newValue, reason) => {
                            onAutocompleteInput(newValue, reason);
                          }}
                          onOpen={(event: React.SyntheticEvent) => {
                            if (
                              (event.type === "mousedown" ||
                                event.type === "change") &&
                              autocompleteInput.length >=
                                AUTOCOMPLETE_INPUT_LIMIT
                            ) {
                              setAutocompleteOpen(true);
                            }
                          }}
                          open={autocompleteOpen}
                          noOptionsText={
                            <AutocompleteDropDownMessage>
                              {autocompleteInput &&
                              autocompleteInput.length >=
                                AUTOCOMPLETE_INPUT_LIMIT ? (
                                <React.Fragment>
                                  <WarningIcon />
                                  {searchAddressFailed
                                    ? "Something went wrong, please retry."
                                    : "No address matches found"}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  Enter delivery address here
                                </React.Fragment>
                              )}
                            </AutocompleteDropDownMessage>
                          }
                          PopperComponent={StyledPopper}
                          renderInput={(params) => (
                            <StyledInput
                              id="address-input"
                              startAdornment={
                                <InputAdornment position="start">
                                  <LocationPinAdornment />
                                </InputAdornment>
                              }
                              endAdornment={
                                autocompleteInput &&
                                autocompleteInput.length > 0 ? (
                                  <InputAdornment
                                    position="end"
                                    onClick={() => {
                                      clearAddressSuggestions();
                                      revalidate("globalAddressKey");
                                    }}
                                  >
                                    <CircleCloseAdornment />
                                  </InputAdornment>
                                ) : null
                              }
                              ref={params.InputProps.ref}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "chrome-off",
                              }}
                              placeholder="Enter delivery address here"
                              error={errors.globalAddressKey !== undefined}
                            />
                          )}
                        />
                      )}
                      rules={
                        props.address
                          ? {}
                          : { required: "Please select delivery address." }
                      }
                      defaultValue=""
                    />
                    {errors.globalAddressKey?.message && (
                      <MessageContainer>
                        <ErrorText
                          id="global-address-key-error"
                          data-testid="global-address-key-error-text"
                        >
                          {errors.globalAddressKey?.message}
                        </ErrorText>
                      </MessageContainer>
                    )}
                  </ThemeProvider>
                </AddressFormColumnContent>
                <AddressFormColumnContent>
                  <SettingName htmlFor="email">Email:</SettingName>
                  <AddAddressInput
                    id="email"
                    name={emailInputName}
                    value={customerEmail}
                    defaultValue={customerEmail}
                    setValue={setValue}
                    errorMessage={errors.email?.message}
                    errorTextId="email-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContent>
                <AddressFormColumnContent>
                  <SettingName htmlFor="phone">Phone number:</SettingName>
                  <AddAddressInput
                    id="phone"
                    name={phoneInputName}
                    value={phoneNumber}
                    defaultValue={phoneNumber}
                    setValue={setValue}
                    errorMessage={errors.phone?.message}
                    errorTextId="phone-error"
                    revalidate={revalidate}
                  />
                </AddressFormColumnContent>
              </AddressFormColumn>
            </AddressFormColumnContainer>
            <Buttons>
              <ThemedAddAddressButton
                type="submit"
                data-testid="save-button"
                onClick={(e: React.MouseEvent) => {
                  setUserTriedToSubmit(true);
                }}
              >
                Save delivery address
              </ThemedAddAddressButton>
              <CancelLink
                tabIndex={0}
                href=""
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  clearAddressSuggestions();
                  clearErrors();
                  dispatch(setFormVisibility(false));
                  setEditAddress(null);
                }}
              >
                Cancel
              </CancelLink>
              {props.address && (
                <DeleteLink
                  href=""
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    dispatch(setDeleteAddressModalVisibility(true));
                    if (props.address) {
                      dispatch(
                        setAddressTobeDeleted({
                          Id: props.address?.id,
                          Nickname: props.address?.nickname,
                        })
                      );
                    }
                  }}
                >
                  <DeleteIcon />
                  Delete address
                </DeleteLink>
              )}
            </Buttons>
          </AddAddressFormContainer>
        </AddAddressContainer>
      )}
    </>
  );
}
