import styled from "styled-components";
import { CustomerProfileAddressFields } from "../../store/customer-profile-address/CustomerProfileAddressSlice";
import AddAddressForm from "./add-address-form/AddAddressForm";
import { AddressCard } from "./view-addresses/AddressCard";
import { screenSize } from "../../common/styles/ScreenSizes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/Store";
import { setAddressToBeEdited } from "../../store/add-address/AddAddressSlice";
interface AddressListProps {
  addresses: Array<CustomerProfileAddressFields>;
}

const AddressListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 12px;
  @media ${screenSize.mobile} {
    flex-direction: column;
    margin: 0 -24px 12px -24px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
`;

const AddressColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  @media ${screenSize.mobile} {
    flex-direction: column;
  }
  @media (min-width: 620px) and (max-width: 1260px) {
    flex-direction: column;
    width: 100%;
  }
`;

const AddressColumn = styled.div`
  display: flex;

  flex-direction: column;
  width: 50%;

  @media (max-width: 1260px) {
    width: 100%;
  }
`;

const RightColumnDiv = styled.div`
  margin-left: 6px;
  @media (max-width: 1260px) {
    margin: 0;
  }
`;

const LeftColumnDiv = styled.div`
  margin-right: 6px;
  @media (max-width: 1260px) {
    margin: 0;
  }
`;

const AddressList = ({ addresses }: AddressListProps) => {
  const hasAddresses = addresses && addresses.length > 0;

  const editAddress = useSelector(
    (state: RootState) => state.addAddress.addressToBeEdited
  );
  const dispatch = useDispatch();
  const setEditAddress = (editAddress: CustomerProfileAddressFields | null) => {
    dispatch(setAddressToBeEdited(editAddress));
  };

  return (
    <>
      <AddressListContainer>
        <AddAddressForm address={editAddress} setEditAddress={setEditAddress} />
        {hasAddresses && (
          <AddressColumnsContainer>
            <AddressColumn>
              <LeftColumnDiv>
                {addresses
                  .filter((_, i) => (i + 1) % 2 !== 0)
                  .map((address, index) => (
                    <AddressCard
                      address={address}
                      key={index}
                      editAddressId={
                        editAddress === null ? null : editAddress.id
                      }
                      setEditAddress={setEditAddress}
                    />
                  ))}
              </LeftColumnDiv>
            </AddressColumn>

            <AddressColumn>
              <RightColumnDiv>
                {addresses
                  .filter((_, i) => (i + 1) % 2 === 0)
                  .map((address, index) => (
                    <AddressCard
                      address={address}
                      key={index}
                      editAddressId={
                        editAddress === null ? null : editAddress.id
                      }
                      setEditAddress={setEditAddress}
                    />
                  ))}
              </RightColumnDiv>
            </AddressColumn>
          </AddressColumnsContainer>
        )}
      </AddressListContainer>
    </>
  );
};

export default AddressList;
