import styled from "styled-components";
import inlineLoader from "../../assets/icons/inlineLoader.svg";

const LoaderIconImage = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 4px;
  margin-bottom: -3px;
`;

export function InlineLoader() {
  return <LoaderIconImage src={inlineLoader} alt="loader" />;
}
